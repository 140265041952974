import React, { Component } from "react";
import Form from "../../../components/form";

function SearchAndFilter(props: any) {
  console.log(props);
  const {
    search,
    assetCode,
    directorate,
    department,
    provience,
    office,
    assetGroup,
    assetSubgroup,
    assetSubCode,
    entryUser,
    directorates,
    departments,
    provinces,
    offices,
    assetGroups,
    assetSubgroups,
    assetSubCodes,
    entryUsers,
    waitingApproval,
    waitingApprovalOption,
    activeTab,
    loggedInUserDetail
  } = props;

  const showWaitingApproval = () => {
    const { RoleId } = loggedInUserDetail;
    const { component } = activeTab

    return (RoleId === 5 && component === "l1Approved") || (RoleId === 6 && component === "l2Approved")
    // return false
  }
  return (
    <div className="filters">
      <div className="filters-grid">

        <Form
          formName="textinput"
          name="search"
          value={search}
          onChange={props.onChange}
          placeholder="Search Asset"
          width="250px"
        />
      </div>
      <div className="filters-grid">

        <Form
          formName="textinput"
          name="assetCode"
          value={assetCode}
          onChange={props.onChange}
          placeholder="Search Asset Code"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="directorate"
          value={directorate}
          options={directorates}
          onChange={props.onChange}
          placeholder="Select Directorate"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="department"
          value={department}
          options={departments}
          onChange={props.onChange}
          placeholder="Select Department"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="provience"
          value={provience}
          options={provinces}
          onChange={props.onChange}
          placeholder="Select Province"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="office"
          value={office}
          options={offices}
          onChange={props.onChange}
          placeholder="Select Office"
          width="250px"
        />
      </div>
      <div className="filters-grid">

        <Form
          formName="reactselect"
          name="assetGroup"
          value={assetGroup}
          options={assetGroups}
          onChange={props.onChange}
          placeholder="Select Asset Group"
          width="250px"
        />
      </div>

      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="assetSubgroup"
          value={assetSubgroup}
          options={assetSubgroups}
          onChange={props.onChange}
          placeholder="Select Asset Sub-Group"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="assetSubCode"
          value={assetSubCode}
          options={assetSubCodes}
          onChange={props.onChange}
          placeholder="Select Asset Sub-code"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="entryUser"
          value={entryUser}
          options={entryUsers}
          onChange={props.onChange}
          placeholder="Select Entry User"
          width="250px"
        />
      </div>
      {showWaitingApproval() &&
        <div className="filters-grid">

          <Form
            formName="reactselect"
            name="waitingApproval"
            value={waitingApproval}
            options={waitingApprovalOption}
            onChange={props.onChange}
            placeholder="Waiting for Approval"
            width="250px"
          />
        </div>}

    </div>
  );
}

export default SearchAndFilter;
