import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty, InputValidator } from "../../../helpers";
import Form from "../../../components/form";
import Button from "../../../components/button";
import ForgetPasswordModal from "../forgetPassword";
import { SignIn } from "../../../redux/actions/authAction";

function Login(props: any) {
  const [state, setState] = useState<any>({
    username: "",
    password: "",
    errors: {},
  });
  const [showForgetPasswordModal, setForgetPasswordModal] = useState(false);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }
  };

  const onSignIn = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      return null;
    }

    const param = { userName: state.username, password: state.password };
    props.SignIn(param);
  };

  const updateErrors = async (state) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;
    setState(nextState);

    return errors;
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-heading">
          <span className="login-heading-title">NEA Asset Verification</span>
          <span className="login-heading-note">Please Login</span>
        </div>
        <div className="login-body">
          <div className="mb-sm">
            <Form
              formName="textinput"
              name="username"
              value={state.username}
              autoFocus={true}
              onEnterPress={onSignIn}
              onChange={onChange}
              label="Username"
              placeholder="Enter valid username"
              validators={["required"]}
              error={state.errors.username}
            />
          </div>
          <div>
            <Form
              formName="textinput"
              type="password"
              name="password"
              value={state.password}
              onChange={onChange}
              onEnterPress={onSignIn}
              label="Password"
              placeholder="Enter valid password"
              validators={["required"]}
              error={state.errors.password}
            />
          </div>
          <div className="login-body-button">
            <Button
              title="Login"
              buttonType="normal"
              bg="primary"
              onClick={onSignIn}
            />
          </div>
        </div>
        <div className="login-forget">
          <span
            className="link-color"
            onClick={() => setForgetPasswordModal(true)}
          >
            Forget username or password?
          </span>
        </div>
      </div>
      {showForgetPasswordModal && (
        <ForgetPasswordModal
          showModal={showForgetPasswordModal}
          setShowModal={(value) => setForgetPasswordModal(value)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { SignIn };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
