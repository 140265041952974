export default {
  login: "/login",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  users: "/users",
  assetListing: "/assets",
  directorateDepartmentOffices: "/directorateDepartmentOffices",
  groupSubgroupCategory: "/groupSubgroupCategory",
  fieldReport:'/report/fieldUsers',
  approvalReport:'/report/approval',
  pendingApprovalReprot:'/report/pendingApproval',
};
