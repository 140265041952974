import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { otherInputInterface } from "../../ts/interfaces/formInterface";

const TextInput = (props: otherInputInterface): JSX.Element => {
  const {
    name,
    type,
    value,
    autoFocus,
    disabled,
    placeholder,
    minValue,
    maxValue,
  } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleFocus = (event) => {
    if (type === "number") {
      event.target.select();
    }
  };

  // onKeyPress={event => {
  //   if (event.key === 'Enter') {
  //     this.search()
  //   }
  // }}

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      props.onEnterPress && props.onEnterPress();
    }
  };

  return (
    <input
      className={classnames({
        "genericForm-group__input": true,
        "disable-input": disabled,
      })}
      placeholder={placeholder}
      value={value}
      type={type}
      name={name}
      min={minValue}
      max={maxValue}
      autoFocus={autoFocus}
      disabled={disabled}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={onKeyPress}
    />
  );
};

TextInput.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
  placeholder: "",
};

export default TextInput;
