import routes from "../../navigation/routes";
import { api, post } from "../../networkService";
import { createBrowserHistory, createHashHistory } from "history";

export const SignIn = (param) => async (dispatch: Function) => {
  let history = createBrowserHistory();
  const res = await post(api.auth.siginIn, dispatch, param, false);
  if (res.Status) {
    saveToken(res.Data, dispatch);
    window.location.href = routes.dashboard;
    // history.push(routes.dashboard);
  }
};

export const SignOut = () => async (dispatch: Function) => {
  let history = createBrowserHistory();
  const res = await post(api.auth.signOut, dispatch, null, false);
  if (res.Status) {
    localStorage.clear();
    window.location.href = routes.login;
    // history.push(routes.login);
  }
};

const saveToken = (res: any, dispatch: Function) => {
  if (res) {
    //Save to local Storage
    let AuthToken = "bearer " + res.Token;
    let expires_in = res.ExpireMinutes;
    let RefreshToken = res.RefreshToken;
    let IdleTimeoutMinutes = res.IdleTimeoutMinutes;
    //Set token to ls
    localStorage.setItem("AuthToken", AuthToken);
    localStorage.setItem("UserName", res.Username);
    localStorage.setItem("RefreshToken", RefreshToken);
    localStorage.setItem("IdleTimeOut", IdleTimeoutMinutes);
    localStorage.setItem("ExpiresMinutes", expires_in);

    // const timeout = expires_in * 60 * 1000 - 60 * 1000;
    // const expiryTimer = setTimeout(() => {
    //   // this would reset localStorage before token expiry timr
    //   dispatch(refreshToken());
    // }, timeout);
    // var tokenexpiration = new Date();
    // let expires: number =
    //   tokenexpiration.setSeconds(
    //     new Date().getSeconds() + (parseInt(expires_in) + 5) * 60 - 10
    //     // new Date().getSeconds() + parseInt(expires_in) * 60 - 10
    //   ) / 1000;
    // // parseInt(expires_in) * 60;
    // // (parseInt(expires_in) / 60) * 10 -10) / 1000;

    // let tokenDetails = {
    //   RefreshToken,
    //   expires,
    //   expires_in,
    //   IdleTimeoutMinutes,
    //   AuthToken,
    // };
    // localStorage.setItem("Expires", expires.toString());
    // setAuthToken(AuthToken);
    // dispatch(setCurrentToken(AuthToken, tokenDetails));
    // const promiseArray = [
    //   dispatch(getUser()),
    //   // dispatch(getFeatureFlag()),
    //   // dispatch(getActiveFeatureFlag())
    // ];
    // Promise.all(promiseArray);

    // dispatch(getIsRecommenderOrApprover());
  }
};
