import React, { useState, useEffect } from "react";
import AvatarEdit from "react-avatar-edit";
import Avatar from "react-avatar";
import Button from "../../components/button";
import { AiFillCloseCircle } from "react-icons/ai";
import { isEmpty } from "../../helpers";
import { validImageExtension } from "../../constants/validExtensions";
import base64ToFile from "../../helpers/base64ToFile";
import { fileUrl } from "../../constants/urls";

function ImageCropper(props: any) {
  const { selectedImage, imageUrlToEdit } = props;
  const [state, setState] = useState({
    preview: selectedImage.preview,
    src: selectedImage.src,
    isPhotoSelectionCompleted: false,
    error: null,
  });

  useEffect(() => {
    if (imageUrlToEdit.preview) {
      setState({
        ...state,
        preview: `${fileUrl}${imageUrlToEdit.preview}`,
        isPhotoSelectionCompleted: true,
      });
    }
  }, [imageUrlToEdit]);

  const handleChange = (name, value) => {
    const nextState = { ...state };
    nextState[name] = value;
    if (name != "error") {
      nextState["error"] = null;
    }
    setState(nextState);
  };

  const handleDoneCrop = async () => {
    handleChange("isPhotoSelectionCompleted", true);

    const previewFileName = "thumbnail_" + state.src.name;
    let previewImageFile = await base64ToFile(state.preview, previewFileName);

    const selectedImage = {
      src: state.src,
      preview: previewImageFile,
    };
    props.onChange && props.onChange(props.name, selectedImage);
  };

  const handleRemoveImage = () => {
    const nextState = { ...state };
    nextState["isPhotoSelectionCompleted"] = false;
    nextState["preview"] = null;
    nextState["src"] = null;
    setState(nextState);

    const selectedImage = {
      src: null,
      preview: null,
    };
    props.onChange && props.onChange(props.name, selectedImage);
  };

  const onClose = () => {
    handleChange("preview", null);
  };

  const onCrop = (preview) => {
    handleChange("preview", preview);
  };

  const onBeforeFileLoad = (elem) => {
    const file = elem.target.files[0];
    const validExtensions = validImageExtension;
    const extension = file.name.split(".")[1];
    const isValidExtension = validExtensions.find(
      (f) => f == extension.toLowerCase()
    )
      ? true
      : false;
    if (file.size > 556000) {
      //556000 Byte==556 Kilobyte
      handleChange("error", "Maximum valid file size is 556 Kb.");
      elem.target.value = "";
    } else if (!isValidExtension) {
      handleChange("error", "Valid extension are " + validExtensions);
      elem.target.value = "";
    } else {
      handleChange("src", file);
    }
  };

  return (
    <div className="image-cropper">
      {!state.isPhotoSelectionCompleted ? (
        <div className="image-cropper-container">
          <AvatarEdit
            width={300}
            height={250}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
            src={state.src}
            label={"Upload photo"}
            borderStyle={{
              border: "1px solid #979797",
              borderStyle: "dashed",
              borderRadius: "8px",
              textAlign: "center",
            }}
          />
          {!isEmpty(state.preview) && (
            <Button title="Done" bg="secondary" onClick={handleDoneCrop} />
          )}
          {!isEmpty(state.error) && (
            <span className={"error__message aligin-center"}>
              {state.error}
            </span>
          )}
        </div>
      ) : (
        <div className="upload-section__new">
          <div className="upload-img__new">
            <Avatar
              className="user-avatar"
              name={"photo"}
              size={"200"}
              // round={true}
              src={state.preview}
            />
            <span className="upload-icon__new" onClick={handleRemoveImage}>
              <AiFillCloseCircle />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageCropper;
