export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_OTHER_MESSAGES = "ADD_OTHER_MESSAGES";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ADD_LOADING = "ADD_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const GET_DASHBOARD_PROGRESS_SUMMARY = "GET_DASHBOARD_PROGRESS_SUMMARY";
export const GET_DASHBOARD_OVERALL_SUMMARY = "GET_DASHBOARD_OVERALL_SUMMARY";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const COUNTRY_LOADING = "COUNTRY_LOADING";
export const GET_PROVINCE = "GET_PROVINCE";
export const PROVINCE_LOADING = "PROVINCE_LOADING";
export const GET_DIRECTORATE = "GET_DIRECTORATE";
export const DIRECTORATE_LOADING = "DIRECTORATE_LOADING";
export const GET_DISTRICTS = "GET_DISTRICTS";
export const DISTRICTS_LOADING = "DISTRICTS_LOADING";
export const GET_ASSETGROUP = "GET_ASSETGROUP";
export const ASSETGROUP_LOADING = "ASSETGROUP_LOADING";
export const GET_ASSETSUBGROUP = "GET_ASSETSUBGROUP";
export const ASSETSUBGROUP_LOADING = "ASSETSUBGROUP_LOADING";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const DEPARTMENTS_LOADING = "DEPARTMENTS_LOADING";
export const GET_OFFICES = "GET_OFFICES";
export const OFFICETYPES_LOADING = "OFFICETYPES_LOADING";
export const GET_OFFICETYPES = "GET_OFFICETYPES";
export const OFFICES_LOADING = "OFFICES_LOADING";
export const GET_UOMS = "GET_UOMS";
export const UOMS_LOADING = "UOMS_LOADING";
export const GET_ASSETSUBCODES = "GET_ASSETSUBCODES";
export const ASSETSUBCODES_LOADING = "ASSETSUBCODES_LOADING";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const LOCATIONS_LOADING = "LOCATIONS_LOADING";
export const ASSETCONDITION_LOADING = "ASSETCONDITION_LOADING";
export const GET_ASSETCONDITION = "GET_ASSETCONDITION";
export const GET_ENTRYUSERS = "GET_ENTRYUSERS";
export const GET_ENTRYUSERS_LOADING = "GET_ENTRYUSERS_LOADING";

export const GET_USERS = "GET_USERS";
export const GET_LOGGEDIN_USER_DETAIL = "GET_LOGGEDIN_USER_DETAIL";
export const GET_USER_BY_USERNAME = "GET_USER_BY_USERNAME";

export const GET_ROLES = "GET_ROLES";

export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSETS_LOADING = "GET_ASSETS_LOADING";
export const GET_ASSETS_EXPORT_EXCEL = "GET_ASSETS_EXPORT_EXCEL";
export const GET_ASSETS_BY_ID = "GET_ASSETS_BY_ID";
export const ON_COMMENT_ASSET = "ON_COMMENT_ASSET";
export const GET_ASSET_GROUPS = "GET_ASSET_GROUPS";

export const GET_DIRECTORATE_AND_DEPARTMENT_LIST =
  "GET_DIRECTORATE_AND_DEPARTMENT_LIST";
export const GET_DEPARTMENT_BY_ID = "GET_DEPARTMENT_BY_ID";
export const GET_OFFICETYPE_LIST = "GET_OFFICETYPE_LIST";
export const GET_OFFICE_LIST = "GET_OFFICE_LIST";

export const GET_REPORT_APPROVAL = "GET_REPORT_APPROVAL";
export const GET_REPORT_APPROVAL_EXCEL = "GET_REPORT_APPROVAL_EXCEL";
export const GET_REPORT_APPROVAL_LOADING = "GET_REPORT_APPROVAL_LOADING";

export const GET_REPORT_FIELD_USERS_EXCEL = "GET_REPORT_FIELD_USERS_EXCEL";
export const GET_REPORT_FIELD_USERS = "GET_REPORT_FIELD_USERS";
export const GET_REPORT_FIELD_USERS_LOADING = "GET_REPORT_FIELD_USERS_LOADING";

export const GET_REPORT_PENDING_APPROVAL_EXCEL = "GET_REPORT_PENDING_APPROVAL_EXCEL";
export const GET_REPORT_PENDING_APPROVAL = "GET_REPORT_PENDING_APPROVAL";
export const GET_REPORT_PENDING_APPROVAL_LOADING = "GET_REPORT_PENDING_APPROVAL_LOADING";

export const GET_APP_VERSION = "GET_APP_VERSION"