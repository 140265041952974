import React, { Component, useEffect } from "react";
import { Route } from "react-router";
import routes from "../routes";
import NavBar from "../navBar";
import Login from "../../scenes/auth/login";
import ResetPassword from "../../scenes/auth/resetPassword";
import Dashboard from "../../scenes/dashboard";
import Users from "../../scenes/users";
import Assets from "../../scenes/assetsManagement/assets";
import DirectorateDepartmentOffices from "../../scenes/assetsManagement/directorateDepartmentOffices";
import GroupSubgroupCategory from "../../scenes/assetsManagement/groupSubgroupCategory";
import FieldUsersReport from '../../scenes/report/fieldUsers';
import ApprovalReport from '../../scenes/report/approval'
import PendingApprovalReport from '../../scenes/report/pendingApproval'

function Links() {
  return (
    <NavBar>
      <Route exact path="/" component={Dashboard} />
      <Route exact path={routes.dashboard} component={Dashboard} />
      <Route path={routes.login} component={Login} />
      <Route path={routes.resetPassword} component={ResetPassword} />

      <Route path={routes.users} component={Users} />
      <Route path={routes.assetListing} component={Assets} />
      <Route
        path={routes.directorateDepartmentOffices}
        component={DirectorateDepartmentOffices}
      />
      <Route
        path={routes.groupSubgroupCategory}
        component={GroupSubgroupCategory}
      />
      <Route path={routes.fieldReport} component={FieldUsersReport}/>
      <Route path={routes.approvalReport} component={ApprovalReport}/>
      <Route path={routes.pendingApprovalReprot} component={PendingApprovalReport}/>
    </NavBar>
  );
}

export default Links;
