import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/AddBoxOutlined";
import ExpandLessIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";

import Button from "../../../../components/button";
import Icons from "../../../../components/icons";
import AssetSubgroupModal from "../forms/assetSubgroup";
import AssetCategoryModal from "../forms/assetCategory";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .015)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    height: 40,
    "&$expanded": {
      minHeight: 45,
    },
    padding: 5,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function SubAccordion(props: any) {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [modalStatus, setModalStatus] = useState({
    showCategoryModal: false,
    showSubgroupModal: false,
  });
  const [selectedSubGroupData, setSelectedSubGroupData] = useState<any>(null);
  const [selectedSubCodeData, setSelectedSubCodeData] = useState<any>(null);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleModals = (name: any, value: any) => {
    setModalStatus({
      ...modalStatus,
      [name]: value,
    });
  };

  const handleCategoryModal = (e: any, subGroup: any) => {
    stopDefaultAction(e);
    setSelectedSubCodeData(null);
    setSelectedSubGroupData({ ...subGroup, GroupCode: props.GroupCode });
    handleModals("showCategoryModal", true);
  };

  const handleEditSubgroup = (e: any, subGroup: any) => {
    stopDefaultAction(e);
    setSelectedSubCodeData(null);
    setSelectedSubGroupData({ ...subGroup, GroupCode: props.GroupCode });
    handleModals("showSubgroupModal", true);
  };

  const handleDeleteSubgroup = (e: any, subGroup: any) => {
    stopDefaultAction(e);
    props.DeleteAssetSubGroup(subGroup.SubGroupId, props.refreshGroup);
  };

  const handleEditSubCode = (e: any, subGroup: any, subCode: any) => {
    stopDefaultAction(e);
    setSelectedSubGroupData(null);
    setSelectedSubCodeData({
      ...subCode,
      GroupCode: props.GroupCode,
      SubGroupCode: subGroup.SubGroupCode,
    });
    handleModals("showCategoryModal", true);
  };

  const handleDeleteSubCode = (e: any, subCode: any) => {
    stopDefaultAction(e);
    props.DeleteAssetSubCode(subCode.SubCodeId, props.refreshGroup);
  };

  const stopDefaultAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="subAccordion">
      {props.SubGroups &&
        props.SubGroups.map((subGroup: any, i: any) => {
          const { SubGroupCode, SubGroupDesc, SubGroupId } = subGroup;
          const activePanel = `panel${i + 1}`;
          return (
            <div className="mb-xsm">
              <Accordion
                square
                expanded={expanded === activePanel}
                onChange={handleChange(activePanel)}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${activePanel}-content`}
                  id={`${activePanel}-header`}
                >
                  {expanded === activePanel ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                  <span className="sub-block">
                    <span className="sub-block-name">
                      <span className="mr-sm">{SubGroupCode}</span>
                      <span>{SubGroupDesc}</span>
                    </span>

                    <span className="sub-block-actions">
                      <Button
                        title="Add Subcode"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Add" />}
                        bg="primary"
                        onClick={(e: any) => handleCategoryModal(e, subGroup)}
                      />
                      <Button
                        title="Edit"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Edit" />}
                        bg="primary"
                        buttonClass="ml-sm mr-sm"
                        onClick={(e: any) => handleEditSubgroup(e, subGroup)}
                      />
                      <Button
                        title="Delete"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Delete" />}
                        bg="danger"
                        onClick={(e: any) => handleDeleteSubgroup(e, subGroup)}
                      />
                    </span>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="subAccordion-detail">
                    {subGroup.SubCodes &&
                      subGroup.SubCodes.map((subCode: any, ind: number) => {
                        const {
                          AccountCode,
                          SubCode,
                          SubCodeId,
                          SubCodeName,
                        } = subCode;
                        return (
                          <span className="sub-block" key={ind}>
                            <span className="sub-block-description">
                              <span className="mr-sm">{SubCode}</span>
                              <span>{SubCodeName}</span>
                              {AccountCode && (
                                <span className="ml-sm">{`[${AccountCode}]`}</span>
                              )}
                            </span>
                            <span className="sub-block-actions">
                              <Button
                                title="Edit"
                                buttonType="icon-button"
                                leftIcon={<Icons name="Edit" />}
                                bg="primary"
                                buttonClass="ml-sm mr-sm"
                                onClick={(e: any) =>
                                  handleEditSubCode(e, subGroup, subCode)
                                }
                              />
                              <Button
                                title="Delete"
                                buttonType="icon-button"
                                leftIcon={<Icons name="Delete" />}
                                bg="danger"
                                onClick={(e: any) =>
                                  handleDeleteSubCode(e, subCode)
                                }
                              />
                            </span>
                          </span>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}

      {modalStatus.showSubgroupModal && (
        <AssetSubgroupModal
          showModal={modalStatus.showSubgroupModal}
          setShowModal={(value) => handleModals("showSubgroupModal", value)}
          refreshGroup={props.refreshGroup}
          dataToEdit={selectedSubGroupData}
        />
      )}
      {modalStatus.showCategoryModal && (
        <AssetCategoryModal
          showModal={modalStatus.showCategoryModal}
          setShowModal={(value) => handleModals("showCategoryModal", value)}
          refreshGroup={props.refreshGroup}
          propsData={selectedSubGroupData}
          dataToEdit={selectedSubCodeData}
        />
      )}
    </div>
  );
}
