import React, { useState } from "react";
import AssetsDetailModal from "../detail";

export default function AssetNameCell(props: any) {
  const { Id } = props.data;
  const [showAssetsDetail, setAssetsDetail] = useState(false);

  return (
    <>
      <span className="link-color" onClick={() => setAssetsDetail(true)}>
        {props.value}
      </span>
      {showAssetsDetail && (
        <AssetsDetailModal
          showModal={showAssetsDetail}
          setShowModal={(value) => setAssetsDetail(value)}
          assetId={Id}
        />
      )}
    </>
  );
}
