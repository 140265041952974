import {
  GET_COUNTRIES,
  COUNTRY_LOADING,
  GET_PROVINCE,
  PROVINCE_LOADING,
  GET_DIRECTORATE,
  DIRECTORATE_LOADING,
  GET_DISTRICTS,
  DISTRICTS_LOADING,
  GET_ASSETGROUP,
  ASSETGROUP_LOADING,
  GET_ASSETSUBGROUP,
  ASSETSUBGROUP_LOADING,
  GET_DEPARTMENTS,
  DEPARTMENTS_LOADING,
  GET_OFFICES,
  OFFICES_LOADING,
  GET_OFFICETYPES,
  OFFICETYPES_LOADING,
  GET_UOMS,
  UOMS_LOADING,
  GET_ASSETSUBCODES,
  ASSETSUBCODES_LOADING,
  LOCATIONS_LOADING,
  GET_LOCATIONS,
  GET_ASSETCONDITION,
  ASSETCONDITION_LOADING,
  GET_ENTRYUSERS,
  GET_ENTRYUSERS_LOADING,
} from "../../types";

const initialState = {
  isCountryLoading: false,
  countries: [],
  isProvinceLoading: false,
  provinces: [],
  isDirectorateLoading: false,
  directorates: [],
  isDistrictLoading: false,
  districts: [],
  isAssetGroupLoading: false,
  assetGroups: [],
  isAssetSubgroupLoading: false,
  assetSubgroups: [],
  isDepartmentLoading: false,
  departments: [],
  isOfficeLoading: false,
  offices: [],
  isOfficeTypesLoading: false,
  officeTypes: [],
  isUomsLoading: false,
  uoms: [],
  isAssetSubCodeLoading: false,
  assetSubCodes: [],
  isLocationLoading: false,
  locations: [],
  assetConditions: [],
  isAssetConditionLoading: false,
  entryUsers: [],
  isEntryUsersLoading: false,
};

export function dropdownReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        isCountryLoading: false,
        countries: action.payload,
      };

    case COUNTRY_LOADING:
      return {
        ...state,
        isCountryLoading: action.payload,
      };

    case GET_PROVINCE:
      return {
        ...state,
        isProvinceLoading: false,
        provinces: action.payload,
      };

    case PROVINCE_LOADING:
      return {
        ...state,
        isProvinceLoading: action.payload,
      };

    case GET_DIRECTORATE:
      return {
        ...state,
        isDirectorateLoading: false,
        directorates: action.payload,
      };

    case DIRECTORATE_LOADING:
      return {
        ...state,
        isDirectorateLoading: action.payload,
      };

    case GET_DISTRICTS:
      return {
        ...state,
        isDistrictLoading: false,
        districts: action.payload,
      };

    case DISTRICTS_LOADING:
      return {
        ...state,
        isDistrictLoading: action.payload,
      };

    case GET_ASSETGROUP:
      return {
        ...state,
        isAssetGroupLoading: false,
        assetGroups: action.payload,
      };

    case ASSETGROUP_LOADING:
      return {
        ...state,
        isAssetGroupLoading: action.payload,
      };

    case GET_ASSETSUBGROUP:
      return {
        ...state,
        isAssetSubgroupLoading: false,
        assetSubgroups: action.payload,
      };

    case ASSETSUBGROUP_LOADING:
      return {
        ...state,
        isAssetSubgroupLoading: action.payload,
      };

    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        isDepartmentLoading: false,
      };

    case DEPARTMENTS_LOADING:
      return {
        ...state,
        isDepartmentLoading: action.payload,
      };

    case GET_OFFICES:
      return {
        ...state,
        offices: action.payload,
        isOfficeLoading: false,
      };

    case OFFICES_LOADING:
      return {
        ...state,
        isOfficeLoading: action.payload,
      };

    case GET_OFFICETYPES:
      return {
        ...state,
        officeTypes: action.payload,
        isOfficeTypesLoading: false,
      };

    case OFFICETYPES_LOADING:
      return {
        ...state,
        isOfficeTypesLoading: action.payload,
      };

    case GET_UOMS:
      return {
        ...state,
        uoms: action.payload,
        isUomsLoading: false,
      };

    case UOMS_LOADING:
      return {
        ...state,
        isUomsLoading: action.payload,
      };

    case GET_ASSETSUBCODES:
      return {
        ...state,
        assetSubCodes: action.payload,
        isAssetSubCodeLoading: false,
      };

    case ASSETSUBCODES_LOADING:
      return {
        ...state,
        isAssetSubCodeLoading: action.payload,
      };

    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        isLocationLoading: false,
      };

    case LOCATIONS_LOADING:
      return {
        ...state,
        isLocationLoading: action.payload,
      };

    case GET_ASSETCONDITION:
      return {
        ...state,
        assetConditions: action.payload,
        isAssetConditionLoading: false,
      };

    case ASSETCONDITION_LOADING:
      return {
        ...state,
        isAssetConditionLoading: action.payload,
      };
    case GET_ENTRYUSERS_LOADING:
      return {
        ...state,
        isEntryUsersLoading: action.payload,
      };
    case GET_ENTRYUSERS:
      return {
        ...state,
        entryUsers: action.payload,
        isEntryUsersLoading: false,
      };
    default:
      return state;
  }
}
