import * as React from "react";
import classnames from "classnames";
import { isEmpty } from "../../helpers";
import { checkRadioInterface } from "../../ts/interfaces/formInterface";

const CheckGroup: React.FunctionComponent<checkRadioInterface> = (props) => {
  const {
    name,
    value,
    error,
    label,
    checked,
    className,
    labelPosition,
    disabled,
  } = props;
  var inputclass = classnames({
    "custom-check": true,
    error: !isEmpty(error) && error,
    "genericForm-group__label": true,
    // [size]:size,
    "label-right": labelPosition === "right",
  });

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    if (value == "true") {
      value = false;
    } else if (value == "false") {
      value = true;
    }
    props.onChange && props.onChange(name, value);
  };
  return (
    <label className={inputclass}>
      <span>{label}</span>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
      <div className="checkmark"></div>
    </label>
  );
};

CheckGroup.defaultProps = {
  disabled: false,
};

export default CheckGroup;
