export default function getSalutation() {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr >= 5 && curHr < 12) {
    return "Good Morning";
  } else if (curHr < 15) {
    return "Good Afternoon";
  } else if (curHr < 18) {
    return "Good Evening";
  } else {
    return "Good Night";
  }
}
