import { api, get, get_by_id } from "../../networkService";
import {
  GET_DASHBOARD_PROGRESS_SUMMARY,
  GET_DASHBOARD_OVERALL_SUMMARY,
} from "../types";

export const GetProgressSummary = (params: any) => async (
  dispatch: Function
) => {
  const res = await get(api.dashboard.directorateStatistics, dispatch, params);
  dispatch({ type: GET_DASHBOARD_PROGRESS_SUMMARY, payload: res.Data });
};

export const GetOverallSummary = () => async (dispatch: Function) => {
  const res = await get(api.dashboard.summaries, dispatch);
  dispatch({ type: GET_DASHBOARD_OVERALL_SUMMARY, payload: res.Data });
};
