import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { SaveAssetGroup } from "../../../../redux/actions/assetsAction";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import { InputValidator, isEmpty } from "../../../../helpers";
import { GetDirectorate } from "../../../../redux/actions/common/dropdownAction";

function AssetGroupModal(props: any) {
  const { dataToEdit } = props;
  const [state, setState] = useState<any>({
    groupId: 0,
    groupCode: 0,
    groupName: null,
    directorate: null,
  });

  useEffect(() => {
    props.GetDirectorate();
  }, []);

  useEffect(() => {
    if (dataToEdit) {
      let directorate = props.directorates.find(
        (f) => f.value == dataToEdit.DirectorateRefId
      );

      setState({
        groupId: dataToEdit.GroupId,
        groupCode: dataToEdit.GroupCode,
        groupName: dataToEdit.GroupDesc,
        directorate: directorate,
      });
    }
  }, [dataToEdit]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }

    const param: any = {
      id: state.groupId,
      groupCode: state.groupCode,
      groupDesc: state.groupName,
    };
    if (state.directorate) {
      param.directorateRefId = state.directorate.value;
    }
    props.SaveAssetGroup(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshGroup && props.refreshGroup();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Asset Group"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="groupCode"
            value={state.groupCode}
            onChange={onChange}
            label="Group Code"
            type="number"
            validators={["required"]}
            error={state.errors && state.errors.groupCode}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="groupName"
            value={state.groupName}
            onChange={onChange}
            label="Group Name"
            validators={["required"]}
            error={state.errors && state.errors.groupName}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="reactselect"
            name="directorate"
            value={state.directorate}
            options={props.directorates}
            label="Directorate"
            onChange={onChange}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  directorates: state.dropdownReducer.directorates,
  //   searchedRigoProduct: state.manageProductReducer.searchedRigoProduct,
});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  SaveAssetGroup,
  GetDirectorate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetGroupModal);
