import React, { useState } from "react";
import Icons from "../../../../components/icons";
import Button from "../../../../components/button";
import AssetsDetailModal from "../detail";
import Modal from "../../../../components/modal";
import { assetsStageEnum } from "../../../../enums/assets";

export default function ActionCell(props: any) {
  const {
    Id,
    IsAllowApprove,
    IsAllowComment,
    IsAllowDelete,
    IsAllowEdit,
    IsAllowToSendBackForReview,
    Status,
  } = props.data;

  const [showAssetsDetail, setAssetsDetail] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmType, setConfirmType] = useState("");

  const onEditAsset = () => {
    setAssetsDetail(true);
  };

  const onDeleteAsset = () => {
    props.DeleteAssets(Id, props.refreshContent);
  };

  const onApproveAsset = () => {
    if (IsAllowApprove) {
      const param = {
        // id: 0,
        assetRefId: Id,
        // approvalRemarks: "string",
      };
      props.ApproveAsset(param, props.refreshContent);
    }
  };

  let buttonTitle = IsAllowApprove ? "Approve" : "";
  return (
    <>
      <Button
        title={buttonTitle}
        buttonType="drop-button"
        bg={buttonTitle ? "green" : "secondary"}
        useCustomPopup={true}
        onClick={() => {
          let msg = "Are you sure, you want to approve?";
          if (Status == assetsStageEnum.reDraft) {
            msg =
              "This action will delete any unsynced data of this Asset from Asset Register Tool (Mobile App). Are you sure, you want to continue?";
          }
          setConfirmContent(msg);
          setConfirmType("approve");
          setShowConfirm(true);
        }}
        // justDrop
        dropComponent={
          <ul className="button-drop-list">
            <li className="button-drop-list__item flex" onClick={onEditAsset}>
              <Icons name="Eye" />
              <span className="button-drop-name ml-xsm">Details</span>
            </li>
            {/* <li className="button-drop-list__item with-hr flex">
              <Icons name="Edit" />
              <span className="button-drop-name ml-xsm">Edit</span>
            </li> */}
            {IsAllowDelete && (
              <li className="button-drop-list__item with-hr flex">
                <Icons name="Close" />
                <span
                  className="button-drop-name ml-xsm"
                  onClick={() => {
                    setConfirmContent("Are you sure, you want to delete?");
                    setConfirmType("delete");
                    setShowConfirm(true);
                  }}
                >
                  Delete
                </span>
              </li>
            )}
          </ul>
        }
      />

      {showAssetsDetail && (
        <AssetsDetailModal
          showModal={showAssetsDetail}
          setShowModal={(value) => setAssetsDetail(value)}
          refreshContent={props.refreshContent}
          assetId={Id}
        />
      )}

      <Modal
        open={showConfirm}
        onModalClose={() => setShowConfirm(false)}
        hideFooter={true}
        type="confirm"
        content={confirmContent}
        func={() => {
          if (confirmType == "delete") {
            onDeleteAsset();
          } else if (confirmType == "approve") {
            onApproveAsset();
          }
        }}
        title={"Confirm!"}
      ></Modal>
    </>
  );
}
