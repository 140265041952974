import React, { Component } from "react";
import "./App.scss";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import Toast from "./components/toast";
import Links from "./navigation/links";

const store = configureStore({});
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="app">
          <Links />
          <Toast />
        </div>
      </Provider>
    );
  }
}
