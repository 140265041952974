import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MaterialPagination from "material-ui-flat-pagination";
import { TablePagination } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";
import { isEmpty } from "../../helpers";
import Icons from "../../components/icons";
import { withStyles } from "@material-ui/core/styles";

const theme = createMuiTheme();

const styles = (theme: any) => ({
  root: {
    borderTop: "1px solid #f3f3f3",
    display: "flex",
    // flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
});

export class Pagination extends Component<any> {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangePage = (event, newPage) => {
    this.props.handleIndexChange(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.handleRowChange(0, event.target.value);
  };
  render() {
    const {
      rowsPerPage,
      pageIndex,
      total,
      searchedPage,
      handleIndexChange,
    } = this.props;
    const { classes } = this.props;

    return (
      <div className="paginate">
        <div className={classes.root}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={!isEmpty(total) ? total : searchedPage.total}
            rowsPerPage={rowsPerPage}
            page={pageIndex}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <MaterialPagination
              previousPageLabel={<Icons name="AngleLeft" />}
              nextPageLabel={<Icons name="AngleRight" />}
              size={"small"}
              limit={rowsPerPage}
              offset={rowsPerPage * pageIndex}
              total={!isEmpty(total) ? total : searchedPage.total}
              // textPrimary="#2680eb"
              // otherPageColor={"#646464"}
              // otherPageColor={"secondary"}
              onClick={(de, offset) => handleIndexChange(offset / rowsPerPage)}
            />
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Pagination);
