import {
  api,
  deletion,
  get,
  get_by_id,
  post,
  put,
  put_inline_param,
} from "../../networkService";
import {
  GET_USERS,
  GET_LOGGEDIN_USER_DETAIL,
  GET_USER_BY_USERNAME,
} from "../types";
import { serialize } from "object-to-formdata";
import routes from "../../navigation/routes";

const formOptions = {
  indices: true,
  nullsAsUndefineds: false,
  booleansAsIntegers: false,
};

export const GetUsers = (params: any) => async (dispatch: Function) => {
  const res = await post(api.user.userList, dispatch, params, false);
  dispatch({ type: GET_USERS, payload: res.Data });
};

export const GetLoggedInUserDetails = (params: any) => async (
  dispatch: Function
) => {
  const res = await get(api.user.userDetails, dispatch);
  dispatch({ type: GET_LOGGEDIN_USER_DETAIL, payload: res });
};

export const GetUserByName = (username: any) => async (dispatch: Function) => {
  if (!username) {
    dispatch({ type: GET_USER_BY_USERNAME, payload: null });
  } else {
    const res = await get_by_id(api.user.users, dispatch, username, false);
    dispatch({ type: GET_USER_BY_USERNAME, payload: res.Data });
  }
};

export const CreateUsers = (
  params: any,
  selectedImage: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  let formData = serialize(params, formOptions);
  if (selectedImage.src) {
    formData.append(`userImage`, selectedImage.src);
  }

  if (selectedImage.preview) {
    formData.append(`userImageThumbnail`, selectedImage.preview);
  }

  const res = await post(api.user.users, dispatch, formData, true);
  if (res.Status) {
    refreshContent && refreshContent();
  }
};

export const UpdateUsers = (
  params: any,
  selectedImage: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  let formData = serialize(params, formOptions);
  if (selectedImage.src) {
    formData.append(`userImage`, selectedImage.src);
  }

  if (selectedImage.preview) {
    formData.append(`userImageThumbnail`, selectedImage.preview);
  }

  const res = await put(api.user.users, dispatch, formData, true);

  if (res.Status) {
    refreshContent && refreshContent();
  }
};

export const ChangeUserStatus = (
  params: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  const res = await put_inline_param(api.user.status, dispatch, params);
  if (res.Status) {
    refreshContent && refreshContent();
  }
};

export const ChangeUserPassword = (
  params: any,
  onRefreshContent: Function
) => async (dispatch: Function) => {
  const res = await post(api.user.changeUserPassword, dispatch, params);
  res.Status && onRefreshContent && onRefreshContent();
};

export const ChangeOwnPassword = (
  params: any,
  onRefreshContent: Function
) => async (dispatch: Function) => {
  const res = await post(api.user.changeOwnPassword, dispatch, params);
  if (res.Status) {
    onRefreshContent && onRefreshContent();
    localStorage.clear();
    window.location.href = routes.login;
  }
};

export const SendPasswordResetLink = (
  params: any,
  onRefreshContent: Function
) => async (dispatch: Function) => {
  const res = await post(api.user.forgotPassword, dispatch, params);
  res.Status && onRefreshContent && onRefreshContent();
};

export const ResetUserPassword = (params: any) => async (
  dispatch: Function
) => {
  const res = await post(api.user.resetPassword, dispatch, params);
  if (res.Status) {
    localStorage.clear();
    window.location.href = routes.login;
  }
};

export const DeleteUser = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.user.users, dispatch, id);

  if (res.Status) {
    refreshContent && refreshContent();
  }
};
