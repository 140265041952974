export const ddlDataFormater = (data: any) => {
  let list = [];
  data &&
    data.forEach((d: any) => {
      list.push({ ...d, label: d.Text || d.Name, value: d.Value || d.Id });
    });
  return list;
};

export const ddlDataFormaterKeyValue = (
  data: any,
  DisplayField: any,
  ValueField: any
) => {
  let list = [];
  data &&
    data.forEach((d: any) => {
      list.push({
        ...d,
        Text: d[DisplayField],
        Value: d[ValueField],
        label: d[DisplayField],
        value: d[ValueField],
      });
    });
  return list;
};
