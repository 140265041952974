import React, { useState } from "react";
import AgGridTable from "../../../../components/tables/agGridTable";
import Button from "../../../../components/button";
import Icons from "../../../../components/icons";

import OfficeTypeModal from "../forms/officeTypes";

const columnDefs = [
  { headerName: "Office Type", field: "OfficeTypeName", width: 250 },
  { headerName: "Directorate", field: "DirectorateName", width: 250 },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "actionComp",
    // cellStyle: { color: "#1786ff" },
    width: 180,
  },
];

function OfficeTypeTable(props: any) {
  const { officeTypeList } = props;

  const [showOfficeTypeModal, setOfficeTypeModal] = useState(false);
  const [selectedOfficeTypeData, setSelectedOfficeTypeData] = useState(null);

  const actionComp = (rowProps: any) => {
    const onEdit = () => {
      setSelectedOfficeTypeData(rowProps.data);
      setOfficeTypeModal(true);
    };
    const onDelete = () => {
      props.DeleteOfficeType(rowProps.data.Id, props.refreshDirectorate);
    };
    return (
      <span className="flex">
        <Button
          title="Edit"
          buttonType="icon-button"
          leftIcon={<Icons name="Edit" />}
          bg="primary"
          onClick={onEdit}
        />
        <Button
          title="Delete"
          buttonType="icon-button"
          leftIcon={<Icons name="Delete" />}
          bg="danger"
          buttonClass="ml-sm"
          onClick={onDelete}
        />
      </span>
    );
  };

  const frameworkComponents = {
    actionComp,
  };

  return (
    <>
      <AgGridTable
        columnDefs={columnDefs}
        rowData={officeTypeList}
        frameworkComponents={frameworkComponents}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
      {showOfficeTypeModal && (
        <OfficeTypeModal
          showModal={showOfficeTypeModal}
          setShowModal={(value) => setOfficeTypeModal(value)}
          refreshContent={props.refreshDirectorate}
          dataToEdit={selectedOfficeTypeData}
        />
      )}
    </>
  );
}

export default OfficeTypeTable;
