import React, { useState } from "react";
import AgGridTable from "../../../components/tables/agGridTable";

/*
"UserName": "sadmin",
    "FullName": "Super Admin",
    "PhysicalLocation": "Loc",
    "AssetOnD1": 0,
    "AssetOnWeekDay": 0,
    "PendingAsset": 0
*/
const columnDefs = [
  {
    headerName: "User Name",
    tooltipField: "FullName",
    field: "FullName",
    fontSize: 13,
    width: 220,
    flex: 1,
  },
  {
    headerName: "Office",
    tooltipField: "Office",
    field: "Office",
    fontSize: 13,
    width: 220,
    flex: 2,
  },
  {
    headerName: "Asset Entries Yesterday",
    tooltipField: "AssetOnD1",
    field: "AssetOnD1",
    fontSize: 13,
    width: 200,
  },
  {
    headerName: "Entries This Week",
    tooltipField: "AssetOnWeekDay",
    field: "AssetOnWeekDay",
    fontSize: 13,
    width: 200,
  },
  {
    headerName: "Comments Pending Addressed",
    tooltipField: "PendingAsset",
    field: "PendingAsset",
    fontSize: 13,
    width: 200,
  },
];

function Table(props: any) {
  return (
    <div className="assets-table">
      <AgGridTable
        columnDefs={columnDefs}
        rowData={props.fieldUsers}
        isLoading={props.fieldUserLoading}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
    </div>
  );
}

export default Table;
