import moment from "moment";
import { AD2BS } from "./dateConvert";
// import { ne, en } from "../../constants/monthName";

export const Date_Format = (date) => {
  // return moment(new Date(date)).format("YYYY/MM/DD");
  return moment(new Date(date)).format("MM/DD/YYYY");
};
export const Nepali_Date_Format = (date) => {
  // return moment(new Date(date)).format("YYYY/MM/DD");
  return moment(new Date(date)).format("YYYY/MM/DD");
};

export const Time_Format = (date) => {
  return moment(new Date(date)).format("hh:mm:ss a");
};

export const Time_Format_HM = (date) => {
  return moment(new Date(date)).format("hh:mm a");
};

export const DateTime_Format = (date) => {
  let result = "";
  if (date) {
    return moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss");
  }
  return result;
};

export const NewDateTime_Format = (date) => {
  let result = "";
  if (date) {
    return moment(new Date(date)).format("YYYY.MM.DD hh:mm a");
  }
  return result;
};

export const formatedMonthDayYear = (date) => {
  let result = "";
  if (date) {
    const momentDate = moment(new Date(date));
    result =
      momentDate.format("MMMM") +
      " " +
      momentDate.format("DD") +
      ", " +
      momentDate.format("YYYY");
  }
  return result;
};

export const formatedDayMonthYear = (date: any, isNepali: boolean = false) => {
  let result = "";
  if (date) {
    if (!isNepali) {
      const momentDate = moment(new Date(date));
      result =
        momentDate.format("DD") +
        " " +
        momentDate.format("MMMM") +
        ", " +
        momentDate.format("YYYY");
    } else {
      const bsDate = AD2BS(date);
      if (bsDate) {
        const { ne, en } = bsDate;
        if (ne) {
          result = ne.day + " " + ne.strMonth + ", " + ne.year;
        }
      }
    }
  }
  return result;
};

export const formatedMonthDayYearTime = (date) => {
  let result = "";
  if (date) {
    const momentDate = moment(new Date(date));
    result =
      momentDate.format("MMM") +
      " " +
      momentDate.format("DD") +
      ", " +
      momentDate.format("YYYY") +
      " " +
      momentDate.format("HH:mm");
  }
  return result;
};
export const newformatedMonthDayYearTime = (date) => {
  let result = "";
  if (date) {
    const momentDate = moment(new Date(date));
    result =
      momentDate.format("MMMM") +
      " " +
      momentDate.format("DD") +
      ", " +
      momentDate.format("YYYY") +
      " " +
      momentDate.format("hh:mm a");
  }
  return result;
};

export const formatedDayMonthYearWDay = (date) => {
  let result = "";
  if (date) {
    const momentDate = moment(new Date(date));
    result =
      momentDate.format("DD") +
      " " +
      momentDate.format("MMMM") +
      ", " +
      momentDate.format("YYYY") +
      " " +
      momentDate.format("dddd");
  }
  return result;
};

export const formatedShortMonthDayYear = (date) => {
  let result = "";
  if (date) {
    const momentDate = moment(new Date(date));
    result =
      momentDate.format("MMM") +
      " " +
      momentDate.format("DD") +
      ", " +
      momentDate.format("YYYY");
  }
  return result;
};

export const formatedToMonthDay = (date) => {
  const momentDate = moment(new Date(date));
  return momentDate.format("MMMM") + " " + momentDate.format("DD");
};

export const startDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .startOf("day")
    .format("MM/DD/YYYY HH:mm:ss");
};

export const endDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .endOf("day")
    .format("MM/DD/YYYY HH:mm:ss");
};

export const getHrsMinFormatFromMinutes = (mins) => {
  let hours = mins / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + "h " + rminutes + "m";
};

export const isDateTodayOrAfter = (toCheck, givenDate) => {
  return (
    moment(new Date(toCheck)).isAfter(new Date(givenDate)) ||
    moment(new Date(toCheck)).isSame(new Date(givenDate), "day")
  );
};

export const getDateDifference = (start, end) => {
  var a = moment(start);
  var b = moment(end);
  return b.diff(a, "days");
};
