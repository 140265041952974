import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import ProgressSummary from "./progressSummary";
import OverallSummary from "./overallSummary";
import { formatedDayMonthYear } from "../../helpers/date/dateFormat";
import getCurrentTimeSalutation from '../../helpers/salutation';

function Dashboard() {
  const {
    UserId,Email,PhoneNumber,UserName,FullName,RoleId,RoleName,UserImage,UserImageThumbnail
  } = useSelector((state:any) => {
    return state.usersReducer.loggedInUserDetail;
  });

  return (
    <div className="common-container dashboard">
      <div className="dashboard-title_bar">
        <div className="dashboard-title_bar-username">{`${getCurrentTimeSalutation()}, ${FullName}`}</div>
        <div className="dashboard-title_bar-date">
          <span className="dashboard-title_bar-date-nepali">
            {formatedDayMonthYear(new Date(), true)}
          </span>
          <span className="dashboard-title_bar-date-english">
            {formatedDayMonthYear(new Date())}
          </span>
        </div>
      </div>
     
      <ProgressSummary />
      <OverallSummary />
    </div>
  );
}

export default Dashboard;
