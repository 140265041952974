import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/modal";
import Form from "../../../components/form";
import { InputValidator, isEmpty } from "../../../helpers";
import { SendPasswordResetLink } from "../../../redux/actions/usersAction";
import {DisplayClientSideMessage} from "../../../redux/actions/common"

function ForgetPassword(props: any) {
  const [state, setState] = useState<any>({email:''});

  const onChange = (name: any, value: any) => {
    let nextState={...state};
    nextState[name]=value;
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }else{
    setState(nextState);
    }
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;
    setState(nextState);
    return errors;
  };

  const onSendResetLink = async() => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      return null;
    }
    const param = {
      email: state.email,
    };
    props.SendPasswordResetLink(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSendResetLink}
      saveButtonText="Get Reset Link"
      title={"Forget Password?"}
      width={"500px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="email"
            value={state.email}
            onChange={onChange}
            label="E-mail"
            placeholder="Please enter valid email, to get reset link."
            validators={["required",'email']}
            error={state.errors && state.errors.email}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  SendPasswordResetLink,
  DisplayClientSideMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
