import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import {
  GetOfficeTypes,
  GetProvinces,
} from "../../../../redux/actions/common/dropdownAction";
import { SaveOffice } from "../../../../redux/actions/organizationAction";
import { InputValidator, isEmpty } from "../../../../helpers";

function Office(props: any) {
  const { dataToEdit } = props;

  const [state, setState] = useState<any>({
    officeId: 0,
    officeCode: "",
    officeName: "",
    officeType: null,
    provience: null,
    officeAddress: "",
    officeIncharge: "",
    inchargeMobile: "",
    officePhone: null,
    email: "",
  });

  useEffect(() => {
    props.GetOfficeTypes();
    props.GetProvinces();
  }, []);

  useEffect(() => {
    if (dataToEdit && props.officeTypes && props.provinces) {
      let currentOfficeType = props.officeTypes.find(
        (f) => f.value == dataToEdit.OfficeTypeRefId
      );
      let currentProvience = props.provinces.find(
        (f) => f.value == dataToEdit.ProvinceRefId
      );
     
      setState({
        officeId: dataToEdit.Id,
        officeCode: dataToEdit.Code,
        officeName: dataToEdit.OfficeName,
        officeType: currentOfficeType || null,
        provience: currentProvience || null,
        officeAddress: dataToEdit.Address,
        officeIncharge: dataToEdit.Incharge,
        inchargeMobile: dataToEdit.InchargeMobile,
        officePhone: dataToEdit.Phone,
        email: dataToEdit.Email,
      });
    }
  }, [dataToEdit, props.officeTypes, props.provinces]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;

    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }

    const param = {
      id: state.officeId,
      name: state.officeName,
      code: state.officeCode,
      officeTypeRefId: state.officeType ? state.officeType.value : 0,
      provinceRefId: state.provience ? state.provience.value : 0,
      address: state.officeAddress,
      phone: state.officePhone,
      email: state.email,
      incharge: state.officeIncharge,
      inchargeMobile: state.inchargeMobile,
    };
    props.SaveOffice(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshContent && props.refreshContent();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Office"}
      width={"800px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="officeCode"
            value={state.officeCode}
            onChange={onChange}
            label="Office Code"
            width="250px"
            validators={["required"]}
            error={state.errors && state.errors.officeCode}
          />
        </div>
        <div className="office_location-add">
          <div className="office_location-add-left">
            <div className="mb-sm">
              <Form
                formName="textinput"
                name="officeName"
                value={state.officeName}
                onChange={onChange}
                label="Office Name"
                validators={["required"]}
                error={state.errors && state.errors.officeName}
              />
            </div>

            <div className="mb-sm">
              
              <Form
                formName="reactselect"
                name="officeType"
                value={state.officeType}
                options={props.officeTypes}
                onChange={onChange}
                label="Office Type"
                validators={["required"]}
                error={state.errors && state.errors.officeType}
              />
            </div>
            <div className="mb-sm">
              <Form
                formName="reactselect"
                name="provience"
                value={state.provience}
                options={props.provinces}
                onChange={onChange}
                label="Province"
                validators={["required"]}
                error={state.errors && state.errors.provience}
              />
            </div>
            <div className="mb-sm">
              <Form
                formName="textarea"
                name="officeAddress"
                value={state.officeAddress}
                onChange={onChange}
                label="Office Address"
                validators={["required"]}
                error={state.errors && state.errors.officeAddress}
              />
            </div>
          </div>
          <div className="office_location-add-right">
            <div className="mb-sm">
              <Form
                formName="textinput"
                name="officeIncharge"
                value={state.officeIncharge}
                onChange={onChange}
                label="Office Incharge"
              />
            </div>
            <div className="mb-sm">
              <Form
                formName="textinput"
                // type="number"
                name="inchargeMobile"
                value={state.inchargeMobile}
                onChange={onChange}
                label="Incharge Mobile"
              />
            </div>
            <div className="mb-sm">
              <Form
                formName="textinput"
                // type="number"
                name="officePhone"
                value={state.officePhone}
                onChange={onChange}
                label="Office Phone"
              />
            </div>
            <div className="mb-sm">
              <Form
                formName="textinput"
                name="email"
                value={state.email}
                onChange={onChange}
                label="E-mail"
                validators={["required", "email"]}
                error={state.errors && state.errors.email}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  officeTypes: state.dropdownReducer.officeTypes,
  provinces: state.dropdownReducer.provinces,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetOfficeTypes,
  GetProvinces,
  SaveOffice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Office);
