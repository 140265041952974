import React, { Component, useState, useEffect } from "react";
import { connect, useStore } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import { Tab, Tabs } from "../../../components/tabs/index.d";
import { assetsStageEnum } from "../../../enums/assets";
import { GetReportFieldUsers } from "../../../redux/actions/report";
import Table from "./table";
import SearchFilter from "./searchAndFilter";
import { isEmpty } from "../../../helpers";
import ExportExcel from "../../../components/export/exportExcel";

const exportName = "Daily Flash Report";
const exportColumns = [
  { label: "User Name", value: "FullName" },
  { label: "Office", value: "Office" },
  { label: "Asset Entries Yesterday", value: "AssetOnD1" },
  { label: "Asset Entries This Week", value: "AssetOnWeekDay" },
  { label: "Comments Pending Addressed", value: "PendingAsset" },
];
function Index(props: any) {
  const [state, setState] = useState({
    searchDate: new Date().toDateString(),
  });
  const [updateTable, setUpdateTable] = useState(true);

  useEffect(() => {
    if (updateTable === true) {
      setUpdateTable(false);
      fetchData();
    }
  }, [updateTable]);

  const onChange = async (name: string, value: any) => {
    console.clear();
    console.log(`${name}:${value}`);
    let nextState = { ...state };
    nextState[name] = value;
    nextState["pageIndex"] = 0;
    setState(nextState);
    setUpdateTable(true);
  };

  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    // nextState.pageIndex = pageIndex;
    // nextState.pageSize = rowsPerPage;
    // setState(nextState);
    // setUpdateTable(true);
  };
  const handleExcelExport = async () => {
    await fetchData(true);
  };

  const fetchData = async (isExport = false) => {
    if (isEmpty(state.searchDate)) {
      return;
    }
    console.clear();
    console.log("fetch data");

    const params = {
      date: state.searchDate,
      isExport: isExport,
    };
    console.log(params);
    await props.GetReportFieldUsers(params);
  };

  const getExcelHeaders = () => {
    var headers = [];
    headers.push({ title: "", text: "" });

    headers.push({
      title: "Daily Flash Report (Filed User) - ",
      text: state.searchDate || "",
    });

    headers.push({ title: "", text: "" });
    return headers;
  };
  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Daily Flash Report (Field User)" />
      </div>
      <div className="list-page-body common-body">
        <div className="space-between-end">
          <SearchFilter {...state} {...props} onChange={onChange} />
          <ExportExcel
            onClick={handleExcelExport}
            fileName={exportName}
            columns={exportColumns}
            sheets={[
              {
                sheetName: exportName,
                data: props.fieldUsersForExcel,
                header: getExcelHeaders(),
              },
            ]}
            className="button button-subtle"
          />
        </div>

        <Table
          {...props}
          {...state}
          handlePagination={handlePagination}
          refreshContent={() => setUpdateTable(true)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fieldUsers: state.reportReducer.fieldUsers,
  fieldUserLoading: state.reportReducer.fieldUserLoading,
  fieldUsersForExcel: state.reportReducer.fieldUsersForExcel,
});

const mapDispatchToProps = {
  GetReportFieldUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
