import React, { Component } from "react";
import { createPortal } from "react-dom";
import { FaTimesCircle, FaExclamationCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PropTypes from "prop-types";
import { isEmpty } from "../../helpers";
import classnames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useLockBodyScreen from "../hooks/useLockBodyScroll";
import Button from "../button";

let modalEl = document.getElementsByClassName("modal");
interface ModalInter {
  open?: boolean;
  onModalClose: Function;
  type: string;
  title?: string;
  width?: string;
  isPrimary?: boolean;
  className?: string;
  noCloseButton?: boolean;
  func?: Function;
  // content?:React.ReactNode,
  // children?:React.ReactNode
  content?: any;
  children?: any;
  onStateClose?: Function;
  onSave?: Function;
  saveButtonText?: string;
  cancelButtonText?: string;
  hideFooter?: boolean;
}

class Mode extends Component<ModalInter> {
  public modalTarget: any;
  state = {
    open: false,
    noCloseButton: false,
  };
  componentDidMount = () => {
    this.setState({ open: this.props.open });
  };
  componentWillUnmount() {
    // ReactDOM.unmountComponentAtNode(this.modalTarget);
    // document.body.removeChild(this.modalTarget);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open });
    }
  }
  onClose = () => {
    this.setState({ open: false });
  };
  switchComponent() {
    const {
      func,
      onModalClose,
      type,
      title,
      content,
      noCloseButton,
    } = this.props;
    switch (type) {
      case "confirm":
        return (
          <Confirm
            onModalClose={onModalClose}
            func={func}
            open={this.props.open}
            title={title}
            content={content}
          />
        );
      case "success":
        return <Success onModalClose={onModalClose} open={this.props.open} />;
      case "danger":
        return <Danger onModalClose={onModalClose} open={this.props.open} />;
      case "dangerContent":
        return (
          <DangerWithContent
            onModalClose={onModalClose}
            title={title}
            content={content}
            noCloseButton={noCloseButton}
            open={this.props.open}
          />
        );
      default:
        return <DefaultModal onStateClose={this.onClose} {...this.props} />;
    }
  }

  render() {
    const modalClasses = classnames({
      modal: true,
      [this.props.className]: !isEmpty(this.props.className),
      "primary-modal": this.props.isPrimary,
    });
    return createPortal(
      <CSSTransition
        in={this.state.open}
        // key={item.module_name}
        timeout={300}
        classNames="modalOpen"
        unmountOnExit
      >
        <div className={modalClasses}>{this.switchComponent()}</div>
      </CSSTransition>,
      document.body
      // document.getElementById('#mode')
    );
  }
}

const DefaultModal = ({
  width,
  title,
  onModalClose,
  content,
  children,
  className,
  onStateClose,
  open,
  onSave,
  saveButtonText,
  cancelButtonText,
  hideFooter,
}: ModalInter) => {
  // const closeModal = (event) => {
  //   let parent = document.getElementsByClassName(className)[0];
  //   let el = parent.querySelector(".modal__content");
  //   document
  //     .getElementsByClassName("modal")[0]
  //     .classList.toggle("bg-transparent");
  //   el.classList.toggle("fade-out");

  // setTimeout(() => {
  //   onModalClose(event);
  //   el.classList.toggle("fade-out");
  // }, 250);
  // };
  const closeModal = (event) => {
    onStateClose();
    setTimeout(() => {
      onModalClose(event);
    }, 300);
    // onModalClose(event);
  };

  useLockBodyScreen(open, modalEl);
  return (
    <div className="modal__content" style={!isEmpty(width) ? { width } : {}}>
      {!isEmpty(title) ? (
        <div className="modal__header">
          <div className="modal__heading">
            <h2>{title}</h2>
          </div>
          <div
            className="modal__header-close"
            onClick={(event) => closeModal(event)}
          >
            {/* <MdClose /> */}
            <IoIosCloseCircleOutline />
          </div>
        </div>
      ) : (
        <div
          className="modal__header-close modal__header-close-noheader"
          onClick={(event) => closeModal(event)}
        >
          <IoIosCloseCircleOutline />
        </div>
      )}
      <div className="modal__content-body">
        {React.cloneElement(content ? content : children)}

        {!hideFooter && (
          <div className="modal__content-footer">
            <Button
              bg="cancel"
              onClick={(event) => closeModal(event)}
              title={cancelButtonText}
            />
            <Button
              bg="primary"
              // isDisabled={isSubmitting}
              title={saveButtonText}
              onClick={() => onSave && onSave()}
              buttonClass="ml-md"
            />
          </div>
        )}
        {/* {content ? content : children} */}
      </div>
    </div>
  );
};

const Confirm = ({ func, onModalClose, open, title, content }) => {
  const _title = title || "Are you Sure?";
  const _content = content || "You wont be able to revert your action";
  useLockBodyScreen(open, modalEl);
  return (
    <div className="modal__confrim confirm">
      <div className="confirm-icon">
        <FaExclamationCircle />
      </div>
      <h2 className="confirm-title">{_title}</h2>
      <p>{_content}</p>
      <div className="confirm-buttons">
        <button
          className="confirm-buttons__confirm"
          onClick={(e) => {
            func && func();
            onModalClose(e);
          }}
        >
          <span>Yes, I am Sure</span>
        </button>
        <button
          className="confirm-buttons__cancel"
          onClick={(e) => onModalClose(e)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
};

const Success = ({ onModalClose, open }) => {
  useLockBodyScreen(open, modalEl);
  return (
    <div className="modal__confrim confirm success">
      <div className="confirm-icon">
        <i className="material-icons">check_circle</i>
      </div>
      <h2 className="confirm-title">Success</h2>
      <p>Your Current Action was a success</p>
      <div className="confirm-buttons">
        <button
          className="btn btn--success"
          style={{ borderRadius: "12rem" }}
          onClick={(e) => onModalClose(e)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

const Danger = ({ onModalClose, open }) => {
  useLockBodyScreen(open, modalEl);
  return (
    <div className="modal__confrim confirm danger">
      <div className="confirm-icon">
        <FaTimesCircle />
      </div>
      <h2 className="confirm-title">Error</h2>
      <p>
        Something Bad Occured
        <span>Please Try again Later</span>
      </p>
      <div className="confirm-buttons">
        <button
          className="btn btn--danger"
          style={{ borderRadius: "12rem" }}
          onClick={(e) => onModalClose(e)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

const DangerWithContent = ({
  onModalClose,
  title,
  content,
  open,
  noCloseButton,
}) => {
  useLockBodyScreen(open, modalEl);
  return (
    <div className="modal__confrim confirm danger">
      <div className="confirm-icon">
        <FaTimesCircle />
      </div>
      <h2 className="confirm-title">{title}</h2>
      {content}
      {noCloseButton ? null : (
        <div className="confirm-buttons">
          <button
            className="btn btn-red"
            style={{ borderRadius: "12rem" }}
            onClick={(e) => onModalClose(e)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export class Modal extends Component<ModalInter> {
  public static defaultProps = {
    open: false,
    type: "normal",
    saveButtonText: "Save",
    cancelButtonText: "Cancel",
  };
  render() {
    const { open, ...props } = this.props;
    return <Mode {...this.props} />;
  }
}

export default Modal;
