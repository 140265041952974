import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import { Tab, Tabs } from "../../../components/tabs/index.d";
import Breadcrumb from "../../../components/breadcrumb";
import ExportExcel from "../../../components/export/exportExcel";
import { assetsStageEnum } from "../../../enums/assets";
import {
  GetAssets,
  DeleteAssets,
  ApproveAsset,
} from "../../../redux/actions/assetsAction";
import {
  GetDirectorate,
  GetDepartments,
  GetProvinces,
  GetOffices,
  GetAssetGroups,
  GetAssetSubgroups,
  GetAssetSubCodes,
  GetEntryUsers,
} from "../../../redux/actions/common/dropdownAction";
import { formatedMonthDayYearTime } from "../../../helpers/date/dateFormat"; // "../../helpers/date/dateFormat";

const tabContent = [
  {
    label: "All Assets",
    component: "allAssets",
    status: assetsStageEnum.allAssets,
  },
  {
    label: "Pending Approval",
    component: "pendingApproval",
    status: assetsStageEnum.pendingApproval,
  },
  {
    label: "L1 Approved",
    component: "l1Approved",
    status: assetsStageEnum.l1Approval,
  },
  {
    label: "L2 Approved",
    component: "l2Approved",
    status: assetsStageEnum.l2Approval,
  },
  {
    label: "L3 Approved",
    component: "l3Approved",
    status: assetsStageEnum.l3Approval,
  },
  {
    label: "Comments",
    component: "comments",
    status: assetsStageEnum.allAssets,
  },
];

const exportColumns = [
  { label: "ENTRY ID", value: "Id" },
  { label: "Location", value: "PhysicalLocation" },
  { label: "AssetCode", value: "AssetCode" },
  { label: "ASSET NAME", value: "AssetName" },
  { label: "Asset Group", value: "AssetGroupDesc" },
  { label: "Asset Sub-Group", value: "AssetSubGroupDesc" },
  { label: "Asset Sub-Code", value: "AssetSubCodeName" },
  { label: "Asset Condition", value: "AssetConditionName" },
  { label: "Capitalization Date(Nepali)", value: "CapitalizationDate" },
  { label: "UOM Desc", value: "UOMDesc" },
  { label: "Meas.", value: "Measurement" },
  { label: "UOM Code", value: "UOMRefCode" },
  { label: "Capacity", value: "Capacity" },
  { label: "Model", value: "Model" },
  { label: "DIRECTORATE", value: "DirectorateName" },
  { label: "DEPARTMENT", value: "DepartmentName" },
  { label: "PROVINCE", value: "ProvinceName" },
  { label: "OFFICE", value: "OfficeName" },
  { label: "ADDED ON", value: "CreatedOn" },
  { label: "ADDED BY", value: "CreatedByName" },
  { label: "STATUS", value: "StatusName" },
  { label: "STATUS UPDATED BY", value: "StatusUpdateByFullName" },
  { label: "STATUS MODIFIED ON", value: "StatusModifiedOn" },
  { label: "Made In", value: "MadeInCountryName" },
  
];

let exportName = "Asset List";

function Assets(props: any) {
  const selectedTabIndex =
    (props.location.state && props.location.state.selectedTabIndex) || 0;
  const [state, setState] = useState({
    search: "",
    assetCode: "",
    directorate: null,
    department: null,
    provience: null,
    office: null,
    assetGroup: null,
    assetSubgroup: null,
    assetSubCode: null,
    entryUser: null,
    activeTab: tabContent[selectedTabIndex],
    pageIndex: 0,
    pageSize: 10,
    waitingApproval: {},
    waitingApprovalOption: [{ label: 'ALL', value: "0" }, { label: 'Waiting Approval', value: "1" }]
  });
  const [updateTable, setUpdateTable] = useState(true);
  const [didComponentMount, setDidComponentMount] = useState(false);

  useEffect(() => { }, [props]);

  useEffect(() => {
    if (!didComponentMount) {
      setDidComponentMount(true);
      props.GetDirectorate();
      props.GetDepartments();
      props.GetProvinces();
      props.GetOffices();
      props.GetAssetGroups();
      props.GetAssetSubgroups();
      props.GetAssetSubCodes();
      props.GetEntryUsers();

    }

    if (updateTable === true) {
      setUpdateTable(false);
      getAssets();
    }
  }, [updateTable, didComponentMount]);

  const getAssets = async (isExport = false) => {

    const params = {
      status: state.activeTab.status,
      isCommentsOnly: state.activeTab.component === "comments" ? true : false,
      assetName: state.search,
      assetCode: state.assetCode,
      directorateName: state.directorate ? state.directorate.Text : "",
      departmentName: state.department ? state.department.Text : "",
      officeName: state.office ? state.office.Text : "",
      provinceName: state.provience ? state.provience.label : "",
      pageIndex: state.pageIndex,
      pageSize: isExport ? 111111111 : state.pageSize,
      assetGroupName: state.assetGroup ? state.assetGroup.Text : "",
      assetSubGroupName: state.assetSubgroup ? state.assetSubgroup.Text : "",
      assetSubCodeName: state.assetSubCode ? state.assetSubCode.Text : "",
      entryUserName: state.entryUser ? state.entryUser.Text : "",
      waitingApproval: (state.waitingApproval && (state.waitingApproval as any).value) === "1" ? true : false
      // entryDate: "2020-12-04T08:42:17.458Z",
      // entryFromDate: "2020-12-04T08:42:17.458Z",
      // entryToDate: "2020-12-04T08:42:17.458Z",
    };
    await props.GetAssets(params, isExport);
  };

  const handleExcelExport = async () => {
    await getAssets(true);
  };

  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    console.clear();
    console.log(name, value)
    nextState[name] = value;
    nextState["pageIndex"] = 0;

    nextState = fetchDropDownItems(name, value, nextState);
    setState(nextState);
    setUpdateTable(true);
  };

  const fetchDropDownItems = (name: string, value: any, nextState: any) => {
    if (name == "directorate") {
      nextState["department"] = null;
      props.GetDepartments((value && value.value) || null);
    } else if (name == "provience") {
      nextState["office"] = null;
      props.GetOffices((value && value.value) || null);
    } else if (name == "assetGroup") {
      nextState["assetSubgroup"] = null;
      nextState["assetSubCode"] = null;
      props.GetAssetSubgroups((value && value.value) || null);
      props.GetAssetSubCodes((value && value.value) || null);
    } else if (name == "assetSubgroup") {
      nextState["assetSubCode"] = null;
      props.GetAssetSubCodes(
        (nextState["assetGroup"] && nextState["assetGroup"].value) || null,
        (value && value.value) || null
      );
    }
    return nextState;
  };

  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    nextState.pageIndex = pageIndex;
    nextState.pageSize = rowsPerPage;
    setState(nextState);
    setUpdateTable(true);
  };

  const getHeaders = () => {
    var headers = [];
    headers.push({ title: "", text: "" });

    headers.push({
      title: "Directorate:",
      text: state.directorate ? state.directorate.Text : "",
    });

    headers.push({
      title: "Department:",
      text: state.department ? state.department.Text : "",
    });

    headers.push({
      title: "Province:",
      text: state.provience && state.provience.label,
    });

    headers.push({
      title: "Office:",
      text: state.office && state.office.Text,
    });
    headers.push({
      title: "Downloaded At:",
      text: formatedMonthDayYearTime(Date()),
    });
    headers.push({ title: "", text: "" });
    return headers;
  };
  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Asset List" />
      </div>
      <div className="list-page-body common-body">
        <div className="space-between-end">
          <SearchAndFilter {...state} {...props} onChange={onChange} />

          <ExportExcel
            onClick={handleExcelExport}
            fileName={exportName}
            columns={exportColumns}
            sheets={[
              {
                sheetName: exportName,
                data: props.assetsForExcelExport,
                header: getHeaders(),
              },
            ]}
            className="button button-subtle"
          />
        </div>
        <Tabs
          // type="block"
          noBackground
        >
          {tabContent.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.component}
                id={tab.component}
                isActive={state.activeTab.component === tab.component}
                onClick={() => onChange("activeTab", tab)}
              >
                <>
                  <Table
                    {...props}
                    {...state}
                    handlePagination={handlePagination}
                    refreshContent={() => setUpdateTable(true)}
                  />
                </>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  directorates: state.dropdownReducer.directorates,
  departments: state.dropdownReducer.departments,
  provinces: state.dropdownReducer.provinces,
  offices: state.dropdownReducer.offices,
  assetGroups: state.dropdownReducer.assetGroups,
  assetSubgroups: state.dropdownReducer.assetSubgroups,
  assetSubCodes: state.dropdownReducer.assetSubCodes,
  entryUsers: state.dropdownReducer.entryUsers,
  assetsForExcelExport: state.assetsReducer.assetsForExcelExport,
  assets: state.assetsReducer.assets,
  isAssetLoading: state.assetsReducer.isAssetLoading,
  selectedTabIndex: state.selectedTabIndex,
  loggedInUserDetail: state.usersReducer.loggedInUserDetail
});

const mapDispatchToProps = {
  GetDirectorate,
  GetDepartments,
  GetProvinces,
  GetOffices,
  GetAssets,
  GetAssetGroups,
  GetAssetSubgroups,
  GetAssetSubCodes,
  GetEntryUsers,
  DeleteAssets,
  ApproveAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
