import {
  GET_DASHBOARD_PROGRESS_SUMMARY,
  GET_DASHBOARD_OVERALL_SUMMARY,
} from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  progressSummary: null,
  overallSummary: null,
};

export const dashboardReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_PROGRESS_SUMMARY:
      return {
        ...state,
        progressSummary: action.payload,
      };

    case GET_DASHBOARD_OVERALL_SUMMARY:
      return {
        ...state,
        overallSummary: action.payload,
      };

    default:
      return state;
  }
};
