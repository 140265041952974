import { api, get } from "../../../networkService";
import {
    GET_APP_VERSION
} from "../../types";

export const GetAppVersion = () => async (dispatch: Function) => {
    const res = await get(api.public.appVersion, dispatch, null, false);
    console.clear();
    console.log(res)
    dispatch({
        type: GET_APP_VERSION,
        payload: res.InformationalVersion,
    });
}