import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import Button from "../../components/button";
import Icons from "../../components/icons";
import { Tab, Tabs } from "../../components/tabs/index.d";
import Breadcrumb from "../../components/breadcrumb";
import UserAndPermissionModal from "./forms/usersAndPermission";
import {
  GetUsers,
  ChangeUserStatus,
  DeleteUser,
} from "../../redux/actions/usersAction";

const tabContent = [
  {
    label: "Active Users",
    component: "activeUsers",
    status: true,
  },
  {
    label: "Disabled Users",
    component: "disablesUsers",
    status: false,
  },
];

function Users(props: any) {
  const [state, setState] = useState({
    search: "",
    pageIndex: 0,
    pageSize: 10,
    activeTab: tabContent[0],
  });
  const [updateTable, setUpdateTable] = useState(true);
  const [showAddUserModal, setAddUserModal] = useState(false);

  useEffect(() => {
    if (updateTable === true) {
      setUpdateTable(false);
      getUsers();
    }
  }, [updateTable]);

  const getUsers = () => {
    const params = {
      userName: state.search,
      status: state.activeTab.status,
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
    };
    props.GetUsers(params);
  };

  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    setUpdateTable(true);
  };

  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    nextState.pageIndex = pageIndex;
    nextState.pageSize = rowsPerPage;
    setState(nextState);
    setUpdateTable(true);
  };

  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Users" />
        <Button
          title="Add User"
          buttonType="icon-button"
          leftIcon={<Icons name="Add" />}
          bg="primary"
          onClick={() => setAddUserModal(true)}
        />
      </div>
      <div className="list-page-body common-body">
        <SearchAndFilter {...state} {...props} onChange={onChange} />

        <Tabs
          // type="block"
          noBackground
        >
          {tabContent.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.component}
                id={tab.component}
                isActive={state.activeTab.component === tab.component}
                onClick={() => onChange("activeTab", tab)}
              >
                <>
                  <Table
                    {...props}
                    {...state}
                    handlePagination={handlePagination}
                    refreshContent={() => setUpdateTable(true)}
                  />
                </>
              </Tab>
            );
          })}
        </Tabs>
      </div>

      {showAddUserModal && (
        <UserAndPermissionModal
          isAdd={true}
          showModal={showAddUserModal}
          setShowModal={(value) => setAddUserModal(value)}
          refreshContent={() => setUpdateTable(true)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: state.usersReducer.users,
});

const mapDispatchToProps = {
  GetUsers,
  ChangeUserStatus,
  DeleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
