import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../../helpers";
import { GetDirectorate } from "../../../redux/actions/common/dropdownAction";
import { GetProgressSummary } from "../../../redux/actions/dashboardAction";
import CounterBoard from "./counterBoard";
import Diagram from "./diagram";

let defaultDirectorate = { Text: "All", Value: "" };

function ProgressSummary(props: any) {
  const [directorates, setDirectorates] = useState([]);
  const [selectedDirectorates, setSelectedDirectorates] = useState<any>([]);

  useEffect(() => {
    props.GetDirectorate();
  }, []);

  useEffect(() => {
    if (props.directorates && props.directorates.length > 0) {
      let directoratesWithAll = [defaultDirectorate, ...props.directorates];

      let selectedDirectorate = [];
      selectedDirectorate.push(directoratesWithAll[0]);

      setDirectorates(directoratesWithAll);
      setSelectedDirectorates(selectedDirectorate);
      getProgressSummary(selectedDirectorate);
    }
  }, [props.directorates]);

  const onDirectorateChange = (directorate: any) => {
    let next = selectedDirectorates;

    if (directorate.Value == defaultDirectorate.Value) {
      next = next.filter((f) => f.Value == defaultDirectorate.Value);
    } else {
      next = next.filter((f) => f.Value != defaultDirectorate.Value);
    }

    let isSelected = false;
    if (next.find((f) => f.Value == directorate.Value)) {
      isSelected = true;
    }

    if (isSelected) {
      next = next.filter((f) => f.Value != directorate.Value);
    } else {
      next.push(directorate);
    }

    if (next.length <= 0) {
      next.push(defaultDirectorate);
    }

    setSelectedDirectorates(next);
    getProgressSummary(next);
  };

  const getProgressSummary = (selectedDirectorates) => {
    let directorateIds = "";
    selectedDirectorates &&
      selectedDirectorates.map((n, i) => {
        if (i == 0) {
          directorateIds = n.Value;
        } else {
          directorateIds = directorateIds + "," + n.Value;
        }
      });

    const param = {
      directorateIds: directorateIds,
    };
    props.GetProgressSummary(param);
  };

  return (
    <>
      <div className="heading">DIRECTORATE STATISTICS AND PROGRESS</div>
      <div className="dashboard-filter_bar">
        {directorates &&
          directorates.map((directorate: any, index: number) => {
            let isSelected = false;
            if (
              selectedDirectorates.find((f) => f.Value == directorate.Value)
            ) {
              isSelected = true;
            }

            let dynamicClass = "filter-item";
            if (isSelected) {
              dynamicClass = dynamicClass + " active";
            }

            return (
              <div
                className={dynamicClass}
                key={index}
                onClick={() => onDirectorateChange(directorate)}
              >
                {directorate.Text}
              </div>
            );
          })}
      </div>

      <CounterBoard {...props} />

      <Diagram {...props} />
    </>
  );
}

const mapStateToProps = (state) => ({
  directorates: state.dropdownReducer.directorates,
  progressSummary: state.dashboardReducer.progressSummary,
});

const mapDispatchToProps = { GetDirectorate, GetProgressSummary };

export default connect(mapStateToProps, mapDispatchToProps)(ProgressSummary);
