export const api = {
  auth: {
    siginIn: "auth/signin",
    refreshToken: "auth/refresh-token",
    signOut: "auth/signout",
  },
  public: {
    appVersion: "public/app-version",
  },
  common: {

    country: "common/countries",
    province: "common/provinces",
    directorates: "common/directorates",
    districts: "common/districts",
    assetGroups: "common/asset-groups",
    assetSubgroups: "common/asset-subgroups",
    // assetSubgroupsByGroupCode: "common/asset-subgroups",
    departments: "common/departments",
    offices: "common/offices",
    officeTypes: "common/office-types",
    uoms: "common/unit-measures",
    assetSubCodes: "common/asset-subcodes",
    locations: "common/locations",
    assetCondition: "common/asset-conditions",
  },
  dashboard: {
    directorateStatistics: "dashboard/directorate-statistics",
    summaries: "dashboard/summaries",
  },
  user: {
    userList: "security/users/list",
    users: "security/users",
    userDetails: "security/users/details",
    forgotPassword: "security/users/forgot-password",
    resetPassword: "security/users/reset-password",
    changeOwnPassword: "security/users/change-password",
    changeUserPassword: "security/users/change-user-password",
    status: "security/users/status",
  },
  role: {
    roles: "security/roles",
  },
  asset: {
    assetList: "asset/assets/list",
    assets: "asset/assets",
    approve: "asset/assets/approve",
    backForReview: "asset/assets/back-for-review",
    comments: "asset/assets/comments",
  },

  assetGroup: {
    groupList: "asset/groups/list",
    groups: "asset/groups",
  },
  assetSubGroup: {
    subGroups: "asset/sub-groups",
  },
  assetSubCode: {
    subCodes: "asset/sub-codes",
  },
  organization: {
    directorate: {
      directorates: "organization/directorates",
    },
    department: {
      departmentList: "organization/departments/list",
      departments: "organization/departments",
    },
    office: {
      officeList: "organization/offices/list",
      offices: "organization/offices",
    },
    officeType: {
      officeTypeList: "organization/office-types/list",
      officeTypes: "organization/office-types",
    },
  },
  report: {
    approval: "asset/assets/daily-flash-report/approval",
    fieldUsers: "asset/assets/daily-flash-report/field-user",
    pendingApproval: "asset/assets/daily-flash-report/pending-approval",
  },
};
