import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../../components/button";
import Icons from "../../../../components/icons";
import Form from "../../../../components/form";
import { newformatedMonthDayYearTime } from "../../../../helpers/date/dateFormat";
import Modal from "../../../../components/modal";

const Comments = forwardRef((props: any, ref: any) => {
  //function Comments(props) {
  const { Id, AssetComments, IsAllowComment } = props.assetById;

  AssetComments.sort(function (a: any, b: any) {
    let d1: any = new Date(a.CommentedOn);
    let d2: any = new Date(b.CommentedOn);
    return d2 - d1;
  });

  const { loggedInUserDetail } = useSelector((state: any) => {
    return state.usersReducer;
  });

  //   const [showComments, setShowComments] = useState(false);

  useImperativeHandle(ref, () => ({
    async submitComment() {
      if (comment && comment != "") {
        onComment();
        return true;
      }
      return false;
    },
  }));
  const [comment, setComment] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState("");
  const [commentId, setCommentId] = useState("");
  const [confirmType, setConfirmType] = useState("");

  const onChange = (name: any, value: any) => {
    setComment(value);
  };

  const onComment = () => {
    if (comment) {
      const param = {
        // "id": 0,
        assetRefId: Id,
        comments: comment,
      };

      props.CommentOnAsset(param, onCommentSucess);
    }
  };

  const onCommentSucess = () => {
    props.getAssetsById();
    setComment("");
  };
  const canDeleteComment = (comment) => {
    return loggedInUserDetail.UserId == comment.CommentedBy;
  };

  const onDeleteComment = (comment) => {
    setShowConfirm(true);
    setCommentId(comment.Id);
    setConfirmContent("Are you sure, you want to delete?");
  };

  return (
    <div className="mt-md">
      {/* {showComments ? ( */}
      <div>
        <div className="text-blue text-bold">Comments</div>
        <div className="mt-xsm">
          {AssetComments && AssetComments.length > 0 ? (
            AssetComments.map((comment: any, index: any) => {
              return (
                <div key={index} className="asset-detail-comments">
                  <Icons name="Comment" />

                  <div className="ml-sm flex-grow mr-sm">
                    <div className="flex">
                      <span className="bold">
                        <span>{comment.CommentedByPerson.FullName}</span>
                        <span className="ml-xsm">
                          {"[" + comment.CommentedByPerson.RoleName + "]"}
                        </span>
                      </span>

                      <span className="ml-md text-light">
                        {newformatedMonthDayYearTime(comment.CommentedOn)}
                      </span>
                    </div>
                    <div className="mt-xxsm">{comment.Comments}</div>
                  </div>
                  {canDeleteComment(comment) && IsAllowComment && (
                    <div className="flex-reverse">
                      <span
                        className={`ml-xsm pad-xxxsm  bg-blue cursor-pointer`}
                        onClick={() => {
                          onDeleteComment(comment);
                        }}
                      >
                        <Icons name="Delete" />
                      </span>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <span className="no-content">Nobody has commented till now.</span>
          )}
          {IsAllowComment && (
            <div className="flex mt-sm">
              <Form
                formName="textinput"
                name="comment"
                value={comment}
                onChange={onChange}
                onEnterPress={onComment}
                placeholder="Write your comment"
                width="500px"
              />
              <span
                className={`ml-xsm pad-xxxsm ${
                  comment ? "bg-blue cursor-pointer" : "bg-grey"
                }`}
                onClick={onComment}
              >
                <Icons name="Check" />
              </span>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={showConfirm}
        onModalClose={() => setShowConfirm(false)}
        hideFooter={true}
        type="confirm"
        content={confirmContent}
        func={() => {
          if (commentId != "") {
            props.DeleteComment(commentId, () => {
              props.getAssetsById();
              setCommentId("");
            });
          }
        }}
        title={"Confirm!"}
      ></Modal>
      {/* ) : (
        <Button
          title="Your Comment"
          buttonType="icon-button"
          leftIcon={<Icons name="Edit" />}
          bg="primary"
          onClick={() => setShowComments(true)}
        />
      )} */}
    </div>
  );
});

export default Comments;
