import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { GrAttachment } from "react-icons/gr";
import { Paper } from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { MdClose } from "react-icons/md";
import Icons from "../icons";
import GetFileTypeIcon from "../icons/getFileTypeIcon";
import {
  isEmpty,
  checkIsFileImageType,
  getSelectedUploadFile,
} from "../../helpers";

interface IAppProps {
  name: string;
  value: any[];
  type: string;
  label: string;
  isManual: boolean;
  validExtensions: any;
  onChange: Function;
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  const {
    value,
    onChange,
    name,
    type,
    label,
    isManual,
    validExtensions,
  } = props;

  const [notValidFile, setNotValidFile] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    onChange(name, acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });

  async function myCustomFileGetter(event) {
    const fileList = event?.dataTransfer
      ? event?.dataTransfer?.files
      : event?.target?.files;

    let { inValidFileExists, validFiles } = getSelectedUploadFile(
      validExtensions,
      fileList,
      value
    );
    setNotValidFile(inValidFileExists);
    return validFiles;
  }

  const { ref, ...rootProps } = getRootProps();
  const files =
    !isManual &&
    value &&
    value.map((file, i) => {
      let isImageFile = file?.name && checkIsFileImageType(file?.name);
      return (
        <li key={i} className="file-li flex text-md">
          <a
            href={
              isEmpty(file?.FileLocation)
                ? window.URL.createObjectURL(file)
                : file?.FileLocation
            }
            target={isImageFile ? "_blank" : ""}
            download={isImageFile ? false : true}
            className="flex link-color"
          >
            <GetFileTypeIcon fileName={file.name} />
            <span className="ml-xsm"> {file.name}</span>
          </a>
          <MdClose
            onClick={() => removeFile(file)}
            className="ml-sm cursor-pointer"
          />
        </li>
      );
    });
  const removeFile = (selected) => {
    let currentFiles = value.filter((x) => x.name !== selected.name);
    onChange(name, currentFiles);
  };
  return (
    <div className="attachment">
      {!isManual && (
        <div className="attachment__title flex">
          {label != "" && (
            <>
              <h3>{label || "Attachment"}</h3>
              <GrAttachment className="ml-sm" />
            </>
          )}
        </div>
      )}
      <div className="attachment__upload">
        <RootRef rootRef={ref}>
          <Paper {...rootProps}>
            <input {...getInputProps()} />
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Drag and drop</span>
              <span>or</span>
              <span className="text-blue">Browse to upload</span>
            </p>
          </Paper>
        </RootRef>
      </div>
      {!isManual && (
        <div>
          <ul>{files}</ul>
        </div>
      )}
      {notValidFile && (
        <div className="flex error-color text-md mt-xsm items-start">
          <Icons name="Info" />
          <span className="error-message">
            Invalid file type(valid file types are {validExtensions.join(", ")}
            )!
          </span>
        </div>
      )}
    </div>
  );
};

export default App;
