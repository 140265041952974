import React, { Component, useState, useEffect } from "react";
import { connect, useStore } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import { Tab, Tabs } from "../../../components/tabs/index.d";
import { assetsStageEnum } from "../../../enums/assets";

import Table from "./table";
import { GetReportPendingApproval } from "../../../redux/actions/report";
import { isEmpty } from "../../../helpers";
import ExportExcel from "../../../components/export/exportExcel";

const exportName = "Peniding Approval Report";

const exportColumns = [
  { label: "Office", value: "Office" },
  { label: "L1 Level", value: "L1PendingApproval" },
  { label: "L2 Level", value: "L2PendingApproval" },
  { label: "L3 Level", value: "L3PendingApproval" },
];

function Index(props: any) {
  const [state, setState] = useState({});
  const [updateTable, setUpdateTable] = useState(true);
  useEffect(() => {
    if (updateTable === true) {
      setUpdateTable(false);
      fetchData();
    }
  }, [updateTable]);

  const getExcelHeaders = () => {
    var headers = [];
    headers.push({ title: "", text: "" });
    headers.push({
      title: "Pending Approval",
    });
    headers.push({ title: "", text: "" });
    return headers;
  };
  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    nextState["pageIndex"] = 0;

    //  nextState = fetchDropDownItems(name, value, nextState);
    setState(nextState);
    setUpdateTable(true);
  };
  const handleExcelExport = async () => {
    await fetchData(true);
  };
  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    // nextState.pageIndex = pageIndex;
    // nextState.pageSize = rowsPerPage;
    // setState(nextState);
    // setUpdateTable(true);
  };

  const fetchData = async (isExport = false) => {
    const params = {
      isExport,
    };
    await props.GetReportPendingApproval(params);
  };

  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Daily Flash Report (Approval)" />
      </div>
      <div className="list-page-body common-body">
        <div className="space-between-end">
          <ExportExcel
            onClick={handleExcelExport}
            fileName={exportName}
            columns={exportColumns}
            sheets={[
              {
                sheetName: exportName,
                data: props.pendingApprovalsExcel,
                header: getExcelHeaders(),
              },
            ]}
            className="button button-subtle"
          />
        </div>
        <Table
          {...props}
          {...state}
          handlePagination={handlePagination}
          refreshContent={() => setUpdateTable(true)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pendingApprovals: state.reportReducer.pendingApprovals,
    pendingApprovalsExcel: state.reportReducer.pendingApprovalsExcel,
    pendingApprovalLoading: state.reportReducer.pendingApprovalLoading,
  };
};
const mapDispatchToProps = { GetReportPendingApproval };
export default connect(mapStateToProps, mapDispatchToProps)(Index);
