import React, { Component, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Icons from "../icons";
import OutsideClickHandler from "react-outside-click-handler";

export class CustomPopup extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      top: "",
      left: "",
    };
    this.target = null;
  }
  public target: any;
  public horizontalScroll: any = null;
  public verticalScroll: any = null;

  componentDidMount() {
    this.target = document.createElement("div");
    this.target.id = "customPopup";
    document.body.appendChild(this.target);
    this._render();
    const { parentRef } = this.props;

    const { x, y } = parentRef && parentRef.getBoundingClientRect();
    this.setState({ top: y, left: x });
    document.addEventListener("scroll", this.handleScroll.bind(this));
    this.horizontalScroll = document.getElementsByClassName(
      "ag-body-viewport"
    )[0];
    this.horizontalScroll.addEventListener(
      "scroll",
      this.handleScroll.bind(this)
    );
    this.verticalScroll = document.getElementsByClassName(
      "ag-center-cols-viewport"
    )[0];
    this.verticalScroll.addEventListener(
      "scroll",
      this.handleScroll.bind(this)
    );
    // var tableViewPort = document.getElementsByClassName(
    //   "ag-center-cols-clipper"
    // )[0];
    // console.log(tableViewPort);

    // tableViewPort.addEventListener("scroll", this.handleScroll.bind(this));

    // document.addEventListener("scroll",tableViewPort)

    // document
    //   .getElementsByClassName("ag-body-viewport")[0]
    //   .addEventListener("scroll", () => this.handleScroll.bind(this));
    // document
    //   .getElementsByClassName("ag-center-cols-viewport")[0]
    //   .addEventListener("scroll", () => this.handleScroll.bind(this));
  }
  componentDidUpdate() {
    this._render();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.target);
    document.body.removeChild(this.target);
    document.removeEventListener("scroll", this.handleScroll.bind(this));
    this.horizontalScroll.removeEventListener(
      "scroll",
      this.handleScroll.bind(this)
    );
    this.verticalScroll.removeEventListener(
      "scroll",
      this.handleScroll.bind(this)
    );
  }

  handleScroll = (event) => {
    const { parentRef } = this.props;
    const { x, y, width, height } =
      parentRef && parentRef.getBoundingClientRect();
    const { left, top } = this.state;
    if (x !== left || top !== y) {
      this.setState({ top: y, left: x });
    }
  };

  _render() {
    const {
      onClose,
      onDone,
      parentRef,
      children,
      hideFooter,
      excludeCustomPopupDesign,
    } = this.props;
    const { x, y, width, height } =
      parentRef && parentRef.getBoundingClientRect();
    const { top, left } = this.state;
    const newTtop = top + height + 2;
    const newLeft = left - 65;
    const style = {
      top: newTtop,
      left: newLeft,
    };

    const handleOutsideClick = () => {
      parentRef && onClose();
    };

    ReactDOM.render(
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className="custom_popup" style={style}>
          <div className="custom_popup-body" onClick={onClose}>
            {children}
          </div>
          {!hideFooter && (
            <div className="custom_popup-footer">
              <div onClick={onClose} className="popup-btn popup-remove">
                <Icons name="Close" />
              </div>
              <div onClick={onDone} className="popup-btn popup-add">
                <Icons name="Check" />
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>,
      this.target
    );
  }

  render() {
    return <noscript />;
  }
}

// CustomPopup.propTypes = {
//   onClose: PropTypes.func,
//   onDone: PropTypes.func,
//   parentRef: PropTypes.any.isRequired,
//   children: PropTypes.any.isRequired,
//   hideFooter: PropTypes.bool,
// };

// CustomPopup.defaultProps = {
//   hideFooter: false,
// };

export default CustomPopup;
