import React from "react";
import { formatedMonthDayYearTime } from "../../../helpers/date/dateFormat";

export default function LastModifiedCell(props: any) {
  const { LastModifiedOn, LastModifiedBy } = props.data;
  return (
    <>
      <span>{formatedMonthDayYearTime(LastModifiedOn)}</span>
      <span className="ml-sm bold-500">{LastModifiedBy}</span>
    </>
  );
}
