import React from "react";
import PropTypes from "prop-types";
import { otherInputInterface } from "../../ts/interfaces/formInterface";

const TextArea = (props: otherInputInterface) => {
  const { name, type, value, autoFocus, disabled, placeholder } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };

  return (
    <textarea
      className="genericForm-group__textarea"
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      autoFocus={autoFocus}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

TextArea.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
};

export default TextArea;
