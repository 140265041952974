import React, { Component } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Sector, Cell, Legend } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class CustomPieChart extends Component {
  renderColorfulLegendText = (value, entry) => {
    const { showPercentageOnly, showValueOnly } = this.props;
    const {
      color,
      payload: { percent },
    } = entry;
    const totalValue = entry.payload.payload.value;

    return (
      <span className="legend-label">
        <span style={{ backgroundColor: color }} className="legend-bg"></span>
        <span className="legend-label-name">{value}</span>
        {showPercentageOnly ? (
          <span className="legend-label-value">{`${(percent * 100).toFixed(
            0
          )}%`}</span>
        ) : showValueOnly ? (
          <span className="legend-label-value">{totalValue}</span>
        ) : (
          <>
            {percent && (
              <span className="legend-label-value">{`${(percent * 100).toFixed(
                0
              )}%`}</span>
            )}
            {totalValue && (
              <span className="legend-label-value">{totalValue}</span>
            )}
          </>
        )}
      </span>
    );
  };

  render() {
    const {
      title,
      data,
      innerRadius,
      outerRadius,
      x,
      y,
      width,
      height,
    } = this.props;
    return (
      <div className="chart">
        {title && <div className="chart-title">{title}</div>}
        <div className="chart-body">
          <div className="chart-body-pie">
            <PieChart width={width} height={height}>
              <Pie
                data={data}
                cx={x}
                cy={y}
                labelLine={false}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                // label={renderCustomizedLabel}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                formatter={this.renderColorfulLegendText}
                // wrapperStyle
                // width={200}
                // cx={1200}
              />
            </PieChart>
            <div className="chart-labels"></div>
          </div>
        </div>
      </div>
    );
  }
}

CustomPieChart.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

CustomPieChart.defaultProps = {
  data: [],
  title: null,
  x: 180,
  y: 120,
  innerRadius: 2,
  outerRadius: 120,
  width: 550,
  height: 250,
};

export default CustomPieChart;
