import React, { useState } from "react";
import AgGridTable from "../../../../components/tables/agGridTable";

const columnDefs = [
  {
    headerName: "Office",
    tooltipField: "Office",
    field: "Office",
    fontSize: 13,
    width: 400,
    
  },
  {
    headerName: "L1 Level",
    tooltipField: "L1PendingApproval",
    field: "L1PendingApproval",
    fontSize: 13,
    width: 120,
  },
  {
    headerName: "L2 Level",
    tooltipField: "L2PendingApproval",
    field: "L2PendingApproval",
    fontSize: 13,
    width: 120,
  },
  {
    headerName: "L3 Level",
    tooltipField: "L3PendingApproval",
    field: "L3PendingApproval",
    fontSize: 13,
    width: 120,
  },
];

function Table(props: any) {
  return (
    <div className="assets-table">
      <AgGridTable
        columnDefs={columnDefs}
        rowData={props.pendingApprovals}
        isLoading={props.pendingApprovalLoading}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
    </div>
  );
}

export default Table;
