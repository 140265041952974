import React from "react";

function TabItemContent(props: any) {
  //Mapping all the children inside the Tab component
  return props.children.map((child) => <TabChild child={child} {...props} />);
}

const TabChild = (props: any) => {
  //Returning the child element if the tab is active
  const childElement = React.Children.only(props.child.props.children);

  if (props.child.props.name !== props.active) return null;
  return childElement;
};

export default TabItemContent;
