import {
  GET_ASSETS,
  GET_ASSETS_EXPORT_EXCEL,
  GET_ASSETS_BY_ID,
  ON_COMMENT_ASSET,
  GET_ASSET_GROUPS,
  GET_ASSETS_LOADING
} from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  isAssetLoading:false,
  assets: null,
  assetsForExcelExport: null,
  assetById: null,
  assetGroupList: null,
};

export const assetsReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETS_LOADING:
      return{
        ...state,
        isAssetLoading:action.payload
      }
    case GET_ASSETS:
      return {
        ...state,
        assets: action.payload,
        isAssetLoading:false
      };

    case GET_ASSETS_EXPORT_EXCEL:
      return {
        ...state,
        assetsForExcelExport: action.payload,
        isAssetLoading:false
      };

    case GET_ASSETS_BY_ID:
      return {
        ...state,
        assetById: action.payload,
      };

    case ON_COMMENT_ASSET: {
      const currentAssetById = { ...state.assetById };
      currentAssetById.AssetComments.push(action.payload);
      return {
        ...state,
        assetById: currentAssetById,
      };
    }

    case GET_ASSET_GROUPS:
      return {
        ...state,
        assetGroupList: action.payload,
      };

    default:
      return state;
  }
};
