import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { fileUrl } from "../../constants/urls"
import { MdKeyboardArrowDown } from "react-icons/md";
import Icons from "../../components/icons";
import ChangePasswordModal from "../../scenes/auth/changePassword";
import { SignOut } from "../../redux/actions/authAction";
import { ChangeOwnPassword } from "../../redux/actions/usersAction";
import { GetAppVersion } from "../../redux/actions/public"

function DropContents(props) {

  const { UserId, Email, PhoneNumber, UserName, FullName, RoleId, RoleName, UserImage, UserImageThumbnail } = props.loggedInUserDetail

  const [showChangePasswordModal, setChangePasswordModal] = useState(false);

  useEffect(() => {
    props.GetAppVersion && props.GetAppVersion();
  }, []);

  const onSignOut = () => {
    props.SignOut();
  };

  return (
    <>
      <div className="profile-image">
        <Avatar
          name={UserName}
          size={40}
          // round={true}
          src={`${fileUrl}${UserImageThumbnail}`}
        />
      </div>
      <div className="profile-name">
        <span>{UserName}</span>
      </div>
      <div className="profile-down-caret">
        <MdKeyboardArrowDown />
      </div>
      <div className="profile-drop__content">
        <div className="profile-drop-item">
          <div className="flex" onClick={() => setChangePasswordModal(true)}>
            <Icons name="LockPassword" />
            <span className="ml-xsm">Change Password</span>
          </div>
        </div>
        <div className="profile-drop-item">
          <div className="flex logout" onClick={onSignOut}>
            <Icons name="LogoutCircle" />
            <span className="ml-xsm">Logout</span>
          </div>
        </div>
        {props.appVersion &&
          <div className="profile-drop-item-version">
            <span className="ml-xsm">Version: {props.appVersion}</span>
          </div>
        }
      </div>

      {showChangePasswordModal && (
        <ChangePasswordModal
          showModal={showChangePasswordModal}
          setShowModal={(value) => setChangePasswordModal(value)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loggedInUserDetail: state.usersReducer.loggedInUserDetail,
  appVersion: state.appVersionReducer.appVersion
});

const mapDispatchToProps = {
  ChangeOwnPassword,
  SignOut,
  GetAppVersion
};

export default connect(mapStateToProps, mapDispatchToProps)(DropContents);
