import React from "react";
import { formatedMonthDayYearTime } from "../../../../helpers/date/dateFormat";

export default function AddedOnCell(props: any) {

  const { CreatedOn, CreatedByName } = props.data;
  return (
    <span className="table-addedOn-section">
    <span className="table-addedOn-section-data">{CreatedOn}</span>
    <span className="table-addedOn-section-sub_data bold-500">
      {CreatedByName}
    </span>
  </span>
    // <>
    //   <span>{CreatedOn}</span>
    //   <span className="ml-sm bold-500">{CreatedByName}</span>
    // </>
  );
}
