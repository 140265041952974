import {
  GET_REPORT_FIELD_USERS_EXCEL,
  GET_REPORT_FIELD_USERS,
  GET_REPORT_FIELD_USERS_LOADING,
  GET_REPORT_APPROVAL_EXCEL,
  GET_REPORT_APPROVAL,
  GET_REPORT_APPROVAL_LOADING,
  GET_REPORT_PENDING_APPROVAL,
  GET_REPORT_PENDING_APPROVAL_EXCEL,
  GET_REPORT_PENDING_APPROVAL_LOADING,
} from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  approvalListForExcel: [],
  approvalList: [],
  approvalListLoading: false,
  fieldUsersForExcel: [],
  fieldUsers: [],
  fieldUserLoading: false,
  pendingApprovalsExcel: [],
  pendingApprovals: [],
  pendingApprovalLoading: false,
};

export const reportReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_APPROVAL_LOADING:
      return {
        ...state,
        approvalListLoading: action.payload,
      };
    case GET_REPORT_APPROVAL:
      return {
        ...state,
        approvalList: action.payload,
        approvalListLoading: false,
      };
    case GET_REPORT_APPROVAL_EXCEL:
      return {
        ...state,
        approvalListForExcel: action.payload,
        approvalListLoading: false,
      };

    case GET_REPORT_FIELD_USERS_LOADING:
      return {
        ...state,
        fieldUserLoading: action.payload,
      };
    case GET_REPORT_FIELD_USERS:
      return {
        ...state,
        fieldUsers: action.payload,
        fieldUserLoading: false,
      };
    case GET_REPORT_FIELD_USERS_EXCEL:
      return {
        ...state,
        fieldUsersForExcel: action.payload,
        fieldUserLoading: false,
      };

    //---
    case GET_REPORT_PENDING_APPROVAL_LOADING:
      return {
        ...state,
        pendingApprovalLoading: action.payload,
      };
    case GET_REPORT_PENDING_APPROVAL:
      return {
        ...state,
        pendingApprovals: action.payload,
        pendingApprovalLoading: false,
      };
    case GET_REPORT_PENDING_APPROVAL_EXCEL:
      return {
        ...state,
        pendingApprovalsExcel: action.payload,
        pendingApprovalLoading: false,
      };

    default:
      return state;
  }
};
