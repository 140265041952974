import {
  GET_USERS,
  GET_LOGGEDIN_USER_DETAIL,
  GET_USER_BY_USERNAME,
} from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  users: null,
  loggedInUserDetail: {},
  userByUsername: null,
};

export const usersReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case GET_LOGGEDIN_USER_DETAIL:
      return {
        ...state,
        loggedInUserDetail: action.payload,
      };

    case GET_USER_BY_USERNAME: {
      return {
        ...state,
        userByUsername: action.payload,
      };
    }

    default:
      return state;
  }
};
