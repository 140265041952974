import * as React from "react";
import Workbook from "../excelWorkbook";
// import Workbook from "react-excel-workbook-emptystring";
import { isEmpty } from "../../helpers";
import Button from "../button";
import Icons from "../icons";
import {
  IExcelSheet,
  IExcelColumn,
} from "../../ts/interfaces/excel/exportExcel";

interface IAppProps {
  fileName: string;
  displayText: string;
  onClick?: Function;
  sheets: IExcelSheet[];
  columns?: IExcelColumn[];
  className: string;
}

interface State {
  isDisable?: boolean;
}

export default class App extends React.Component<IAppProps, State> {
  public static defaultProps = {
    displayText: "Export",
  };
  private exportBtn: React.RefObject<HTMLButtonElement>;
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isDisable: false,
    };
    this.exportBtn = React.createRef();
  }

  setButton = (value) => {
    this.setState({ isDisable: value });
  };

  // const sleep = (m) => new Promise((r) => setTimeout(r, m));

  async handleClick() {
    this.setButton(true);
    this.props.onClick && (await this.props.onClick());
    if (!isEmpty(this.props.sheets[0].data))
      this.exportBtn.current && this.exportBtn.current.click();
    this.setButton(false);
  }

  public render() {
    const { isDisable } = this.state;
    const { displayText, fileName, columns, sheets, className } = this.props;

    return (
      <>
        {/* <button
          className={className}
          onClick={this.handleClick.bind(this)}
          disabled={isDisable}
        >
          {isDisable ? "Waiting..." : displayText}
        </button> */}
        <Button
          title={isDisable ? "Waiting..." : displayText}
          buttonType="icon-button"
          leftIcon={<Icons name="Download" />}
          isDisabled={isDisable}
          bg="primary"
          onClick={this.handleClick.bind(this)}
        />
        <Workbook
          filename={fileName + ".xlsx"}
          element={
            <button
              ref={this.exportBtn}
              style={{ opacity: 0, visibility: "hidden" }}
            />
          }
        >
          {sheets && sheets.length > 0
            ? sheets.map((sheet, index) => {
                const sheetName = sheet.sheetName
                  ? sheet.sheetName
                  : `Sheet ${index + 1}`;
                return (
                  <Workbook.Sheet
                    data={sheet.data ? sheet.data : []}
                    header={sheet.header ? sheet.header : []}
                    name={sheetName}
                  >
                    {columns &&
                      columns.map((column, i) => {
                        return (
                          <Workbook.Column
                            label={column.label ? column.label : ""}
                            value={column.value ? column.value : ""}
                          />
                        );
                      })}
                  </Workbook.Sheet>
                );
              })
            : sheets &&
              sheets.length == 0 && (
                <Workbook.Sheet data={[]} name={"Sheet 1"} headerData={[]}>
                  {columns &&
                    columns.map((column, i) => {
                      return (
                        <Workbook.Column
                          label={column.label ? column.label : ""}
                          value={column.value ? column.value : ""}
                        />
                      );
                    })}
                </Workbook.Sheet>
              )}
        </Workbook>
      </>
    );
  }
}
