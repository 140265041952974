import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import { SaveDirectorate } from "../../../../redux/actions/organizationAction";
import { InputValidator, isEmpty } from "../../../../helpers";

function Directorate(props: any) {
  const { dataToEdit } = props;

  const [state, setState] = useState<any>({
    directorateId: 0,
    directorateName: "",
  });

  useEffect(() => {
    if (dataToEdit) {
      setState({
        directorateId: dataToEdit.DirectorateId,
        directorateName: dataToEdit.DirectorateName,
      });
    }
  }, [dataToEdit]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;

    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }
    const param = {
      id: state.directorateId,
      name: state.directorateName,
    };
    props.SaveDirectorate(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshContent && props.refreshContent();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Directorate"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="directorateName"
            value={state.directorateName}
            onChange={onChange}
            label="Directorate Name"
            validators={["required"]}
            error={state.errors && state.errors.directorateName}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  SaveDirectorate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Directorate);
