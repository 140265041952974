import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import useOutsideClick from "../../components/hooks/useOutsideClick";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import routes from "../routes";
import { FaHome,FaFile, FaIdCard } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { VscProject, VscFileSymlinkDirectory } from "react-icons/vsc";
import { TiGroupOutline } from "react-icons/ti";
import { BsList } from "react-icons/bs";
import DropContents from "./dropContents";
import useEventListener from "../../components/hooks/useEventListener";
import { role } from "../../enums/role";

const navigationContent = [
  {
    navId: 1,
    title: "Home",
    link: routes.dashboard,
    env: true,
    icon: <FaHome />,
  },
  {
    navId: 5,
    title: "Asset List",
    link: routes.assetListing,
    env: true,
    icon: <VscProject />,
  },
  {
    navId: 2,
    title: "Reports",
    isDrop: true,
    env: true,
    icon: <GoGraph />,
    links: [
      {
        title: "Daily Flash Report (Field Users)",
        link: routes.fieldReport,
        icon: <FaFile />,
      },
      {
        title: "Daily Flash Report (Approval)",
        link: routes.approvalReport,
        icon: <FaFile />,
      },
      {
        title: "Pending  Asset Approval Report",
        link: routes.pendingApprovalReprot,
        icon: <FaFile />,
      },
    ],
  },
  {
    navId: 3,
    title: "Users",
    link: routes.users,
    env: true,
    icon: <FaIdCard />,
    role: role.SystemAdministrator,
  },
  {
    navId: 4,
    title: "Settings",
    isDrop: true,
    env: true,
    icon: <VscProject />,
    role: role.SystemAdministrator,
    links: [
      {
        title: "Directorate, Department & Offices",
        link: routes.directorateDepartmentOffices,
        icon: <VscFileSymlinkDirectory />,
      },
      {
        title: "Group, Subgroup & Category",
        link: routes.groupSubgroupCategory,
        icon: <TiGroupOutline />,
      },
    ],
  },
  // {
  //   navId: 5,
  //   title: "Settings",
  //   isDrop: true,
  //   env: true,
  //   icon: <AiFillSetting />,
  //   links: [
  //     {
  //       title: "Setting 1",
  //       link: "",
  //       icon: <FaHome />,
  //     },
  //   ],
  // },
];

function NavigatorBar(props) {
  const { RoleName, RoleId } = props.loggedInUserDetail;
  let headerRef = useRef(null);
  const [drop, setDrop] = useState(false);

  useEventListener("click", (e) => {
    if (
      headerRef &&
      headerRef.current &&
      !headerRef.current.contains(e.target)
    ) {
      setDrop(false);
    }
  });

  return (
    <div className="header">
      <div className="header-title">
        <h4>{"NEA Asset Verification"}</h4>
      </div>
      <div className="header-navigation">
        <nav className="navbar">
          <ul className="navbar-list">
            {navigationContent.map((content, index) => {
              if (content.role && content.role != RoleId) {
                return null;
              }
              if (content.env) {
                return <Tab content={content} index={index} key={index} />;
              }
              return null;
            })}
          </ul>
        </nav>
      </div>

      <div
        ref={headerRef}
        onClick={() => setDrop(!drop)}
        className="header-profile profile"
      >
        <div
          className={`profile profile-drop ${
            drop ? " profile-drop__active" : ""
          }`}
        >
          <DropContents />
        </div>
      </div>
    </div>
  );
}

const Tab = ({ key, content }) => {
  if (content.isDrop) {
    return <DropLink content={content} />;
  }
  return (
    <li className="navbar-list__item">
      <NavLink
        exact
        className={"navbar-list__links"}
        to={{ pathname: content.link, state: { navId: content.navId } }}
        key={key}
        activeClass="active"
      >
        <>
          <span className="navbar-list__links-icon">{content.icon}</span>
          <span>{content.title}</span>
        </>
      </NavLink>
    </li>
  );
};

const DropLink = ({ content }) => {
  const dropRef = useRef(null);
  const [drop, setDrop] = useState(false);

  const onOpen = () => {
    setDrop(true);
  };

  const onClose = () => {
    setDrop(false);
  };

  useOutsideClick(dropRef, onClose);

  const dropClasses = classnames({
    "navbar-list__item": true,
    "drop-active": drop,
  });

  return (
    <li ref={dropRef} onClick={() => onOpen()} className={dropClasses}>
      <a className="navbar-list__links">
        <span className="navbar-list__links-icon">{content.icon}</span>
        <span>{content.title}</span>
      </a>
      <CSSTransition
        in={drop}
        timeout={300}
        classNames="dropdown"
        appear
        unmountOnExit
      >
        <div className="navbar-dropdown">
          <ul className="navbar-dropdown__list">
            {content.links.map((item) => {
              return (
                <li key={item.title} className="navbar-dropdown__item">
                  <NavLink to={item.link} className="navbar-dropdown__link">
                    <div className="navbar-dropdown__icon">{item.icon}</div>
                    <div className="navbar-dropdown__title">{item.title}</div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
    </li>
  );
};

const mapStateToProps = (state) => ({
  loggedInUserDetail: state.usersReducer.loggedInUserDetail,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorBar);
//export default NavigatorBar;
