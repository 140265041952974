import React, { useState } from "react";
import Loaders from "../loaders";
import { AgGridReact } from "ag-grid-react";
import Pagination from "./pagination";

const defaultColDef = {
  // width: 145,
  resizable: true,
  sortable: true,
  // flex: 1,
  // editable: true,
  // enableRowGroup: true,
  // enablePivot: true,
  // enableValue: true,
  // filter: true,
  // minWidth: 100,
};

function AgGrid(props: any) {
  let { rowData, pagination,isLoading, page, height, minHeight, rowHeight } = props;
  let totalData = 0;

  const [state, setState] = useState({
    pageIndex: page ? page.pageIndex : 0,
    rowsPerPage: page ? page.rowsPerPage : 0,
  });

  const getRowHeight = (params) => {
    return rowHeight || 44;
    // return rowHeight || 45;
  };

  const onGridReady = (params) => {
    let gridApi = params.api;
    let gridColumnApi = params.columnApi;

    // gridApi.setDomLayout('autoHeight');
    // const grid:any = document.querySelector('#myGrid');
    // if (grid) grid.style.height = '';
  };

  const handleRowChange = (pageIndex, rowsPerPage) => {
    setState({ ...state, pageIndex: pageIndex, rowsPerPage: rowsPerPage });
    props.handlePagination && props.handlePagination(pageIndex, rowsPerPage);
  };

  const handleIndexChange = (pageIndex) => {
    const { page } = props;
    if (page) {
      setState({ ...state, pageIndex: pageIndex });
      props.handlePagination &&
        props.handlePagination(pageIndex, page.rowsPerPage);
    }
  };

  // const updateState = (pageIndex: any = null, rowsPerPage: any = null) => {
  //   props.handlePagination && props.handlePagination(pageIndex, rowsPerPage);
  // };

  if (rowData && rowData.length > 0) {
    totalData = rowData[0].TotalRows || 0;
  }

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{
          height: height ? height : "auto",
          width: "100%",
        }}
      >
        {isLoading ? (
          <Loaders
            loading={true}
            type="skeleton"
            // backgroundColor="#bbb"
            // foregroundColor="#dedede"
            name="custom-table"
            //cardType="block"
          />
        ) : (
          <AgGridReact
            rowSelection={"single"}
            domLayout="autoHeight"
            columnDefs={props.columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={getRowHeight}
            // onGridReady={onGridReady}
            frameworkComponents={props.frameworkComponents}
            rowData={rowData && rowData}
            // pagination={!isEmpty(pagination) ? pagination : true}
            animateRows={true}
          />
        )}
      </div>
      {totalData > 0 && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={state.pageIndex} //{props.pageIndex}
          rowsPerPage={state.rowsPerPage} //{props.rowsPerPage}
          total={totalData}
        />
      )}
    </div>
  );
}

export default AgGrid;
