import React from "react";
import { isEmpty } from "../../helpers";

function TabItemHeader(props: any) {
  const { children, expand, single, active, setExpand } = props;
  // checking if ther is single or multiple Tab component inside Tabs component
  if (single) {
    // Returning Same Tab component with extra props like isActive, onClick, name of activeTab
    return React.cloneElement(children, {
      ...children.props,
      isActive: true,
      onClick: props.onClick,
      setExpand,
      activeTab: props.active,
    });
  }
  return children.map((child) => {
    if (!isEmpty(child)) {
      const childProps = {
        isActive: active === child.props.name,
        onClick: props.onClick,
        activeTab: props.active,
        setExpand,
        ...child.props,
      };
      return React.cloneElement(child, childProps);
    }
  });
}

export default TabItemHeader;
