import React from "react";
import Skeleton from "./skeleton";
import Spinner from "./spinner";

interface Loaders {
  type?: "skeleton" | "spinner" | string;
  cardType?:
    | "leave"
    | "employee"
    | "block"
    | "travel"
    | "dashboard"
    | "dashboard2";
  name?: "custom-table" | "table" | "card";
  backgroundColor?: string;
  foregroundColor?: string;
  loading: boolean;
  width?: Number;
  height?: Number;
  noRows?: Number;
}

function Loaders(props: Loaders) {
  const loaderComponent = () => {
    const { type } = props;
    switch (type) {
      case "skeleton":
        return Skeleton;
      case "spinner":
        return Spinner;
      default:
        // return null
        return Spinner;
    }
  };
  const Component = loaderComponent();
  return <Component {...props} />;
}

Loaders.defaultProps = {
  // type: "normal",
  loading: false,
};

export default Loaders;
