import { api, get } from "../../networkService";
import { GET_ROLES } from "../types";

export const GetRoles = () => async (dispatch: Function) => {
  const res = await get(api.role.roles, dispatch, null, false);

  let list = [];
  res.Data.forEach((d: any) => {
    list.push({ ...d, label: d.Name, value: d.RoleId });
  });
  dispatch({ type: GET_ROLES, payload: list });
};
