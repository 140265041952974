import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchAndFilter from "./searchAndFilter";
import Accordion from "./accordion";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import Breadcrumb from "../../../components/breadcrumb";
import AssetGroupModal from "./forms/assetGroup";
import {
  GetAssetGroupList,
  DeleteAssetGroup,
  DeleteAssetSubGroup,
  DeleteAssetSubCode,
} from "../../../redux/actions/assetsAction";
import {
  GetAssetGroups,
  GetAssetSubgroups,
} from "../../../redux/actions/common/dropdownAction";

function GroupSubgroupCategory(props: any) {
  const [state, setState] = useState({
    searchCategory: "",
    group: null,
    subGroup: null,
    pageIndex: 0,
    pageSize: 10,
  });
  const [showAddAssetGroupModal, setAddAssetGroupModal] = useState(false);
  const [updateTable, setUpdateTable] = useState(true);
  const [didComponentMount, setDidComponentMount] = useState(false);

  useEffect(() => {
    if (!didComponentMount) {
      setDidComponentMount(true);
      props.GetAssetGroups();
      props.GetAssetSubgroups();
    }

    if (updateTable === true) {
      setUpdateTable(false);
      getAssetGroupList();
    }
  }, [updateTable, didComponentMount]);

  const getAssetGroupList = () => {
    const params = {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      groupCode: state.group ? state.group.Value : "",
      subGroupCode: state.subGroup ? state.subGroup.Value : "",
      subCodeName: state.searchCategory,
    };
    props.GetAssetGroupList(params);
  };

  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    nextState['pageIndex']=0;
    setState(nextState);
    setUpdateTable(true);
  };

  const handlePagination = (pageIndex: any, pageSize: any) => {
    let nextState = { ...state };
    nextState.pageIndex = pageIndex;
    nextState.pageSize = pageSize;
    setState(nextState);
    setUpdateTable(true);
  };

  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Asset Group and Category" />
        <Button
          title={"Add Asset Group"}
          buttonType="icon-button"
          leftIcon={<Icons name="Add" />}
          buttonClass="flex-end"
          bg="primary"
          onClick={() => setAddAssetGroupModal(true)}
        />
      </div>
      <div className="list-page-body common-body">
        <SearchAndFilter {...state} {...props} onChange={onChange} />

        <Accordion
          {...props}
          {...state}
          refreshGroup={() => setUpdateTable(true)}
          handlePagination={handlePagination}
        />
      </div>
      <AssetGroupModal
        showModal={showAddAssetGroupModal}
        setShowModal={(value) => setAddAssetGroupModal(value)}
        refreshGroup={() => setUpdateTable(true)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  assetGroups: state.dropdownReducer.assetGroups,
  assetSubgroups: state.dropdownReducer.assetSubgroups,
  assetSubCodes: state.dropdownReducer.assetSubCodes,
  assetGroupList: state.assetsReducer.assetGroupList,
});

const mapDispatchToProps = {
  GetAssetGroups,
  GetAssetSubgroups,
  GetAssetGroupList,
  DeleteAssetGroup,
  DeleteAssetSubGroup,
  DeleteAssetSubCode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSubgroupCategory);
