import React, { useState } from "react";
import AgGridTable from "../../../../components/tables/agGridTable";
import Button from "../../../../components/button";
import Icons from "../../../../components/icons";

import OfficeModal from "../forms/office";

const columnDefs = [
  { headerName: "Office", field: "OfficeName", width: 250 },
  { headerName: "Office Type", field: "OfficeTypeName", width: 250 },
  { headerName: "Province", field: "ProvinceName", width: 250 },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "actionComp",
    // cellStyle: { color: "#1786ff" },
    width: 180,
  },
];

function OfficesTable(props: any) {
  const { officeList } = props;

  const [showOfficeModal, setOfficeModal] = useState(false);
  const [selectedOfficeData, setSelectedOfficeData] = useState(null);

  const actionComp = (rowProps) => {
    const onEdit = () => {
      setSelectedOfficeData(rowProps.data);
      setOfficeModal(true);
    };
    const onDelete = () => {
      props.DeleteOffice(rowProps.data.Id, props.refreshDirectorate);
    };
    return (
      <span className="flex">
        <Button
          title="Edit"
          buttonType="icon-button"
          leftIcon={<Icons name="Edit" />}
          bg="primary"
          onClick={onEdit}
        />
        <Button
          title="Delete"
          buttonType="icon-button"
          leftIcon={<Icons name="Delete" />}
          bg="danger"
          buttonClass="ml-sm"
          onClick={onDelete}
        />
      </span>
    );
  };

  const frameworkComponents = {
    actionComp,
  };

  return (
    <>
      <AgGridTable
        columnDefs={columnDefs}
        rowData={officeList}
        frameworkComponents={frameworkComponents}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
      {showOfficeModal && (
        <OfficeModal
          showModal={showOfficeModal}
          setShowModal={(value) => setOfficeModal(value)}
          refreshContent={props.refreshDirectorate}
          dataToEdit={selectedOfficeData}
        />
      )}
    </>
  );
}

export default OfficesTable;
