import React, { Component } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import routes from "../../navigation/routes";
import { useHistory } from "react-router-dom";

function Breadcrumb(props) {
  const history = useHistory();
  const { name, parentProps } = props && props;
  return (
    <div className="breadcrumb_custom">
      <span
        className="module"
        onClick={() => history.push(routes.dashboard)}
        style={{ paddingLeft: 0 }}
      >
        Dashboard
      </span>
      <span className="breadcrumb_custom-back" onClick={() => history.goBack()}>
        <AiOutlineDoubleLeft className="breadcrumb_custom-back-icon" />
        <span className="module">Back</span>
      </span>
      <span className="path-name">{name}</span>
    </div>
  );
}

export default Breadcrumb;
