import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/modal";
import Form from "../../../components/form";
import { ChangeUserPassword } from "../../../redux/actions/usersAction";
import { InputValidator, isEmpty } from "../../../helpers";

function UserPassword(props: any) {
  const { UserName } = props.userDetail;

  const [state, setState] = useState<any>({newPassword:''});

  const onChange = (name: any, value: any) => {
    let nextState={...state};
    nextState[name]=value;
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }else{
    setState(nextState);
    }
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;
    setState(nextState);
    return errors;
  };

  const onChangeUserPassword = async() => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      return null;
    }
    const param = {
      userName: UserName,
      newPassword: state.newPassword,
    };
    props.ChangeUserPassword(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onChangeUserPassword}
      saveButtonText="Change"
      title={UserName}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-md ">
          {"Do you really want to change password for "}{" "}
          <span className="bold">{UserName + "?"}</span>
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="newPassword"
            value={state.newPassword}
            onChange={onChange}
            label="New Password"
            placeholder="Enter new password"
            validators={["required"]}
            error={state.errors && state.errors.newPassword}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  ChangeUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPassword);
