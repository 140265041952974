import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "../../helpers";
import {
  clearMessage,
  removeMessage,
} from "../../redux/actions/validateAction";
import Icons from "../../components/icons";

interface ToastBody {
  type?: string;
  position?: string;
  remove?: boolean;
  rounded?: boolean;
  timeout?: number;
  clearMessage?: Function;
  removeMessage?: Function;
  validateReducer?: any;
  id?: string | number;
  children?: React.ReactNode;
}

function ToastBody(props: ToastBody) {
  const { type, position, rounded, timeout } = props;
  var toastClasses = classnames({
    toast: true,
    success: type === "Success" || type === "success",
    warning: type === "Warning" || type === "warning",
    info: type === "Info" || type === "info",
    danger: type === "Danger" || type === "danger",
    primary: type === "Primary" || type === "primary",
    "bottom-left": position === "bottom-left",
    "bottom-right": position === "bottom-right",
    "bottom-center": position === "bottom-center",
    "top-left": position === "top-left",
    "top-right": position === "top-right",
    "top-center": position === "top-center",
    rounded: rounded === true,
  });
  if (timeout && timeout > 0) {
    setTimeout(() => {
      props.clearMessage && props.clearMessage();
      if (props.remove && !isEmpty(props.id)) {
        props.removeMessage && props.removeMessage(props.id);
      }
    }, timeout);
  }

  const clear = () => {
    props.clearMessage && props.clearMessage();
    if (props.remove && !isEmpty(props.id)) {
      props.removeMessage && props.removeMessage(props.id);
    }
  };
  const arrayMessage = (children) => {
    let message = (
      <ol>
        {children.map((mes) => {
          return <li>{mes}</li>;
        })}
      </ol>
    );

    return message;
  };
  const renderMessage = (children) => {
    if (
      typeof children === "object" &&
      children.length !== undefined &&
      children.length >= 2
    ) {
      return arrayMessage(children);
    } else if (
      typeof children === "object" &&
      children.length !== undefined &&
      children.length === 1
    ) {
      return props.children;
    } else if (typeof children === "string" || typeof children === "number") {
      return props.children;
    } else {
      return null;
    }
  };

  return (
    <div className={toastClasses}>
      <span className="toast__body">
        {isEmpty(props.children) ? null : renderMessage(props.children)}
      </span>
      <span className="toast__close" onClick={() => clear()}>
        <Icons name="CrossCircle" />
      </span>
    </div>
  );
}

ToastBody.defaultProps = {
  type: "primary",
  position: "bottom-left",
  rounded: false,
  timeout: 5000,
};

const mapStateToProps = (state) => ({
  validateReducer: state.validateReducer,
});

export default connect(mapStateToProps, { clearMessage, removeMessage })(
  ToastBody
);
