import React, { useState } from "react";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import UserAndPermissionModal from "../forms/usersAndPermission";
import UserPasswordModal from "../../auth/userPassword";

export default function ActionCell(props: any) {
  const [showUserAndPermissionModal, setUserAndPermissionModal] = useState(
    false
  );
  const [showUserPasswordModal, setUserPasswordModal] = useState(false);

  const { Id, Email, UserName, Status } = props.data;

  const onChangePassword = () => {
    setUserPasswordModal(true);
  };

  const onChangeUserStatus = () => {
    const params = {
      userId: Id,
      status: !Status,
    };
    props.ChangeUserStatus(params, props.refreshContent);
  };

  const onDeleteUser = () => {
    props.DeleteUser(Id, props.refreshContent);
  };

  return (
    <>
      <Button
        title={"Edit"}
        buttonType="drop-button"
        bg="primary"
        useCustomPopup={true}
        onClick={() => setUserAndPermissionModal(true)}
        // justDrop
        dropComponent={
          <ul className="button-drop-list">
            <li className="button-drop-list__item" onClick={onChangePassword}>
              <span className="button-drop-name">Change Password</span>
            </li>
            <li
              className="button-drop-list__item with-hr"
              onClick={onChangeUserStatus}
            >
              <span className="button-drop-name">
                {Status ? "Disable User" : "Enable User"}
              </span>
            </li>
            <li
              className="button-drop-list__item with-hr"
              onClick={onDeleteUser}
            >
              <span className="button-drop-name">Delete User</span>
            </li>
          </ul>
        }
      />
      {showUserAndPermissionModal && (
        <UserAndPermissionModal
          showModal={showUserAndPermissionModal}
          setShowModal={(value) => setUserAndPermissionModal(value)}
          dataToEdit={props.data}
          refreshContent={props.refreshContent}
        />
      )}

      {showUserPasswordModal && (
        <UserPasswordModal
          showModal={showUserPasswordModal}
          setShowModal={(value) => setUserPasswordModal(value)}
          userDetail={props.data}
        />
      )}
    </>
  );
}
