export const getSelectedUploadFile = (
  validExtensions: any,
  selectedFiles: any,
  prevSelectedFiles: any
) => {
  let validFiles = [];
  const fileList = selectedFiles;
  let inValidFileExists = false;
  for (var i = 0; i < fileList?.length; i++) {
    const file = fileList.item(i);

    if (file) {
      Object.defineProperty(file, "myProp", {
        value: true,
      });
      let splittedFileName = file.name.split(".");

      if (
        !validExtensions.includes(
          splittedFileName[splittedFileName.length - 1].toLocaleLowerCase()
        )
      ) {
        inValidFileExists = true;
        break;
      }
      validFiles.push(file);
    }
  }

  const nonRepeatingFiles = prevSelectedFiles?.filter(
    ({ name: id1 }) => !validFiles?.some(({ name: id2 }) => id2 === id1)
  );
  if (nonRepeatingFiles) {
    validFiles = validFiles.concat(nonRepeatingFiles);
  }
  return { inValidFileExists, validFiles };
};
