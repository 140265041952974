import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import { GetDirectorate } from "../../../../redux/actions/common/dropdownAction";
import { SaveOfficeType } from "../../../../redux/actions/organizationAction";
import { InputValidator, isEmpty } from "../../../../helpers";

function OfficeTypes(props: any) {
  const { dataToEdit } = props;

  const [state, setState] = useState<any>({
    officeTypeId: 0,
    officeTypeName: "",
    selectedDirectorate: null,
  });

  useEffect(() => {
    props.GetDirectorate();
  }, []);

  useEffect(() => {
    if (dataToEdit && props.directorates) {
      let currentDirectorate = props.directorates.find(
        (f) => f.value == dataToEdit.DirectorateRefId
      );
      setState({
        officeTypeId: dataToEdit.Id,
        officeTypeName: dataToEdit.OfficeTypeName,
        selectedDirectorate: currentDirectorate,
      });
    }
  }, [dataToEdit, props.directorates]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;

    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }
    const param = {
      id: state.officeTypeId,
      name: state.officeTypeName,
      directorateRefId: state.selectedDirectorate
        ? state.selectedDirectorate.value
        : 0,
    };
    props.SaveOfficeType(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshContent && props.refreshContent();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Office Type"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="officeTypeName"
            value={state.officeTypeName}
            onChange={onChange}
            label="Office Type Name"
            validators={["required"]}
            error={state.errors && state.errors.officeTypeName}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="reactselect"
            name="selectedDirectorate"
            value={state.selectedDirectorate}
            options={props.directorates}
            onChange={onChange}
            label="Select Directorate"
            validators={["required"]}
            error={state.errors && state.errors.selectedDirectorate}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  directorates: state.dropdownReducer.directorates,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetDirectorate,
  SaveOfficeType,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeTypes);
