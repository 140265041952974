import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BarChart from "../../../components/charts/barChart";
import { GetOverallSummary } from "../../../redux/actions/dashboardAction";

let barsInThous = [
  { dataKey: "AddedInThous", fill: "#8884d8" },
  { dataKey: "VerifiedInThous", fill: "#82ca9d" },
];
let barsInLakhs = [
  { dataKey: "AddedInLakhs", fill: "#8884d8" },
  { dataKey: "VerifiedInLakhs", fill: "#82ca9d" },
];

function OverallSummary(props: any) {
  const { overallSummary } = props;
  const [isInLakh, setIsInLakh] = useState(false);

  useEffect(() => {
    props.GetOverallSummary();
  }, []);

  const handleRange = (status) => {
    setIsInLakh(status);
  };

  if (!overallSummary) {
    return null;
  }
  return (
    <div className="mt-md">
      <div className="heading">
        <div>OVERALL SUMMARY</div>
        <div>
          <span
            className={`link ${isInLakh ? " active" : ""}`}
            onClick={() => handleRange(true)}
          >
            Lakhs
          </span>
          <span
            className={`link ml-md ${!isInLakh ? " active" : ""}`}
            onClick={() => handleRange(false)}
          >
            Thousands
          </span>
        </div>
      </div>
      <BarChart
        title="Directorate Progress"
        data={overallSummary.DirectorateProgress}
        bars={isInLakh ? barsInLakhs : barsInThous}
        xAxisDataKey="DirectorateName"
        width={1250}
      />
      <div className="mt-md">
        <BarChart
          title="Provincial Progress"
          data={overallSummary.ProvincialProgress}
          bars={isInLakh ? barsInLakhs : barsInThous}
          xAxisDataKey="ProvinceName"
          width={1250}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  overallSummary: state.dashboardReducer.overallSummary,
});

const mapDispatchToProps = { GetOverallSummary };

export default connect(mapStateToProps, mapDispatchToProps)(OverallSummary);
