import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import { Tab, Tabs } from "../../../components/tabs/index.d";

import DirectorateDepartmentTable from "./tables/directorateDepartment";
import OfficesTable from "./tables/offices";
import OfficeTypesTable from "./tables/officeTypes";

import DirectorateModal from "./forms/directorate";
import OfficeModal from "./forms/office";
import OfficeTypesModal from "./forms/officeTypes";

import {
  GetDirectorateAndDepartmentList,
  GetOfficeTypeList,
  GetOfficeList,
  DeleteDirectorate,
  DeleteDepartment,
  DeleteOffice,
  DeleteOfficeType,
} from "../../../redux/actions/organizationAction";

const tabContent = [
  {
    id: 1,
    label: "Directorates And Department",
    component: "directorateAndDepartment",
  },
  {
    id: 2,
    label: "Office Types",
    component: "officeTypes",
  },
  {
    id: 3,
    label: "Offices",
    component: "offices",
  },
];

function Index(props: any) {
  const [state, setState] = useState({
    activeTab: tabContent[0],
    pageIndex: 0,
    pageSize: 10,
  });
  const [modalStatus, setModalStatus] = useState({
    directorateModal: false,
    officeTypesModal: false,
    officesModal: false,
  });
  const [updateTable, setUpdateTable] = useState(true);

  useEffect(() => {
    if (updateTable === true) {
      setUpdateTable(false);
      getLists();
    }
  }, [updateTable]);

  const getLists = () => {
    const params = {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
    };
    if (state.activeTab.id === 1) {
      props.GetDirectorateAndDepartmentList();
    } else if (state.activeTab.id === 2) {
      props.GetOfficeTypeList(params);
    } else if (state.activeTab.id === 3) {
      props.GetOfficeList(params);
    }
  };

  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    setUpdateTable(true);
  };

  function getTabwiseDetail(activeTab: any) {
    if (activeTab.id === 1) {
      return {
        title: "Add Directorate",
        component: DirectorateDepartmentTable,
      };
    } else if (activeTab.id === 2) {
      return { title: "Add Office Types", component: OfficeTypesTable };
    } else if (activeTab.id === 3) {
      return { title: "Add Offices", component: OfficesTable };
    } else return null;
  }

  const handleModalStatus = (status: boolean) => {
    const nextModalStatus = { ...modalStatus };
    if (state.activeTab.id === 1) {
      nextModalStatus["directorateModal"] = status;
    } else if (state.activeTab.id === 2) {
      nextModalStatus["officeTypesModal"] = status;
    } else if (state.activeTab.id === 3) {
      nextModalStatus["officesModal"] = status;
    }
    setModalStatus(nextModalStatus);
  };

  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    nextState.pageIndex = pageIndex;
    nextState.pageSize = rowsPerPage;
    setState(nextState);
    setUpdateTable(true);
  };

  const tabDetail = getTabwiseDetail(state.activeTab);
  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Directorate, Department, Office Types and Offices" />
        <Button
          title={tabDetail.title}
          buttonType="icon-button"
          leftIcon={<Icons name="Add" />}
          buttonClass="flex-end"
          bg="primary"
          onClick={() => handleModalStatus(true)}
        />
      </div>
      <div className="list-page-body common-body">
        <Tabs
          // type="block"
          noBackground
        >
          {tabContent.map((tab, index) => {
            let TabComponent: any = tabDetail.component;
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.component}
                id={tab.component}
                isActive={state.activeTab.component === tab.component}
                onClick={() => onChange("activeTab", tab)}
              >
                {TabComponent && (
                  <TabComponent
                    {...props}
                    {...state}
                    handlePagination={handlePagination}
                    refreshDirectorate={() => setUpdateTable(true)}
                  />
                )}
              </Tab>
            );
          })}
        </Tabs>
      </div>

      {modalStatus.directorateModal && (
        <DirectorateModal
          showModal={modalStatus.directorateModal}
          setShowModal={(value) => handleModalStatus(value)}
          refreshContent={() => setUpdateTable(true)}
        />
      )}
      {modalStatus.officesModal && (
        <OfficeModal
          showModal={modalStatus.officesModal}
          setShowModal={(value) => handleModalStatus(value)}
          refreshContent={() => setUpdateTable(true)}
        />
      )}
      {modalStatus.officeTypesModal && (
        <OfficeTypesModal
          showModal={modalStatus.officeTypesModal}
          setShowModal={(value) => handleModalStatus(value)}
          refreshContent={() => setUpdateTable(true)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  directorateAndDepartmentList:
    state.organizationReducer.directorateAndDepartmentList,
  officeTypeList: state.organizationReducer.officeTypeList,
  officeList: state.organizationReducer.officeList,
});

const mapDispatchToProps = {
  GetDirectorateAndDepartmentList,
  GetOfficeTypeList,
  GetOfficeList,
  DeleteDirectorate,
  DeleteDepartment,
  DeleteOffice,
  DeleteOfficeType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
