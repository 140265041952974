import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { InputValidator, isEmpty } from "../../../../helpers";
import { SaveAssetSubGroup } from "../../../../redux/actions/assetsAction";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import { GetAssetGroups } from "../../../../redux/actions/common/dropdownAction";

function AssetSubgroupModal(props: any) {
  const { propsData, dataToEdit, assetGroups } = props;
  const [state, setState] = useState<any>({
    subgroupId: 0,
    assetGroup: null,
    subgroupCode: 0,
    subgroupName: "",
  });

  useEffect(() => {
    props.GetAssetGroups();
  }, []);

  useEffect(() => {
    if (propsData && assetGroups) {
      let activeAssetGroup = assetGroups.find(
        (f) => f.Value == propsData.GroupCode
      );
      setState({ ...state, assetGroup: activeAssetGroup });
    }
    if (dataToEdit && assetGroups) {
      let activeAssetGroup = assetGroups.find(
        (f) => f.Value == dataToEdit.GroupCode
      );
      setState({
        subgroupId: dataToEdit.SubGroupId,
        assetGroup: activeAssetGroup,
        subgroupCode: dataToEdit.SubGroupCode,
        subgroupName: dataToEdit.SubGroupDesc,
      });
    }
  }, [propsData, dataToEdit, assetGroups]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }
    const param = {
      id: state.subgroupId,
      assetGroupRefCode: state.assetGroup ? state.assetGroup.Value : 0,
      subGroupCode: state.subgroupCode,
      subGroupDesc: state.subgroupName,
    };

    props.SaveAssetSubGroup(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshGroup && props.refreshGroup();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Asset Subgroup"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="reactselect"
            name="assetGroup"
            value={state.assetGroup}
            options={assetGroups}
            onChange={onChange}
            label="Asset Group"
            validators={["required"]}
            error={state.errors && state.errors.assetGroup}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="subgroupCode"
            value={state.subgroupCode}
            onChange={onChange}
            label="Subgroup Code"
            type="number"
            validators={["required"]}
            error={state.errors && state.errors.subgroupCode}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="subgroupName"
            value={state.subgroupName}
            onChange={onChange}
            label="Subgroup Name"
            validators={["required"]}
            error={state.errors && state.errors.subgroupName}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  assetGroups: state.dropdownReducer.assetGroups,
});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetAssetGroups,
  SaveAssetSubGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetSubgroupModal);
