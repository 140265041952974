import React, { useState } from "react";
import classnames from "classnames";
import TabItemContent from "./tabItemContent";
import TabItemHeader from "./tabItemHeader";
import Icons from "../icons";
import filterIcon from "../../assets/icons/filter.svg";

const TabBlockItems = (props: any) => {
  const [expand, setExpand] = useState(true);
  const collapseClass = classnames({
    "tabs-block": true,
    "tabs-collapse": props.isCollapse && expand === true,
    "tabs-expand": props.isCollapse && expand === false,
  });
  const collapseIconClass = classnames({
    "tabs-block__item": true,
    "tabs-collapse-icon": props.isCollapse && expand === true,
    "tabs-expand-icon": props.isCollapse && expand === false,
  });
  return (
    <div className="tabs-card">
      <div className={collapseClass}>
        <ol className="tabs-block__list" onClick={() => setExpand(!expand)}>
          {/* TabItemHeader renders Label part of tab */}
          <TabItemHeader setExpand={setExpand} {...props} />
          {/* Collapse icon for if tab iscollapsable */}
          {props.isCollapse ? (
            <li
              className={collapseIconClass}
              onClick={() => setExpand(!expand)}
            >
              <div className="tabs-filter">
                <div className="tabs-filter__icon">
                  <img src={filterIcon} alt="Filter Icon" />
                </div>
                Filteration
              </div>
              <div className="tabs-icon">
                <Icons name="ArrowDown" />
              </div>
            </li>
          ) : null}
        </ol>
        <div className="tabs-content">
          {/* TabItemHeader renders tab content of active tab */}
          <TabItemContent {...props} />
        </div>
      </div>
    </div>
  );
};

export default TabBlockItems;
