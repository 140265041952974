import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/AddBoxOutlined";
import ExpandLessIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import Pagination from "../../../../components/tables/pagination";

import Button from "../../../../components/button";
import Icons from "../../../../components/icons";
import SubAccordion from "./subAccordion";
import AssetGroupModal from "../forms/assetGroup";
import AssetSubgroupModal from "../forms/assetSubgroup";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    height: 40,
    "&$expanded": {
      minHeight: 50,
    },
    padding: 5,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const data = [
  {
    title: "11 Land",
  },
  {
    title: "15 Building",
  },
  {
    title: "25 Transmission Line",
  },
];

export default function MainAccordion(props: any) {
  const { assetGroupList } = props;
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [modalStatus, setModalStatus] = useState({
    showGroupModal: false,
    showSubgroupModal: false,
  });
  const [selectedGroupData, setSelectedGroupData] = useState(null);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleModals = (name: any, value: any) => {
    setModalStatus({
      ...modalStatus,
      [name]: value,
    });
  };

  const handleSubgroupModal = (e: any, assetGroup: any) => {
    stopDefaultAction(e);
    setSelectedGroupData(assetGroup);
    handleModals("showSubgroupModal", true);
  };

  const handleEditGroup = (e: any, assetGroup) => {
    stopDefaultAction(e);
    setSelectedGroupData(assetGroup);
    handleModals("showGroupModal", true);
  };

  const handleDeleteGroup = (e: any, assetGroup: any) => {
    stopDefaultAction(e);
    props.DeleteAssetGroup(assetGroup.GroupId, props.refreshGroup);
  };

  const stopDefaultAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleRowChange = (pageIndex, pageSize) => {
    props.handlePagination && props.handlePagination(pageIndex, pageSize);
  };

  const handleIndexChange = (pageIndex) => {
    props.handlePagination && props.handlePagination(pageIndex, props.pageSize);
  };

  if (!assetGroupList) {
    return null;
  }
  return (
    <>
      <div className="group_subgroup_category">
        <AccordionSummary>
          <Typography>{"Group, Subgroup and Category"}</Typography>
        </AccordionSummary>

        {assetGroupList &&
          assetGroupList.data.map((assetGroup: any, i: any) => {
            const { GroupCode, GroupDesc, GroupId } = assetGroup;
            const activePanel = `panel${i + 1}`;

            return (
              <Accordion
                square
                expanded={expanded === activePanel}
                onChange={handleChange(activePanel)}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${activePanel}-content`}
                  id={`${activePanel}-header`}
                >
                  {expanded === activePanel ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                  <span className="block">
                    <span className="block-name">
                      <Typography>
                        <span className="mr-sm">{GroupCode}</span>
                        <span>{GroupDesc}</span>
                      </Typography>
                    </span>

                    <span className="block-actions">
                      <Button
                        title="Add Subgroup"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Add" />}
                        bg="primary"
                        onClick={(e: any) => handleSubgroupModal(e, assetGroup)}
                      />
                      <Button
                        title="Edit"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Edit" />}
                        bg="primary"
                        buttonClass="ml-sm mr-sm"
                        onClick={(e: any) => handleEditGroup(e, assetGroup)}
                      />
                      <Button
                        title="Delete"
                        buttonType="icon-button"
                        leftIcon={<Icons name="Delete" />}
                        bg="danger"
                        onClick={(e: any) => handleDeleteGroup(e, assetGroup)}
                      />
                    </span>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <SubAccordion {...assetGroup} {...props} />
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <Pagination
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        pageIndex={props.pageIndex}
        rowsPerPage={props.pageSize}
        total={props.assetGroupList.total}
      />

      {modalStatus.showGroupModal && (
        <AssetGroupModal
          showModal={modalStatus.showGroupModal}
          setShowModal={(value) => handleModals("showGroupModal", value)}
          refreshGroup={props.refreshGroup}
          dataToEdit={selectedGroupData}
        />
      )}
      {modalStatus.showSubgroupModal && (
        <AssetSubgroupModal
          showModal={modalStatus.showSubgroupModal}
          setShowModal={(value) => handleModals("showSubgroupModal", value)}
          refreshGroup={props.refreshGroup}
          propsData={selectedGroupData}
        />
      )}
    </>
  );
}
