import { GET_ROLES } from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  roles: [],
};

export const rolesReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    default:
      return state;
  }
};
