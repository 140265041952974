import {
    GET_APP_VERSION
} from "../../types";

const initialState = {
    appVersion: '',

};

export function appVersionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_APP_VERSION:
            return {
                ...state,
                appVersion: action.payload,
            };


        default:
            return state;
    }
}
