import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEmpty } from "../../helpers";

function RadioGroup(props: any) {
  const {
    key,
    name,
    value,
    error,
    options,
    className,
    labelPosition,
    disabled,
    onChange,
  } = props;
  var inputclass = classnames({
    "custom-check": true,
    error: !isEmpty(error) && error,
    [className]: className,
    // [size]:size,
    "label-right": labelPosition === "right",
  });

  const handleChange = (e, value) => {
    const { name } = e.target;
    onChange && onChange(name, value);
  };
  return (
    <div className="form__radio-group_list">
      {options.map((option, index) => {
        return (
          <div className="form__radio-group" key={index}>
            <input
              key={key}
              type="radio"
              value={value && value.value}
              disabled={disabled}
              checked={value && option.value == value.value}
              className="form__radio-input"
              id={option.label}
              onChange={(e) => handleChange(e, option)}
              name={name}
            />
            <label htmlFor={option.label} className="form__radio-label">
              <span className="form__radio-button"></span>
              <span style={{ minWidth: "200px" }}>{option.label}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
  labelPosition: PropTypes.string,
  label: PropTypes.string,
  radioLabel: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  labelPosition: "left",
  disabled: false,
};

export default RadioGroup;
