import axios from "axios";
import { baseURL } from "../constants/urls";
import { dispatchError } from "../helpers/dispatchError";

const setHeaders = {
  headers: {
    Authorization: localStorage.getItem("AuthToken"),
  },
};

// "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6InNhZG1pbiIsIm5hbWVpZCI6ImUzMDBhMWRlLWVkYTgtNDQwNC04MzA2LWE0ZTE2MTczZWM2ZCIsIm5iZiI6MTYwNzMyMTI2NiwiZXhwIjoxNjA3MzI0ODY2LCJpYXQiOjE2MDczMjEyNjZ9.w5ImSXsI-WXXK38Kq0O0gMO0ccuw0Fq4dM28C0NYgWY",

const actionBase = axios.create({ baseURL: baseURL });

export const get = (
  url,
  dispatch,
  param = null,
  showResponseMessage = false
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    actionBase
      .get(fullUrl, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const get_by_id = (url, dispatch, id, showResponseMessage = false) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .get(fullUrl, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const post = (url, dispatch, param, showResponseMessage = true) => {
  return new Promise((resolve, reject) => {
    // let headers = setHeaders;
    // // if (isFileType) {
    // //   headers["contentType"] = "multipart/form-data";
    // // }

    actionBase
      .post(url, param, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put = (url, dispatch, param, showResponseMessage = true) => {
  return new Promise((resolve, reject) => {
    actionBase
      .put(url, param, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put_inline_param = (
  url,
  dispatch,
  param,
  showResponseMessage = true
) => {
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);

    actionBase
      .put(fullUrl, null, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const deletion = (url, dispatch, id, showResponseMessage = true) => {
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    actionBase
      .delete(fullUrl, setHeaders)
      .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

// export const deletion_inline_param = (url, dispatch, param) => {
//   return new Promise((resolve, reject) => {
//     const fullUrl = getFullStringUrl(url, param);
//     actionBase
//       .delete()
//       .then((res) => onSuccess(res, dispatch, resolve))
//       .catch((err) => onFailure(err, dispatch, reject));
//   });
// };

const getFullStringUrl = (url, param) => {
  const entries = param ? Object.entries(param) : null;
  let fullUrl = url;
  entries &&
    entries.map((entry, ind) => {
      if (ind == 0) {
        fullUrl = `${fullUrl}?${`${entry[0]}=${entry[1]}`}`;
      } else {
        fullUrl = `${fullUrl}&${`${entry[0]}=${entry[1]}`}`;
      }
    });
  return fullUrl;
};

const onSuccess = (res, dispatch, resolve, showResponseMessage) => {
  const response = res.data;
  if (response.Status == true) {
    if (showResponseMessage) {
      dispatchError(dispatch, response);
    }
    resolve(response);
  } else if (response.Status == undefined) {
    if (res.status == 200) {
      resolve(response);
    } else {
      dispatchError(dispatch, "Response status is not 200");
    }
  } else {
    dispatchError(dispatch, response);
  }
};

const onFailure = (err, dispatch, reject) => {
  dispatchError(dispatch, err.message);
  // reject(err);
};
