import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, InputValidator } from "../../../helpers";
import Form from "../../../components/form";
import Button from "../../../components/button";
import { ResetUserPassword } from "../../../redux/actions/usersAction";
import routes from "../../../navigation/routes";

function ResetPassword(props: any) {
  const [state, setState] = useState<any>({
    userId: null,
    code: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const currentUrl = window.location.href;
    const subUrl = currentUrl.split("?")[1];
    const params = subUrl.split("&");
    const code = params[0].split("=")[1];
    const userId = params[1].split("=")[1];

    setState({ ...state, code: code, userId: userId });
  }, []);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    setState(nextState);
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }
  };

  const onBackToLogin = () => {
    window.location.href = routes.login;
  };

  const onResetPassword = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      return null;
    }

    const param = {
      userId: state.userId,
      code: state.code,
      password: state.password,
      confirmPassword: state.confirmPassword,
    };
    props.ResetUserPassword(param);
  };

  const updateErrors = async (state) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;
    if (
      nextState.password &&
      nextState.confirmPassword &&
      nextState.password != nextState.confirmPassword
    ) {
      nextState.errors.confirmPassword = "Password didnot matched.";
    }
    setState(nextState);

    return errors;
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-heading">
          <span className="login-heading-title">NEA Asset Verification</span>
          <span className="login-heading-note">Please Reset Password</span>
        </div>
        <div className="login-body">
          <div className="mb-sm">
            <Form
              formName="textinput"
              name="password"
              value={state.password}
              autoFocus={true}
              onChange={onChange}
              label="Password"
              placeholder="Enter password"
              validators={["required"]}
              error={state.errors && state.errors.password}
            />
          </div>
          <div>
            <Form
              formName="textinput"
              //   type="password"
              name="confirmPassword"
              value={state.confirmPassword}
              onChange={onChange}
              onEnterPress={onResetPassword}
              label="Confirm Password"
              placeholder="Re-enter password"
              validators={["required"]}
              error={state.errors && state.errors.confirmPassword}
            />
          </div>
          <div className="login-body-button">
            <Button
              title="Confirm"
              buttonType="normal"
              bg="primary"
              onClick={onResetPassword}
            />
          </div>
        </div>
        <div className="login-forget">
          <span className="link-color" onClick={onBackToLogin}>
            Back to Login Page?
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ResetUserPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
