import { dispatchError } from "../../../helpers/dispatchError";

export * from "./dropdownAction";


export const DisplayClientSideMessage = (message: any) => (
  dispatch: Function
) => {
  dispatchError(dispatch, message);
};


