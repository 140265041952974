import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/modal";
import Button from "../../../components/button";
import Form from "../../../components/form";
import Icons from "../../../components/icons";
import ImageCropper from "../../../components/imageCropper";
import { InputValidator, isEmpty } from "../../../helpers";
import { DisplayClientSideMessage } from "../../../redux/actions/common";
import { CreateUsers, UpdateUsers } from "../../../redux/actions/usersAction";
import { GetRoles } from "../../../redux/actions/rolesAction";
import {
  GetDirectorate,
  GetProvinces,
} from "../../../redux/actions/common/dropdownAction";
import { GetUserByName } from "../../../redux/actions/usersAction";

const approverForRdo = {
  Id: null,
  Name: "Approver",
  RoleId: null,
  label: "Approver",
  value: null,
};

function UserAndPermission(props: any) {
  const [state, setState] = useState<any>({
    userId: null,
    fullName: "",
    mobileNumber: null,
    officePhone: null,
    email: "",
    userName: "",
    selectedRole: null,
    isApprover: false,
    selectedApprover: null,
    selectedDirectorates: [],
    selectedProviences: [],
    selectedImage: { src: null, preview: null },
    imageUrlToEdit: { src: null, preview: null },
  });

  useEffect(() => {
    props.GetRoles();
    props.GetDirectorate();
    props.GetProvinces();
  }, []);

  useEffect(() => {
    if (props.dataToEdit) {
      const { UserName } = props.dataToEdit;
      props.GetUserByName(UserName);
    }
    return function cleanup() {
      props.GetUserByName(null);
    };
  }, [props.dataToEdit]);

  useEffect(() => {
    if (
      props.userByUsername &&
      props.roles &&
      props.directorates &&
      props.provinces
    ) {
      let role = null;
      let approver = null;
      props.userByUsername &&
        props.userByUsername.URoles.map((ur) => {
          if (ur.RoleId <= 3) {
            role = props.roles.find((f) => f.RoleId == ur.RoleId);
          } else if (ur.RoleId > 3) {
            role = approverForRdo;
            approver = props.roles.find((f) => f.RoleId == ur.RoleId);
          }
        });

      let selectedDirectorates = [];
      if (props.userByUsername.ApprovalRoleDirectorateRefIds) {
        let splitedDirectorateIds = props.userByUsername.ApprovalRoleDirectorateRefIds.split(
          ","
        );
        splitedDirectorateIds &&
          splitedDirectorateIds.map((sdi) => {
            const currentDirectorate = props.directorates.find(
              (f) => f.Value == sdi
            );
            currentDirectorate && selectedDirectorates.push(currentDirectorate);
          });
      }
      let selectedProviences = [];
      if (props.userByUsername.ApprovalRoleProvinceRefIds) {
        let splitedProviencesIds = props.userByUsername.ApprovalRoleProvinceRefIds.split(
          ","
        );
        splitedProviencesIds &&
          splitedProviencesIds.map((sdi) => {
            const currentProvience = props.provinces.find(
              (f) => f.Value == sdi
            );
            currentProvience && selectedProviences.push(currentProvience);
          });
      }

      setState({
        ...state,
        userId: props.userByUsername.Id,
        fullName: props.userByUsername.FullName,
        mobileNumber: props.userByUsername.MobileNumber,
        officePhone: props.userByUsername.OfficePhoneNumber,
        email: props.userByUsername.Email,
        userName: props.userByUsername.UserName,
        selectedRole: role,
        isApprover: approver ? true : false,
        selectedApprover: approver,
        selectedDirectorates: selectedDirectorates,
        selectedProviences: selectedProviences,
        imageUrlToEdit: {
          src: props.userByUsername.UserImage,
          preview: props.userByUsername.UserImageThumbnail,
        },
      });
    }
  }, [props.userByUsername, props.roles, props.directorates, props.provinces]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;

    if (name === "selectedRole") {
      nextState["isApprover"] = value.Id ? false : true;
      nextState["selectedApprover"] = null;
    }

    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }

    let roles = [];
    if (state.selectedRole) {
      if (state.selectedRole.Id) {
        roles.push(state.selectedRole.Name);
      } else {
        roles.push(state.selectedApprover.Name);
      }
    }

    let directorateIds = "";
    state.selectedDirectorates &&
      state.selectedDirectorates.map((dir, i) => {
        if (i == 0) {
          directorateIds = directorateIds + dir.Value;
        } else {
          directorateIds = directorateIds + "," + dir.Value;
        }
      });

    let provienceIds = "";
    state.selectedProviences &&
      state.selectedProviences.map((pro, i) => {
        if (i == 0) {
          provienceIds = provienceIds + pro.Value;
        } else {
          provienceIds = provienceIds + "," + pro.Value;
        }
      });

    const param = {
      id: state.userId,
      fullName: state.fullName,
      mobileNumber: state.mobileNumber,
      officePhoneNumber: state.officePhone,
      email: state.email,
      userName: state.userName,
      roles: roles,
      userImage:state.imageUrlToEdit.src || null,
      userImageThumbnail:state.imageUrlToEdit.preview || null, 
      // password: null,
      approvalRoleDirectorateRefIds: directorateIds,
      approvalRoleProvinceRefIds: provienceIds,
    };

    if (state.userId) {
      props.UpdateUsers(param, state.selectedImage, onRefreshContent);
    } else {
      props.CreateUsers(param, state.selectedImage, onRefreshContent);
    }
  };

  const onRefreshContent = () => {
    props.refreshContent();
    props.setShowModal(false);
  };

  let rdoRoles = [];
  let ddlApprovers = [];
  if (props.roles && props.roles.length > 0) {
    rdoRoles = props.roles.filter((f) => f.RoleId <= 3);
    rdoRoles.push(approverForRdo);
    ddlApprovers = props.roles.filter((f) => f.RoleId > 3);
  }
  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"User and Permission"}
      width={"1100px"}
    >
      <>
        <div className="common_modal user-add">
          <div className="user-add-top ">
            <div className="user-add-top-left">
              <div className="mb-sm">
                <Form
                  formName="textinput"
                  name="fullName"
                  value={state.fullName}
                  onChange={onChange}
                  label="Full Name"
                  validators={["required"]}
                  error={state.errors && state.errors.fullName}
                />
              </div>
              <div className="flex mb-sm">
                <div className="mr-md">
                  <Form
                    formName="textinput"
                    name="mobileNumber"
                    value={state.mobileNumber}
                    onChange={onChange}
                    width="300px"
                    label="Mobile Number"
                  />
                </div>
                <div>
                  <Form
                    formName="textinput"
                    name="officePhone"
                    value={state.officePhone}
                    onChange={onChange}
                    width="300px"
                    label="Office Phone Number"
                  />
                </div>
              </div>
              <div className="mb-sm">
                <Form
                  formName="textinput"
                  name="email"
                  value={state.email}
                  onChange={onChange}
                  label="E-mail"
                  validators={["required", "email"]}
                  error={state.errors && state.errors.email}
                />
              </div>
              <div className="mb-sm">
                <Form
                  formName="textinput"
                  name="userName"
                  value={state.userName}
                  onChange={onChange}
                  width="300px"
                  label="Username"
                  validators={["required"]}
                  error={state.errors && state.errors.userName}
                />
              </div>
            </div>
            <div className="user-add-top-right">
              <ImageCropper
                name="selectedImage"
                selectedImage={state.selectedImage}
                imageUrlToEdit={state.imageUrlToEdit}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="mb-sm flex">
            <Form
              formName="radiogroup"
              name="selectedRole"
              options={rdoRoles}
              value={state.selectedRole}
              onChange={onChange}
              label="Role"
              width={"700px"}
              // disabled={isAddToRigo}
              validators={["required"]}
              error={state.errors && state.errors.selectedRole}
            />
            {state.isApprover && (
              <Form
                formName="reactselect"
                name="selectedApprover"
                value={state.selectedApprover}
                options={ddlApprovers}
                onChange={onChange}
                placeholder="Select Approver"
                width="200px"
                className="user-add-approver-ddl"
                validators={["required"]}
                error={state.errors && state.errors.selectedApprover}
              />
            )}
          </div>

          <div className="mb-sm">
            <Form
              formName="reactselect"
              multiple={true}
              name="selectedDirectorates"
              value={state.selectedDirectorates}
              options={props.directorates}
              onChange={onChange}
              label="Directorate"
              width="50%"
              validators={["required"]}
              error={state.errors && state.errors.selectedDirectorates}
            />
          </div>
          <div className="mb-sm">
            <Form
              formName="reactselect"
              multiple={true}
              name="selectedProviences"
              value={state.selectedProviences}
              options={props.provinces}
              onChange={onChange}
              label="Province"
              width="50%"
              validators={["required"]}
              error={state.errors && state.errors.selectedProviences}
            />
          </div>
        </div>
      </>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  roles: state.rolesReducer.roles,
  directorates: state.dropdownReducer.directorates,
  provinces: state.dropdownReducer.provinces,
  userByUsername: state.usersReducer.userByUsername,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  CreateUsers,
  UpdateUsers,
  GetRoles,
  GetDirectorate,
  GetProvinces,
  GetUserByName,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAndPermission);
