import { api, post, get } from "../../../networkService";
import {
  GET_COUNTRIES,
  COUNTRY_LOADING,
  GET_PROVINCE,
  PROVINCE_LOADING,
  GET_DIRECTORATE,
  DIRECTORATE_LOADING,
  GET_DISTRICTS,
  DISTRICTS_LOADING,
  GET_ASSETGROUP,
  ASSETGROUP_LOADING,
  GET_ASSETSUBGROUP,
  ASSETSUBGROUP_LOADING,
  OFFICES_LOADING,
  GET_OFFICES,
  OFFICETYPES_LOADING,
  GET_OFFICETYPES,
  UOMS_LOADING,
  DEPARTMENTS_LOADING,
  GET_DEPARTMENTS,
  GET_UOMS,
  ASSETSUBCODES_LOADING,
  GET_ASSETSUBCODES,
  LOCATIONS_LOADING,
  GET_LOCATIONS,
  ASSETCONDITION_LOADING,
  GET_ASSETCONDITION,
  GET_ENTRYUSERS,
  GET_ENTRYUSERS_LOADING,
} from "../../types";
import { ddlDataFormater, ddlDataFormaterKeyValue } from "../../../helpers";

export const GetCountries = () => async (dispatch: Function) => {
  dispatch({ type: COUNTRY_LOADING, payload: true });
  const res = await get(api.country, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_COUNTRIES, payload: formatedList });
};

export const GetProvinces = () => async (dispatch: Function) => {
  dispatch({ type: PROVINCE_LOADING, payload: true });
  const res = await get(api.common.province, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_PROVINCE, payload: formatedList });
};

export const GetDirectorate = () => async (dispatch: Function) => {
  dispatch({ type: DIRECTORATE_LOADING, payload: true });
  const res = await get(api.common.directorates, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_DIRECTORATE, payload: formatedList });
};

export const GetDistricts = () => async (dispatch: Function) => {
  dispatch({ type: DISTRICTS_LOADING, payload: true });
  const res = await get(api.common.districts, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_DISTRICTS, payload: formatedList });
};

export const GetAssetGroups = () => async (dispatch: Function) => {
  dispatch({ type: ASSETGROUP_LOADING, payload: true });
  const res = await get(api.common.assetGroups, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_ASSETGROUP, payload: formatedList });
};

export const GetAssetSubgroups = (groupCode) => async (dispatch: Function) => {
  dispatch({ type: ASSETSUBGROUP_LOADING, payload: true });
  let url = api.common.assetSubgroups;
  if (groupCode) {
    url = `${url}?groupCode=${groupCode}`;
  }
  const res = await get(url, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_ASSETSUBGROUP, payload: formatedList });
};

export const GetDepartments = (directorateId) => async (dispatch: Function) => {
  dispatch({ type: GET_DEPARTMENTS, payload: true });
  let url = api.common.departments;
  if (directorateId) {
    url = `${url}?directorateId=${directorateId}`;
  }
  const res = await get(url, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_DEPARTMENTS, payload: formatedList });
};
export const GetOffices = (provinceId) => async (dispatch: Function) => {
  dispatch({ type: OFFICES_LOADING, payload: true });
  let url = api.common.offices;
  if (provinceId) {
    url = `${url}?provinceId=${provinceId}`;
  }
  const res = await get(url, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_OFFICES, payload: formatedList });
};

export const GetOfficeTypes = () => async (dispatch: Function) => {
  dispatch({ type: OFFICETYPES_LOADING, payload: true });
  const res = await get(api.common.officeTypes, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_OFFICETYPES, payload: formatedList });
};

export const GetUnitOfMeasures = () => async (dispatch: Function) => {
  dispatch({ type: UOMS_LOADING, payload: true });
  const res = await get(api.common.uoms, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_UOMS, payload: formatedList });
};

export const GetAssetSubCodes = (groupCode, subGroupCode) => async (
  dispatch: Function
) => {
  dispatch({ type: ASSETSUBCODES_LOADING, payload: true });
  let url = `${api.common.assetSubCodes}?groupCode=${
    groupCode || ""
  }&subGroupCode=${subGroupCode || ""}`;

  const res = await get(url, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_ASSETSUBCODES, payload: formatedList });
};

// export const GetAssetSubCodeByGroupAndSubgroupId = (groupId, subgroupId) => (
//   dispatch,
// ) => {
//   const subgroups = assetSubCodeService.getByAssetGroupAndSubgroupId(
//     groupId,
//     subgroupId,
//   );
//   dispatch({type: GET_ASSETSUBCODES, payload: subgroups});
// };

export const GetLocations = () => async (dispatch: Function) => {
  dispatch({ type: LOCATIONS_LOADING, payload: true });
  const res = await get(api.common.locations, dispatch);
  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_LOCATIONS, payload: formatedList });
};

export const GetAssetCondition = () => async (dispatch: Function) => {
  dispatch({ type: ASSETCONDITION_LOADING, payload: true });
  const res = await get(api.common.assetCondition, dispatch);

  const formatedList = ddlDataFormater(res);
  dispatch({ type: GET_ASSETCONDITION, payload: formatedList });
};

export const GetEntryUsers = () => async (dispatch: Function) => {
  dispatch({ type: GET_ENTRYUSERS_LOADING, payload: true });
  const res = await post(api.user.userList, dispatch, {
    role: "asset entry",
    pageSize: 111111111,
  });
  var data = res.Data || [];
  console.clear();
  console.log(data);
  const formatedList = ddlDataFormaterKeyValue(data, "FullName", "FullName");
  dispatch({ type: GET_ENTRYUSERS, payload: formatedList });
};
