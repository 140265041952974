import React from "react";
import BarChart from "../../../components/charts/barChart";
import PieChart from "../../../components/charts/pieChart";
import { pieChartValue } from "../../../helpers/dataFormater";

const bars = [{ dataKey: "Value", fill: "#8884d8" }];

export default function Diagram(props: any) {
  const { progressSummary } = props;
  let formatedData: any = [];
  progressSummary &&
    progressSummary.ProcessSummary.map((process) => {
      // formatedData.push({ Title: process.Value, Total: 1 });
      formatedData.push({ Title: process.Value, Total: process.Key });
    });

  return (
    <div className="dashboard-diagram">
      <div className="mr-md">
        <PieChart
          title="Process Summary"
          showPercentageOnly={true}
          data={pieChartValue(formatedData)}
        />
      </div>
      {progressSummary && progressSummary.Progress && (
        <BarChart
          title="Progress (in Thousand)"
          data={progressSummary.Progress}
          bars={bars}
          xAxisDataKey="Key"
        />
      )}
    </div>
  );
}
