import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/AddBoxOutlined";
import ExpandLessIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import Pagination from "../../../../components/tables/pagination";

import Button from "../../../../components/button";
import Icons from "../../../../components/icons";

import DirectorateModal from "../forms/directorate";
import DepartmentModal from "../forms/department";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .015)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    height: 40,
    "&$expanded": {
      minHeight: 45,
    },
    padding: 5,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function DirectorateDepartmentTable(props: any) {
  const { directorateAndDepartmentList } = props;

  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [modalStatus, setModalStatus] = useState({
    showDirectorateModal: false,
    showDepartmentModal: false,
  });
  const [selectedDirectorateData, setSelectedDirectorateData] = useState<any>(
    null
  );
  const [selectedDepartmentData, setSelectedDepartmentData] = useState<any>(
    null
  );

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleModals = (name: any, value: any) => {
    setModalStatus({
      ...modalStatus,
      [name]: value,
    });
  };

  const handleAddDepartment = (e: any, directorateAndDepartment: any) => {
    stopDefaultAction(e);
    setSelectedDepartmentData(directorateAndDepartment);
    handleModals("showDepartmentModal", true);
  };

  const handleEditDirectorate = (e: any, directorateAndDepartment: any) => {
    stopDefaultAction(e);
    setSelectedDirectorateData(directorateAndDepartment);
    handleModals("showDirectorateModal", true);
  };

  const handleDeleteDirectorate = (e: any, directorateAndDepartment: any) => {
    stopDefaultAction(e);
    props.DeleteDirectorate(
      directorateAndDepartment.DirectorateId,
      props.refreshDirectorate
    );
  };

  const handleEditDepartment = (e: any, department) => {
    e.preventDefault();
    setSelectedDepartmentData(department);
    handleModals("showDepartmentModal", true);
  };

  const handleDeleteDepartment = (e: any, department) => {
    e.preventDefault();
    props.DeleteDepartment(department.DepartmentId, props.refreshDirectorate);
  };

  const stopDefaultAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };


  // const handleRowChange = (pageIndex, pageSize) => {
  //   props.handlePagination && props.handlePagination(pageIndex, pageSize);
  // };

  // const handleIndexChange = (pageIndex) => {
  //   props.handlePagination &&
  //         props.handlePagination(pageIndex, props.pageSize);    
  // };

  return (
    <>
    <div className="organization">
      <AccordionSummary>
        <div className="row">
          <div
            className="directorate title"
            style={{
              marginLeft: "2rem",
            }}
          >
            Directorate
          </div>
          <div className="department title">Department</div>
          <div className="actions title" />
        </div>
      </AccordionSummary>
      {directorateAndDepartmentList &&
        directorateAndDepartmentList.map(
          (directorateAndDepartment: any, i: any) => {
            const {
              DirectorateId,
              DirectorateName,
              Departments,
            } = directorateAndDepartment;
            const activePanel = `panel${i + 1}`;

            return (
              <div className="mb-xsm">
                <Accordion
                  square
                  expanded={expanded === activePanel}
                  onChange={handleChange(activePanel)}
                >
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${activePanel}-content`}
                    id={`${activePanel}-header`}
                  >
                    {expanded === activePanel ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                    <span className="row">
                      <span className="directorate">{DirectorateName}</span>
                      <span className="department" />
                      <span className="actions">
                        <Button
                          title="Add Department"
                          buttonType="icon-button"
                          leftIcon={<Icons name="Add" />}
                          bg="primary"
                          onClick={(e: any) =>
                            handleAddDepartment(e, directorateAndDepartment)
                          }
                        />
                        <Button
                          title="Edit"
                          buttonType="icon-button"
                          leftIcon={<Icons name="Edit" />}
                          bg="primary"
                          buttonClass="ml-sm mr-sm"
                          onClick={(e: any) =>
                            handleEditDirectorate(e, directorateAndDepartment)
                          }
                        />
                        <Button
                          title="Delete"
                          buttonType="icon-button"
                          leftIcon={<Icons name="Delete" />}
                          bg="danger"
                          onClick={(e: any) =>
                            handleDeleteDirectorate(e, directorateAndDepartment)
                          }
                        />
                      </span>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="details">
                      {Departments &&
                        Departments.map((department: any, ind: number) => {
                          const { DepartmentId, DepartmentName } = department;
                          return (
                            <span className="row" key={ind}>
                              <span className="directorate" />
                              <span className="department">
                                {DepartmentName}
                              </span>
                              <span className="actions">
                                <Button
                                  title="Edit"
                                  buttonType="icon-button"
                                  leftIcon={<Icons name="Edit" />}
                                  bg="primary"
                                  buttonClass="ml-sm mr-sm"
                                  onClick={(e: any) =>
                                    handleEditDepartment(e, department)
                                  }
                                />
                                <Button
                                  title="Delete"
                                  buttonType="icon-button"
                                  leftIcon={<Icons name="Delete" />}
                                  bg="danger"
                                  onClick={(e: any) =>
                                    handleDeleteDepartment(e, department)
                                  }
                                />
                              </span>
                            </span>
                          );
                        })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          }
        )}
    </div>
    {/* <Pagination
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        pageIndex={props.pageIndex}
        rowsPerPage={props.pageSize}
        total={(props.directorateAndDepartmentList && props.directorateAndDepartmentList.TotalRows) || 0}
      /> */}

    {modalStatus.showDirectorateModal && (
        <DirectorateModal
          showModal={modalStatus.showDirectorateModal}
          setShowModal={(value) => handleModals("showDirectorateModal", value)}
          refreshContent={props.refreshDirectorate}
          dataToEdit={selectedDirectorateData}
        />
      )}

      {modalStatus.showDepartmentModal && (
        <DepartmentModal
          showModal={modalStatus.showDepartmentModal}
          setShowModal={(value) => handleModals("showDepartmentModal", value)}
          refreshContent={props.refreshDirectorate}
          propsData={selectedDepartmentData}
        />
      )}
    </>
  );
}
