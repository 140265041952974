import React, { Component,useState,useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Route } from "react-router";

import {GetLoggedInUserDetails} from "../../redux/actions/usersAction"

// import HeaderTop from "./topBar";
import NavigatorBar from "./navigatorBar";
import routes from "../routes";
import Login from "../../scenes/auth/login";
import ResetPassword from "../../scenes/auth/resetPassword";

function Index(props) {
  const dispatch = useDispatch();
  let authToken = localStorage.getItem("AuthToken");

  const currentUrl = window.location.href;
  const isResetPasswordPage =
    currentUrl.search(routes.resetPassword) > 0 ? true : false;    

  useEffect(()=>{
    if(!isResetPasswordPage && authToken){
      dispatch(GetLoggedInUserDetails())
    }
  },[isResetPasswordPage,authToken]); 

  return (
    <>
      {isResetPasswordPage ? (
        <Route path="/" component={ResetPassword} />
      ) : authToken ? (
        <>
          {/* <header className="headers">
            <HeaderTop />
            <NavigatorBar />
          </header> */}
          <NavigatorBar />
          <div className="app-container">{props.children}</div>
        </>
      ) : (
        <Route path="/" component={Login} />
      )}
    </>
  );
}

export default Index;
