import React, { useState } from "react";
import AgGridTable from "../../../../components/tables/agGridTable";
import AssetNameCell from "./assetNameCell";
import AddedOnCell from "./addedOnCell";
import ActionCell from "./actionCell";

const columnDefs = [
  { headerName: "Entry ID", field: "Id", width: 60 },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "actionCell",
    // cellStyle: { color: "#1786ff" },
    width: 140,
  },
  {
    headerName: "Location",
    tooltipField: "PhysicalLocation",
    field: "PhysicalLocation",
    fontSize: 13,
    width: 220,
  },
  {
    headerName: "Asset Name",
    field: "AssetName",
    tooltipField: "AssetName",
    // filter: true,
    cellRenderer: "assetNameCell",
    width: 220,
  },
  {
    headerName: "Asset Sub-Group",
    field: "AssetSubGroupDesc",
    width: 150,
    tooltipField: "AssetSubGroupDesc",
  },
  {
    headerName: "Asset Sub-Code",
    field: "AssetSubCodeName",
    width: 150,
    tooltipField: "AssetSubCodeName",
  },
  {
    headerName: "Asset Condition",
    field: "AssetConditionName",
    width: 150,
    tooltipField: "AssetConditionName",
  },
  {
    headerName: "Capitalization Date(Nepali)",
    field: "CapitalizationDate",
    width: 100,
    tooltipField: "CapitalizationDate",
  },
  //{ headerName: "Asset Group", field: "AssetGroupDesc", width: 150 },
  { headerName: "Unit", field: "UOMDesc", width: 80 },
  { headerName: "Meas.", field: "Measurement", width: 60 },
  { headerName: "Status", field: "StatusName", width: 80 },
  { headerName: "Capacity", field: "Capacity", width: 70 },
  { headerName: "Model", field: "Model", width: 80, tooltipField: "Model" },
  {
    headerName: "Added On",
    // headerClass: "cash-header",
    field: "CreatedOn",
    cellRenderer: "addedOnCell",
    width: 180,
  },
  
];

function Table(props: any) {
  const frameworkComponents = {
    assetNameCell: (rowProps: any) => <AssetNameCell {...rowProps} />,
    addedOnCell: (rowProps: any) => <AddedOnCell {...rowProps} />,
    actionCell: (rowProps: any) => <ActionCell {...rowProps} {...props} />,
  };

  return (
    <div className="assets-table">
      <AgGridTable
        // rowHeight={50}
      
        columnDefs={columnDefs}
        rowData={props.assets}
        isLoading={props.isAssetLoading}
        frameworkComponents={frameworkComponents}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
    </div>
  );
}

export default Table;
