import { isEmpty } from "../../helpers";
import { api, deletion, post, get, put, get_by_id } from "../../networkService";
import {
  GET_DIRECTORATE_AND_DEPARTMENT_LIST,
  GET_DEPARTMENT_BY_ID,
  GET_OFFICETYPE_LIST,
  GET_OFFICE_LIST,
} from "../types";

//#region Department
export const GetDirectorateAndDepartmentList = () => async (
  dispatch: Function
) => {
  const res = await get(api.organization.department.departmentList, dispatch); 
  dispatch({
    type: GET_DIRECTORATE_AND_DEPARTMENT_LIST,
    payload: res,
  });
};

export const GetDepartmentById = (id: any) => async (dispatch: Function) => {
  if (isEmpty(id)) {
    dispatch({ type: GET_DEPARTMENT_BY_ID, payload: null });
  } else {
    const res = await get_by_id(
      api.organization.department.departments,
      dispatch,
      id,
      false
    );
    dispatch({ type: GET_DEPARTMENT_BY_ID, payload: res.Data });
  }
};

export const SaveDepartment = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.organization.department.departments, dispatch, params);
  } else {
    res = await post(api.organization.department.departments, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteDepartment = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(
    api.organization.department.departments,
    dispatch,
    id
  );
  res.Status && refreshContent && refreshContent();
};

//#endregion

//#region Directorate
export const SaveDirectorate = (
  params: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(
      api.organization.directorate.directorates,
      dispatch,
      params
    );
  } else {
    res = await post(
      api.organization.directorate.directorates,
      dispatch,
      params
    );
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteDirectorate = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(
    api.organization.directorate.directorates,
    dispatch,
    id
  );
  res.Status && refreshContent && refreshContent();
};

//#endregion

//#region OfficeType
export const GetOfficeTypeList = (params: any) => async (
  dispatch: Function
) => {
  const res = await post(
    api.organization.officeType.officeTypeList,
    dispatch,
    params,
    false
  );
  dispatch({
    type: GET_OFFICETYPE_LIST,
    payload: res.Data,
  });
};

export const SaveOfficeType = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.organization.officeType.officeTypes, dispatch, params);
  } else {
    res = await post(api.organization.officeType.officeTypes, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteOfficeType = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(
    api.organization.officeType.officeTypes,
    dispatch,
    id
  );
  res.Status && refreshContent && refreshContent();
};
//#endregion OfficeType

//#region Office
export const GetOfficeList = (params: any) => async (dispatch: Function) => {
  const res = await post(
    api.organization.office.officeList,
    dispatch,
    params,
    false
  );
  dispatch({
    type: GET_OFFICE_LIST,
    payload: res.Data,
  });
};

export const SaveOffice = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.organization.office.offices, dispatch, params);
  } else {
    res = await post(api.organization.office.offices, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteOffice = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.organization.office.offices, dispatch, id);
  res.Status && refreshContent && refreshContent();
};
//#endregion
