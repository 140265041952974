import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Form from "../../../../components/form";
import { SaveAssetSubCode } from "../../../../redux/actions/assetsAction";
import {
  GetAssetGroups,
  GetAssetSubgroups,
} from "../../../../redux/actions/common/dropdownAction";
import { DisplayClientSideMessage } from "../../../../redux/actions/common";
import { InputValidator, isEmpty } from "../../../../helpers";

function AssetCategoryModal(props: any) {
  const { dataToEdit, propsData, assetGroups, assetSubgroups } = props;

  const [state, setState] = useState<any>({
    assetSubCodeId: 0,
    assetGroup: null,
    assetSubgroup: null,
    categoryCode: 0,
    categoryName: "",
    accountCode: "",
  });

  useEffect(() => {
    props.GetAssetSubgroups();
    props.GetAssetGroups();
  }, []);

  useEffect(() => {
    if (propsData && assetGroups && assetSubgroups) {
      let activeAssetGroup = assetGroups.find(
        (f) => f.Value == propsData.GroupCode
      );
      let activeAssetSubgroup = assetSubgroups.find(
        (f: any) => f.Value == propsData.SubGroupCode
      );
      setState({
        ...state,
        assetGroup: activeAssetGroup,
        assetSubgroup: activeAssetSubgroup,
      });
    }

    if (dataToEdit && assetGroups && assetSubgroups) {
      let activeAssetGroup = assetGroups.find(
        (f) => f.Value == dataToEdit.GroupCode
      );
      let activeAssetSubgroup = assetSubgroups.find(
        (f: any) => f.Value == dataToEdit.SubGroupCode
      );

      setState({
        ...state,
        assetSubCodeId: dataToEdit.SubCodeId,
        assetGroup: activeAssetGroup,
        assetSubgroup: activeAssetSubgroup,
        categoryCode: dataToEdit.SubCode,
        categoryName: dataToEdit.SubCodeName,
        accountCode: dataToEdit.AccountCode,
      });
    }
  }, [propsData, dataToEdit, assetGroups, assetSubgroups]);

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;

    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;

    setState(nextState);
    return errors;
  };

  const onSave = async () => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      props.DisplayClientSideMessage("Please fill all required fields.");
      return null;
    }
    const param = {
      id: state.assetSubCodeId,
      assetGroupRefCode: state.assetGroup ? state.assetGroup.Value : 0,
      assetSubGroupRefCode: state.assetSubgroup ? state.assetSubgroup.Value : 0,
      subCode: state.categoryCode,
      subCodeName: state.categoryName,
      accountCode: state.accountCode,
    };
    props.SaveAssetSubCode(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshGroup && props.refreshGroup();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onSave}
      title={"Add Asset Category"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-sm">
          <Form
            formName="reactselect"
            name="assetGroup"
            value={state.assetGroup}
            options={assetGroups}
            onChange={onChange}
            label="Asset Group"
            validators={["required"]}
            error={state.errors && state.errors.assetGroup}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="reactselect"
            name="assetSubgroup"
            value={state.assetSubgroup}
            options={assetSubgroups}
            onChange={onChange}
            label="Asset Subgroup"
            validators={["required"]}
            error={state.errors && state.errors.assetSubgroup}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="categoryCode"
            value={state.categoryCode}
            onChange={onChange}
            label="Category Code"
            width="200px"
            type="number"
            validators={["required"]}
            error={state.errors && state.errors.categoryCode}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="categoryName"
            value={state.categoryName}
            onChange={onChange}
            label="Asset Category Name"
            validators={["required"]}
            error={state.errors && state.errors.categoryName}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="accountCode"
            value={state.accountCode}
            onChange={onChange}
            label="Account Code"
            width="200px"
            validators={["required"]}
            error={state.errors && state.errors.accountCode}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  assetGroups: state.dropdownReducer.assetGroups,
  assetSubgroups: state.dropdownReducer.assetSubgroups,
});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetAssetGroups,
  GetAssetSubgroups,
  SaveAssetSubCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetCategoryModal);
