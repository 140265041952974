interface dataProps {
  Text?: string;
  Value: string;
  Key?: string | number;
}

interface Label {
  label: string;
  value: string | number;
}

export const Arrays = (data: [dataProps]) => {
  let arrayItem: any = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item.Text, value: item.Value });
    });
  }
  return arrayItem;
};

export const ArraysKey = (data: [dataProps]) => {
  let arrayItem: any = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item.Value, value: item.Key });
    });
  }
  return arrayItem;
};

type EmployeeLabel = {
  FullName: string;
  EmployeeId: string | number;
  Imagepath: string;
};

export const ArraysWithImage = (data: [EmployeeLabel]) => {
  let arrayItem: any = [];
  if (data) {
    data.map((item, key) => {
      item &&
        arrayItem.push({
          label: item.FullName,
          value: item.EmployeeId,
          img: item.Imagepath,
        });
    });
  }
  return arrayItem;
};

interface pieItems {
  Title: string;
  Total: number;
  ValuePercentage: number | string;
}

export const pieChartValue = (items: [pieItems]) => {
  return items
    ? items.map((item: pieItems) => {
        interface retItems {
          name: string;
          value: number | null;
          percantage: number | string | null;
        }
        let ret: retItems = { name: "", value: null, percantage: null };
        ret.name = item.Title;
        ret.value = item.Total;
        if (item.ValuePercentage) {
          ret.percantage = item.ValuePercentage;
        }
        return ret;
      })
    : [];
};
