import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEmpty } from "../../helpers";
import { wholeFormInterface } from "../../ts/interfaces/formInterface";

import TextInput from "./textInput";
import TextArea from "./textArea";
import ReactSelect from "./reactSelect";
import Switch from "./switch";
import RadioGroup from "./radioGroup";
import CheckGroup from "./checkGroup";
import DragAndDropUpload from "./dragAndDropUpload";
// import DateInput from "./dateInput";
import SearchableSelect from "./searchableSelect";
import ReactCalendar from "./reactCalendar"

declare module "react" {
  interface HTMLAttributes<T> extends DOMAttributes<T> {
    validators?: any[];
  }
}

class index extends React.Component<wholeFormInterface, {}> {
  public static defaultProps = {
    formName: "textinput",
    name: "",
    label: "",
    autoFocus: false,
    alignment: "vertical",
    validators: [],
  };

  constructor(props: wholeFormInterface) {
    super(props);
    this.getDynamicComponent = this.getDynamicComponent.bind(this);
  }

  getDynamicComponent(): any {
    let { formName } = this.props;
    // formName = formName.toLowerCase();
    switch (formName) {
      case "textinput":
        return TextInput;
      case "textarea":
        return TextArea;
      case "reactselect":
        return ReactSelect;
      case "switch":
        return Switch;
      case "radiogroup":
        return RadioGroup;
      case "checkgroup":
        return CheckGroup;
      case "dragAndDropUpload":
        return DragAndDropUpload;
      // case "dateInput":
      //   return DateInput;
      case "searchableSelect":
        return SearchableSelect;
      case "reactcalendar":
        return ReactCalendar;

      default:
        return null;
    }
  }

  render() {
    const {
      formName,
      name,
      label,
      alignment,
      size,
      width,
      className,
      validators,
      error,
      loading,
    } = this.props;

    let inputclass = classnames({
      "genericForm-group": true,
      error: !isEmpty(error) && error,
      horizontalGenericForm: alignment && alignment == "horizontal",
      [className]: className,
      [size]: size,
      // "check-group": formName === "checkBoxGroup",
    });

    let DynamicComponent = this.getDynamicComponent();
    const load = !isEmpty(loading) ? loading : false;
    return (
      <div
        id={name}
        validators={validators}
        className={inputclass}
        style={{ width }}
      >
        {label &&
          formName != "checkgroup" &&
          // formName != "checkBoxGroup" &&
          formName != "switch" &&
          !load && (
            <div style={{ marginBottom: "0.35rem" }}>
              <label htmlFor={name} className="genericForm-group__label">
                {label}
                {!isEmpty(validators) && validators.find((f) => f == "required")
                  ? " *"
                  : ""}
              </label>
            </div>
          )}
        {DynamicComponent ? (
          <DynamicComponent {...this.props} />
        ) : (
          "Invalid form name"
        )}
        {error && <div className="genericForm-group__message">{error}</div>}
      </div>
    );
  }
}

export default index;
