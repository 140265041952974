import { isEmpty } from "../../helpers";
import { formatedMonthDayYearTime } from "../../helpers/date/dateFormat";
import { api, deletion, post, get_by_id, put } from "../../networkService";
import {
  GET_ASSETS_LOADING,
  GET_ASSETS,
  GET_ASSETS_EXPORT_EXCEL,
  GET_ASSETS_BY_ID,
  ON_COMMENT_ASSET,
  GET_ASSET_GROUPS,
} from "../types";

//#region Assets Listing
export const GetAssets = (params: any, isExport: boolean) => async (
  dispatch: Function
) => {
  dispatch({
    type: GET_ASSETS_LOADING,
    payload: true,
  });
  const res = await post(api.asset.assetList, dispatch, params, false);

  let formatedData = [];
  res.Data &&
    res.Data.map((data: any) => {
      formatedData.push({
        ...data,
        CreatedOn: formatedMonthDayYearTime(data.CreatedOn),
        StatusModifiedOn:formatedMonthDayYearTime(data.StatusModifiedOn),
      });
    });

  dispatch({
    type: isExport ? GET_ASSETS_EXPORT_EXCEL : GET_ASSETS,
    payload: formatedData,
  });
};

export const GetAssetsById = (id: any) => async (dispatch: Function) => {
  const res = await get_by_id(api.asset.assets, dispatch, id);
  dispatch({ type: GET_ASSETS_BY_ID, payload: res.Data });
};

export const DeleteAssets = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.asset.assets, dispatch, id);
  res.Status && refreshContent && refreshContent();
};
//#endregion

//#region AssetStatusHistory
export const ApproveAsset = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await post(api.asset.approve, dispatch, params);
  res.Status && refreshContent && refreshContent();
};
export const SendAssetBackForReview = (
  params: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  const res = await post(api.asset.backForReview, dispatch, params);
  res.Status && refreshContent && refreshContent();
};
export const CommentOnAsset = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await post(api.asset.comments, dispatch, params, false);
  res.Status && refreshContent && refreshContent();
  // dispatch({ type: ON_COMMENT_ASSET, payload: res.Data });
};
export const DeleteComment = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.asset.comments, dispatch, id, false);
  res.Status && refreshContent && refreshContent();
  // dispatch({ type: ON_COMMENT_ASSET, payload: res.Data });
};
//#endregion

//#region Asset Group
export const GetAssetGroupList = (params: any) => async (
  dispatch: Function
) => {
  const res = await post(api.assetGroup.groupList, dispatch, params, false);

  dispatch({
    type: GET_ASSET_GROUPS,
    payload: { data: res.Data, total: res.RecordsTotal },
  });
};

export const SaveAssetGroup = (params: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.assetGroup.groups, dispatch, params);
  } else {
    res = await post(api.assetGroup.groups, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteAssetGroup = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.assetGroup.groups, dispatch, id);
  res.Status && refreshContent && refreshContent();
};
//#endregion

//#region Asset Subgroup
export const SaveAssetSubGroup = (
  params: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.assetSubGroup.subGroups, dispatch, params);
  } else {
    res = await post(api.assetSubGroup.subGroups, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteAssetSubGroup = (
  id: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  const res = await deletion(api.assetSubGroup.subGroups, dispatch, id);
  res.Status && refreshContent && refreshContent();
};
//#endregion

//#region Asset Sub Code
export const SaveAssetSubCode = (
  params: any,
  refreshContent: Function
) => async (dispatch: Function) => {
  let res = null;
  if (!isEmpty(params.id) && params.id > 0) {
    res = await put(api.assetSubCode.subCodes, dispatch, params);
  } else {
    res = await post(api.assetSubCode.subCodes, dispatch, params);
  }
  res.Status && refreshContent && refreshContent();
};

export const DeleteAssetSubCode = (id: any, refreshContent: Function) => async (
  dispatch: Function
) => {
  const res = await deletion(api.assetSubCode.subCodes, dispatch, id);
  res.Status && refreshContent && refreshContent();
};
//#endregion
