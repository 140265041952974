import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { otherInputInterface } from "../../ts/interfaces/formInterface";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 26,
      padding: 0,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(22px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#077dff",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#077dff",
        // border: "6px solid #fff",
        border: "none",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#ddd", // theme.palette.grey[100],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function CustomizedSwitches(props: otherInputInterface) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    props.onChange && props.onChange(name, checked);
  };

  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={props.value}
          onChange={handleChange}
          name={props.name}
        />
      }
      label={props.label}
    />
  );
}
