import React, { Component } from "react";
import Form from "../../../components/form";

function SearchAndFilter(props: any) {
  const { date } = props;
  return (
    <div className="filters">
      <div className="filters-grid">
        <Form
          formName="reactcalendar"
          name="date"
          value={date}
          maxDate={new Date()}
          onChange={props.onChange}
          width={"220px"}
          placeholder="Selecte Date..."
        />
      </div>
    </div>
  );
}

export default SearchAndFilter;
