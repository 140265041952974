import React, { useState } from "react";
import AgGridTable from "../../../../components/tables/agGridTable";

/*
\"UserName": "l1.user",
    "FullName": "L1User",
    "PhysicalLocation": "Loc",
    "AssetApprovedForD1": 4,
    "AssetApprovedForWeekDay": 4,
    "AssetApprovedForMonth": 4,
    "AssetApprovedTillDate": 0
*/
const columnDefs = [
  {
    headerName: "User Name",
    tooltipField: "FullName",
    field: "FullName",
    fontSize: 13,
    width: 220,
    flex: 1,
  },
  {
    headerName: "Office",
    tooltipField: "Office",
    field: "Office",
    fontSize: 13,
    width: 220,
    flex: 2,
  },
  {
    headerName: "Approved Yesterday",
    tooltipField: "AssetApprovedForD1",
    field: "AssetApprovedForD1",
    fontSize: 13,
    width: 150,
  },
  {
    headerName: "Approved This Week",
    tooltipField: "AssetApprovedForWeekDay",
    field: "AssetApprovedForWeekDay",
    fontSize: 13,
    width: 150,
  },
  {
    headerName: "Approved This Month",
    tooltipField: "AssetApprovedForMonth",
    field: "AssetApprovedForMonth",
    fontSize: 13,
    width: 150,
  },
  {
    headerName: "Approved Till Date",
    tooltipField: "AssetApprovedTillDate",
    field: "AssetApprovedTillDate",
    fontSize: 13,
    width: 150,
  },
];

function Table(props: any) {
  return (
    <div className="assets-table">
      <AgGridTable
        columnDefs={columnDefs}
        rowData={props.approvalList}
        isLoading={props.approvalListLoading}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
    </div>
  );
}

export default Table;
