import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/modal";
import Form from "../../../components/form";
import { InputValidator, isEmpty } from "../../../helpers";
import { ChangeOwnPassword } from "../../../redux/actions/usersAction";
import {DisplayClientSideMessage} from "../../../redux/actions/common"

function UserPassword(props: any) {
  const [state, setState] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const onChange = (name: any, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    if (!isEmpty(state.errors)) {
      updateErrors(nextState);
    }

    setState(nextState);
  };

  const updateErrors = async (state: any) => {
    let errors = await InputValidator(document, state);
    let nextState = { ...state };
    nextState["errors"] = errors;
    if(nextState.newPassword && nextState.confirmNewPassword && nextState.newPassword!=nextState.confirmNewPassword){
      nextState.errors.confirmNewPassword="New password doesnot match."
    }

    setState(nextState);
    return errors;
  };

  const onChangePassword = async() => {
    let errors = await updateErrors(state);
    if (!isEmpty(errors)) {
      return null;
    }
    const param = {
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
    };
    props.ChangeOwnPassword(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      onModalClose={() => props.setShowModal(false)}
      onSave={onChangePassword}
      saveButtonText="Change"
      title={"Change Password"}
      width={"450px"}
    >
      <div className="common_modal">
        <div className="mb-md ">{"Do you want to change your password?"}</div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="currentPassword"
            value={state.currentPassword}
            onChange={onChange}
            label="Current Password"
            placeholder="Enter current password"
            validators={["required"]}
            error={state.errors && state.errors.currentPassword}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="newPassword"
            value={state.newPassword}
            onChange={onChange}
            label="New Password"
            placeholder="Enter new password"
            validators={["required"]}
            error={state.errors && state.errors.newPassword}
          />
        </div>
        <div className="mb-sm">
          <Form
            formName="textinput"
            name="confirmNewPassword"
            value={state.confirmNewPassword}
            onChange={onChange}
            label="Confirm Password"
            placeholder="Re-enter new password"
            validators={["required"]}
            error={state.errors && state.errors.confirmNewPassword}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  ChangeOwnPassword,
  DisplayClientSideMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPassword);
