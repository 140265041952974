import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState = {}) {
  const production = process.env.NODE_ENV !== "development";

  let middleware = [thunk];

  // if (!production) {
  //   middleware.push(require("redux-immutable-state-invariant").default());
  // }

  if (production) {
    return createStore(reducers, initialState, applyMiddleware(...middleware));
  } else {
    let composeEnhancers = composeWithDevTools({
      //Specify name here, actionsBlacklist, actionsCreators and other options if needed
    });

    return createStore(
      reducers,
      initialState,
      composeEnhancers(applyMiddleware(...middleware))
    );
  }
}
