import React, { Component } from "react";
import Form from "../../components/form";

function SearchAndFilter(props: any) {
  const { search } = props;
  return (
    <div className="filters">
      <div className="filters-grid">
        <Form
          formName="textinput"
          name="search"
          value={search}
          onChange={props.onChange}
          placeholder="Search"
          width="250px"
        />
      </div>
    </div>
  );
}

export default SearchAndFilter;
