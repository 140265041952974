import React, { Component, useState, useEffect } from "react";
import { connect, useStore } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb";
import Button from "../../../components/button";
import Icons from "../../../components/icons";
import { Tab, Tabs } from "../../../components/tabs/index.d";
import { assetsStageEnum } from "../../../enums/assets";
import SearchFilter from "./searchAndFilter";
import Table from "./table";
import { GetReportApproval } from "../../../redux/actions/report";
import { isEmpty } from "../../../helpers";
import ExportExcel from "../../../components/export/exportExcel";

const exportName = "Daily Flash Report";

const exportColumns = [
  { label: "User Name", value: "UserName" },
  { label: "Office", value: "Office" },
  { label: "Approved Yesterday", value: "AssetApprovedForD1" },
  { label: "Approved This Week", value: "AssetApprovedForWeekDay" },
  { label: "Approved This Month", value: "AssetApprovedForMonth" },
  { label: "Approved Till Date", value: "AssetApprovedTillDate" },
];

const tabContent = [
  {
    label: "L1 Approval",
    component: "allAssets",
    status: assetsStageEnum.l1Approval,
  },
  {
    label: "L2 Approval",
    component: "pendingApproval",
    status: assetsStageEnum.l2Approval,
  },
  {
    label: "L3 Approval",
    component: "l1Approved",
    status: assetsStageEnum.l3Approval,
  },
];

function Index(props: any) {
  const selectedTabIndex =
    (props.location.state && props.location.state.selectedTabIndex) || 0;

  const [state, setState] = useState({
    activeTab: tabContent[selectedTabIndex],
    date: new Date().toDateString(),
  });
  const [updateTable, setUpdateTable] = useState(true);
  useEffect(() => {
    if (updateTable === true) {
      setUpdateTable(false);
      fetchData();
    }
  }, [updateTable]);
  const getHeaders = () => {
    var headers = [];
    headers.push({ title: "", text: "" });

    headers.push({
      title: "Daily Flash Report(Approval):",
      text: state.date || "",
    });
    headers.push({
      title: state.activeTab && state.activeTab.label,
      text: "",
    });

    headers.push({ title: "", text: "" });
    return headers;
  };
  const onChange = async (name: string, value: any) => {
    let nextState = { ...state };
    nextState[name] = value;
    nextState["pageIndex"] = 0;

    //  nextState = fetchDropDownItems(name, value, nextState);
    setState(nextState);
    setUpdateTable(true);
  };
  const handleExcelExport = async () => {
    await fetchData(true);
  };
  const handlePagination = (pageIndex: any, rowsPerPage: any) => {
    let nextState = { ...state };
    // nextState.pageIndex = pageIndex;
    // nextState.pageSize = rowsPerPage;
    // setState(nextState);
    // setUpdateTable(true);
  };

  const fetchData = async (isExport = false) => {
    if (isEmpty(state.date)) {
      return;
    }
    const params = {
      status: state.activeTab.status,
      date: state.date,
      isExport,
    };
    await props.GetReportApproval(params);
  };

  return (
    <div className="list-page common-container">
      <div className="common-heading">
        <Breadcrumb name="Daily Flash Report (Approval)" />
      </div>
      <div className="list-page-body common-body">
        <div className="space-between-end">
          <SearchFilter {...state} {...props} onChange={onChange} />
          <ExportExcel
            onClick={handleExcelExport}
            fileName={exportName}
            columns={exportColumns}
            sheets={[
              {
                sheetName: exportName,
                data: props.approvalListForExcel,
                header: getHeaders(),
              },
            ]}
            className="button button-subtle"
          />
        </div>
        <Tabs
          // type="block"
          noBackground
        >
          {tabContent.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.component}
                id={tab.component}
                isActive={state.activeTab.component === tab.component}
                onClick={() => onChange("activeTab", tab)}
              >
                <>
                  <Table
                    {...props}
                    {...state}
                    handlePagination={handlePagination}
                    refreshContent={() => setUpdateTable(true)}
                  />
                </>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    approvalList: state.reportReducer.approvalList,
    approvalListForExcel: state.reportReducer.approvalListForExcel,
    approvalListLoading: state.reportReducer.approvalListLoading,
  };
};
const mapDispatchToProps = { GetReportApproval };
export default connect(mapStateToProps, mapDispatchToProps)(Index);
