import { isEmpty } from "../../helpers";
import { api, deletion, post, get, put, get_by_id } from "../../networkService";
import {
  GET_REPORT_APPROVAL_EXCEL,
  GET_REPORT_FIELD_USERS_EXCEL,
  GET_REPORT_APPROVAL,
  GET_REPORT_FIELD_USERS,
  GET_REPORT_APPROVAL_LOADING,
  GET_REPORT_FIELD_USERS_LOADING,
  GET_REPORT_PENDING_APPROVAL_LOADING,
  GET_REPORT_PENDING_APPROVAL_EXCEL,
  GET_REPORT_PENDING_APPROVAL,
} from "../types";

export const GetReportApproval = (param) => async (dispatch: Function) => {
  const url = `${api.report.approval}?date=${param.date}&status=${param.status}`;
  dispatch({ type: GET_REPORT_APPROVAL_LOADING, payload: true });
  const res = await get(url, dispatch);
  dispatch({
    type: param.isExport ? GET_REPORT_APPROVAL_EXCEL : GET_REPORT_APPROVAL,
    payload: res,
  });
};

export const GetReportFieldUsers = (param) => async (dispatch: Function) => {
  const url = `${api.report.fieldUsers}?date=${param.date}`;
  dispatch({ type: GET_REPORT_FIELD_USERS_LOADING, payload: true });
  const res = await get(url, dispatch);
  dispatch({
    type: param.isExport
      ? GET_REPORT_FIELD_USERS_EXCEL
      : GET_REPORT_FIELD_USERS,
    payload: res,
  });
};

export const GetReportPendingApproval = (param) => async (
  dispatch: Function
) => {
  const url = `${api.report.pendingApproval}`;
  dispatch({ type: GET_REPORT_PENDING_APPROVAL_LOADING, payload: true });
  const res = await get(url, dispatch);
  dispatch({
    type: param.isExport
      ? GET_REPORT_PENDING_APPROVAL_EXCEL
      : GET_REPORT_PENDING_APPROVAL,
    payload: res,
  });
};
