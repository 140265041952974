import React, { useEffect } from "react";

function useOutsideClick(ref: any, setDrop: any, parentRef: any = null) {
  function handleClickOutside(event) {
    if (parentRef) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!parentRef.current.contains(event.target)) {
          setDrop();
        }
      }
    } else {
      if (ref.current && !ref.current.contains(event.target)) {
        setDrop();
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

export default useOutsideClick;
