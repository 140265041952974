import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/modal";
import Button from "../../../../components/button";
import Icons from "../../../../components/icons";
import Comments from "./comments";
import {
  GetAssetsById,
  ApproveAsset,
  SendAssetBackForReview,
  CommentOnAsset,
  DeleteComment,
} from "../../../../redux/actions/assetsAction";
import { fileUrl } from "../../../../constants/urls";
import { validImageExtension } from "../../../../constants/validExtensions";
import { NewDateTime_Format } from "../../../../helpers/date/dateFormat";
import { assetsStageEnum } from "../../../../enums/assets";

function AssetsDetail(props: any) {
  const { assetId, assetById } = props;

  useEffect(() => {
    getAssetsById();
  }, []);
  var commentsRef: any = useRef();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmType, setConfirmType] = useState("");

  const getAssetsById = () => {
    props.GetAssetsById(assetId);
  };

  if (!assetById) {
    return null;
  }

  const {
    Id,
    AssetName,
    AssetCode,
    Model,
    Manufacturer,
    Note,
    SerialNo,
    Measurement,
    PurchaseDateEng,
    RunningHours,
    AssetGroupDesc,
    AssetSubGroupDesc,
    AssetSubCodeName,
    DirectorateName,
    ProvinceName,
    OfficeCode,
    OfficeName,
    OfficeTypeName,
    DepartmentName,
    LocationName,
    CreatedByName,
    CreatedOn,
    StatusName,
    AssetImageDtos,
    Longitude,
    PhysicalLocation,
    Latitude,
    L1ApprovalDate,
    L1ApprovalRemarks,
    L1ApprovedBy,
    L2ApprovalDate,
    L2ApprovalRemarks,
    L2ApprovedBy,
    L3ApprovalDate,
    L3ApprovalRemarks,
    L3ApprovedBy,

    IsAllowApprove,
    IsAllowToSendBackForReview,
    CapitalizationDate,
    AssetConditionName,
    Capacity,
    UOMDesc,
    Status,
    MadeInCountryName,
  } = assetById;

  console.clear();
  console.log(assetById);
  const onApproveAsset = () => {
    if (IsAllowApprove) {
      const param = {
        // id: 0,
        assetRefId: Id,
        // approvalRemarks: "string",
      };

      props.ApproveAsset(param, onRefreshContent);
    }
  };

  const onSendBackForReview = async () => {
    const param = {
      // id: 0,
      assetRefId: Id,
      // approvalRemarks: "string",
    };
    var canSubmit = commentsRef && (await commentsRef.submitComment());
    if (!canSubmit) {
      alert("comment is required");
      return;
    }
    commentsRef &&
      commentsRef.handleParentAction &&
      commentsRef.handleParentAction();
    props.SendAssetBackForReview(param, onRefreshContent);
  };

  const onRefreshContent = () => {
    props.refreshContent && props.refreshContent();
    props.setShowModal(false);
  };

  const buttonApprove = () => {
    return (
      <>
        {IsAllowApprove ? (
          <Button
            title="Approve"
            buttonType="icon-button"
            leftIcon={<Icons name="CircleCheck" />}
            bg="green"
            onClick={() => {
              setShowConfirm(true);
              setConfirmType("approve");
              let msg = "Are you sure, you want to approve?";
              if (Status == assetsStageEnum.reDraft) {
                msg =
                  "This action will delete any unsynced data of this Asset from Asset Register Tool (Mobile App). Are you sure, you want to continue?";
              }

              setConfirmContent(msg);
            }}
          />
        ) : null}
      </>
    );
  };

  const getAssetName = () => {
    let name = AssetName;
    if (AssetName && AssetName != "" && AssetCode && AssetCode != "") {
      name = `${AssetCode} - ${AssetName}`;
    }
    return name;
  };
  const getOfficeName = () => {
    return (OfficeCode && `${OfficeCode} - ${OfficeName}`) || OfficeName;
  };
  const getMeasurement = () => {
    if (UOMDesc && UOMDesc != "" && Measurement && Measurement != "") {
      return `${Measurement} ${UOMDesc}`;
    }
    return Measurement;
  };

  return (
    <div>
      <Modal
        open={props.showModal}
        onModalClose={() => props.setShowModal(false)}
        hideFooter={true}
        // title={Model + " - " + AssetName}
        title={getAssetName()}
        width={"1000px"}
      >
        <div className="common_modal asset-detail">
          <div className="flex-reverse">
            {buttonApprove()}

            {/* <Button
              title="Edit"
              buttonType="icon-button"
              leftIcon={<Icons name="Edit" />}
              bg="secondary"
              buttonClass="ml-md"
              //onClick={() => alert("we are working on it.")}
            /> */}
          </div>

          <div className="flex-start">
            <div className="asset-detail-table">
              <table>
                <tr>
                  <td className="wd-30">Asset Name</td>
                  <td className="bold">{AssetName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Manufacturer</td>
                  <td className="bold">{Manufacturer}</td>
                </tr>
                <tr>
                  <td className="wd-30">Model</td>
                  <td className="bold">{Model}</td>
                </tr>
                <tr>
                  <td className="wd-30">Serial Number</td>
                  <td className="bold">{SerialNo}</td>
                </tr>
                <tr>
                  <td className="wd-30">Measurement</td>
                  <td className="bold">{getMeasurement()}</td>
                </tr>
                <tr>
                  <td className="wd-30">Purchase Date</td>
                  <td className="bold">{PurchaseDateEng}</td>
                </tr>
                <tr>
                  <td className="wd-30">RunningHours</td>
                  <td className="bold">{RunningHours}</td>
                </tr>
                <tr>
                  <td className="wd-30">Asset Condition</td>
                  <td className="bold">{AssetConditionName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Capacity</td>
                  <td className="bold">{Capacity}</td>
                </tr>
                <tr>
                  <td className="wd-30">Asset Group</td>
                  <td className="bold">{AssetGroupDesc}</td>
                </tr>
                <tr>
                  <td className="wd-30">Asset Subgroup</td>
                  <td className="bold">{AssetSubGroupDesc}</td>
                </tr>
                <tr>
                  <td className="wd-30">Asset Subcode</td>
                  <td className="bold">{AssetSubCodeName}</td>
                </tr>
              </table>
            </div>

            <div className="asset-detail-table ml-md">
              <table>
                <tr>
                  <td className="wd-30">Made In</td>
                  <td className="bold">{MadeInCountryName}</td>
                </tr>

                <tr>
                  <td className="wd-30">Directorate</td>
                  <td className="bold">{DirectorateName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Province</td>
                  <td className="bold">{ProvinceName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Office</td>
                  <td className="bold">{getOfficeName()}</td>
                </tr>
                <tr>
                  <td className="wd-30">Office Type</td>
                  <td className="bold">{OfficeTypeName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Department</td>
                  <td className="bold">{DepartmentName}</td>
                </tr>
                <tr>
                  <td className="wd-30">Date of capitalization (Nepali)</td>
                  <td className="bold">{CapitalizationDate}</td>
                </tr>

                <tr>
                  <td className="wd-30">Location</td>
                  <td>
                    <span className="bold mr-sm">{PhysicalLocation}</span>
                    <br />
                    <span className="text-sm">
                      {Latitude + ", " + Longitude}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {Note && (
            <div className="asset-detail-note">
              <div>Note</div>
              <div className="bold">{Note}</div>
            </div>
          )}
          <div className="asset-detail-images">
            {/* <video className="asset-detail-images-image" controls>
            <source
              src={`https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4?_=1`}
              type="video/mp4"
            />
            //  <source src="movie.ogg" type="video/ogg" /> 
            //Your browser does not support the video tag.
          </video>

          <img
            src={`https://www.xda-developers.com/files/2019/11/URL-Radio.jpg`}
            // style={{ width: "350px", height: "300px" }}
            className="asset-detail-images-image"
          />

          <img
            src={`https://cdn.urlencoder.org/assets/images/url-fb.png`}
            className="asset-detail-images-image"
          />

          <img
            src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&usqp=CAU`}
            className="asset-detail-images-image"
          /> */}

            {AssetImageDtos && AssetImageDtos.length > 0 ? (
              AssetImageDtos.map((file: any, index: any) => {
                const splitedFilename = file.UserFileName.split(".");
                const extension = splitedFilename[splitedFilename.length - 1];
                const isImage = validImageExtension.find(
                  (f) => f == extension.toLowerCase()
                )
                  ? true
                  : false;
                return (
                  <>
                    {isImage ? (
                      <img
                        key={index}
                        src={`${fileUrl}${file.ServerFileName}`}
                        alt={file.UserFileName}
                        className="asset-detail-images-image"
                      />
                    ) : (
                      <video
                        key={index}
                        className="asset-detail-images-image"
                        controls
                      >
                        <source
                          src={`${fileUrl}${file.ServerFileName}`}
                          type={`video/${extension}`}
                        />
                      </video>
                    )}
                  </>
                );
              })
            ) : (
              <span className="no-content">No asset image available.</span>
            )}
          </div>
          <div className="flex-start">
            <div className="asset-detail-table" style={{ width: "45%" }}>
              <table>
                <tr>
                  <td>Added By</td>
                  <td className="bold">{CreatedByName}</td>
                </tr>
                <tr>
                  <td>Added On</td>
                  <td className="bold">{NewDateTime_Format(CreatedOn)}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td className="bold">{StatusName}</td>
                </tr>
              </table>
            </div>

            <div className="asset-detail-table ml-md">
              <table>
                <tr>
                  <td className="wd-15">L1 Approval</td>
                  <td className="bold">{NewDateTime_Format(L1ApprovalDate)}</td>
                  <td className="bold">{L1ApprovedBy}</td>
                  <td className="bold">{L1ApprovalRemarks}</td>
                </tr>
                <tr>
                  <td className="wd-15">L2 Approval</td>
                  <td className="bold ">
                    {NewDateTime_Format(L2ApprovalDate)}
                  </td>
                  <td className="bold">{L2ApprovedBy}</td>
                  <td className="bold">{L2ApprovalRemarks}</td>
                </tr>
                <tr>
                  <td className="wd-15">L3 Approval</td>
                  <td className="bold">{NewDateTime_Format(L3ApprovalDate)}</td>
                  <td className="bold">{L3ApprovedBy}</td>
                  <td className="bold">{L3ApprovalRemarks}</td>
                </tr>
              </table>
            </div>
          </div>

          <Comments
            ref={(instance) => {
              commentsRef = instance;
            }}
            IsAllowComment={assetById.IsAllowComment}
            {...props}
            getAssetsById={getAssetsById}
          />

          <div className="mt-md modal__content-footer">
            {IsAllowToSendBackForReview && (
              <Button
                bg="primary"
                title={"Send back for Review"}
                onClick={() => {
                  setShowConfirm(true);
                  setConfirmType("sendBackForReview");
                  setConfirmContent(
                    "Are you sure, you want to send back for review?"
                  );
                }}
              />
            )}
            <span className="ml-md"> {buttonApprove()}</span>
          </div>
        </div>
      </Modal>

      <Modal
        open={showConfirm}
        onModalClose={() => setShowConfirm(false)}
        hideFooter={true}
        type="confirm"
        content={confirmContent}
        func={() => {
          if (confirmType == "approve") {
            onApproveAsset();
          } else {
            onSendBackForReview();
          }
        }}
        title={"Confirm!"}
      ></Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  assetById: state.assetsReducer.assetById,
});

const mapDispatchToProps = {
  GetAssetsById,
  ApproveAsset,
  SendAssetBackForReview,
  CommentOnAsset,
  DeleteComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsDetail);
