import React, { useRef, useEffect } from "react";

export default function App(toggle, activeEl) {
  useEffect(() => {
    document.body.style.overflow = toggle ? "hidden" : "visible";
    return () =>
      (document.body.style.overflow =
        activeEl.length === 1 ? "visible" : "hidden");
  }, [toggle]);
}
