export const baseURL = `${window.location.origin}/v1/`;
export const fileUrl = `${window.location.origin}/uploads/`;
export const originUrl = window.location.origin;
// export const baseURL = `http://avnea.rigoerp.com/v1/`;
// export const fileUrl = `http://avnea.rigoerp.com/uploads/`;
// export const originUrl = "http://avnea.rigoerp.com";

//  "http://192.168.1.135:88/v1/";
// export const baseURL = 'http://192.168.0.107:88/v1/';
// export const baseURL = 'http://192.168.0.110:88/v1/';
// export const baseURL = 'http://localhost:49255/v1/';
// export const fileUrl = `http://localhost:49255/uploads/`;
// http://avnea.rigoerp.com/v1/
