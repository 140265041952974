import React, { Component } from "react";
import Form from "../../../components/form";

function SearchAndFilter(props: any) {
  const {
    searchCategory,
    group,
    subGroup,
    assetGroups,
    assetSubgroups,
  } = props;
  return (
    <div className="filters">
      <div className="filters-grid">
        <Form
          formName="textinput"
          name="searchCategory"
          value={searchCategory}
          onChange={props.onChange}
          placeholder="Search Asset Category"
          width="250px"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="group"
          value={group}
          options={assetGroups}
          onChange={props.onChange}
          placeholder="Select Asset Group"
        />
      </div>
      <div className="filters-grid">
        <Form
          formName="reactselect"
          name="subGroup"
          value={subGroup}
          options={assetSubgroups}
          onChange={props.onChange}
          placeholder="Select Asset Subgroup"
        />
      </div>
    </div>
  );
}

export default SearchAndFilter;
