import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const monthTickFormatter = (tick) => {
  const date = new Date(tick);

  return date.getMonth() + 1;
};

const renderQuarterTick = (tickProps) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = new Date(value);
  const month = date.getMonth();
  const quarterNo = Math.floor(month / 3) + 1;
  const isMidMonth = month % 3 === 1;

  if (month % 3 === 1) {
    return (
      <text
        x={x + offset}
        y={y - 4}
        textAnchor="middle"
      >{`Q${quarterNo}`}</text>
    );
  }

  const isLast = month === 11;

  if (month % 3 === 0 || isLast) {
    const pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
  }
  return null;
};

class Index extends PureComponent {
  render() {
    const {
      title,
      data,
      width,
      height,
      minPointSize,
      xAxisDataKey,
      bars,
    } = this.props;
    return (
      <div className="chart">
        {title && <div className="chart-title">{title}</div>}
        <div className="chart-body">
          <BarChart
            width={width}
            height={height}
            data={data}
            margin={{
              // top: 5,
              right: 10,
              left: 10,
              // bottom: 5,
            }}
            // barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} />
            {/* <XAxis dataKey="Key" tickFormatter={monthTickFormatter} /> */}
            {/* <XAxis
              dataKey="Key"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderQuarterTick}
              height={1}
              scale="band"
              xAxisId="quarter"
            /> */}
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" minPointSize={minPointSize}>
              <LabelList dataKey="name" content={renderCustomizedLabel} />
            </Bar> */}
            {/* <Bar dataKey="Value" fill="#8884d8" minPointSize={minPointSize} /> */}
            {bars &&
              bars.map((desc, i) => (
                <Bar
                  dataKey={desc.dataKey}
                  fill={desc.fill}
                  minPointSize={minPointSize}
                  key={i}
                />
              ))}
          </BarChart>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  minPointSize: PropTypes.number,
  xAxisDataKey: PropTypes.string,
  bars: PropTypes.array,
};

Index.defaultProps = {
  data: [],
  title: null,
  width: 700,
  height: 300,
  xAxisDataKey: "name",
  minPointSize: 5,
  bars: [],
};

export default Index;
