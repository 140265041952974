import {
  GET_DIRECTORATE_AND_DEPARTMENT_LIST,
  GET_DEPARTMENT_BY_ID,
  GET_OFFICETYPE_LIST,
  GET_OFFICE_LIST,
} from "../types";
import { Action, Reducer } from "redux";

const initialState = {
  directorateAndDepartmentList: null,
  departmentById: null,
  officeTypeList: null,
  officeList: null,
};

export const organizationReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTORATE_AND_DEPARTMENT_LIST:
      return {
        ...state,
        directorateAndDepartmentList: action.payload,
      };

    case GET_DEPARTMENT_BY_ID:
      return {
        ...state,
        departmentById: action.payload,
      };

    case GET_OFFICETYPE_LIST:
      return {
        ...state,
        officeTypeList: action.payload,
      };

    case GET_OFFICE_LIST:
      return {
        ...state,
        officeList: action.payload,
      };

    default:
      return state;
  }
};
