import React from "react";
import { toastMessage } from "../redux/actions/validateAction";

export function dispatchError(dispatch: Function, error: any) {
  let response: any = {};
  response.MessageType = error.MessageType || error.messageType || "Warning";
  response.Message = error.Message || error.message || error;

  toastMessage(dispatch, response);
}
