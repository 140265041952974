import React, { useState } from "react";
import AgGridTable from "../../../components/tables/agGridTable";
import LastModifiedCell from "./lastModifiedCell";
import ActionCell from "./actionCell";

const columnDefs = [
  {
    headerName: "User Name",
    field: "UserName",
    // filter: true,
    width: 180,
  },
  { headerName: "Roles", field: "Roles", width: 300 },
  { headerName: "Phone", field: "PhoneNumber", width: 100 },
  { headerName: "E-mail", field: "Email", width: 220 },
  {
    headerName: "Last Modified",
    // headerClass: "cash-header",
    field: "LastModifiedOn",
    cellRenderer: "lastModifiedCell",
    width: 300,
  },
  {
    headerName: "Action",
    field: "",
    cellRenderer: "actionCell",
    // cellStyle: { color: "#1786ff" },
    width: 120,
  },
];

function Table(props: any) {
  const frameworkComponents = {
    lastModifiedCell: (rowProps: any) => <LastModifiedCell {...rowProps} />,
    actionCell: (rowProps: any) => <ActionCell {...rowProps} {...props} />,
  };

  return (
    <>
      <AgGridTable
        columnDefs={columnDefs}
        rowData={props.users}
        frameworkComponents={frameworkComponents}
        page={{ pageIndex: props.pageIndex, rowsPerPage: props.pageSize }}
        handlePagination={props.handlePagination}
      />
    </>
  );
}

export default Table;
