import React from "react";
import { NavLink } from "react-router-dom";
import routes from "../../../navigation/routes";

export default function CounterBoard(props: any) {
  return (
    <>
      {props.progressSummary && props.progressSummary.DirectorateStatistics && (
        <div className="dashboard-counter_board">
          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 0 },
            }}
            key={0}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#056bd5" }}>
                {props.progressSummary.DirectorateStatistics.AssetsAdded}
              </span>
              <span className="text">Assets Added</span>
            </div>
          </NavLink>
          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 4 },
            }}
            key={4}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#039d00" }}>
                {props.progressSummary.DirectorateStatistics.AssetsVerified}
              </span>
              <span className="text">Assets Verified</span>
            </div>
          </NavLink>
          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 1 },
            }}
            key={1}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#066cd5" }}>
                {props.progressSummary.DirectorateStatistics.WaitingL1Approval}
              </span>
              <span className="text">Waiting L1 Approval</span>
            </div>
          </NavLink>
          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 2 },
            }}
            key={2}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#066cd5" }}>
                {props.progressSummary.DirectorateStatistics.WaitingL2Approval}
              </span>
              <span className="text">Waiting L2 Approval</span>
            </div>
          </NavLink>

          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 3 },
            }}
            key={3}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#066cd5" }}>
                {props.progressSummary.DirectorateStatistics.WaitingL3Approval}
              </span>
              <span className="text">Waiting L3 Approval</span>
            </div>
          </NavLink>
          <NavLink
            exact
            className={"navbar-list__links"}
            to={{
              pathname: routes.assetListing,
              state: { selectedTabIndex: 5 },
            }}
            key={5}
          >
            <div className="dashboard-counter_board-item">
              <span className="count" style={{ color: "#ff6905" }}>
                {props.progressSummary.DirectorateStatistics.CommentsToResolve}
              </span>
              <span className="text">Comments to Resolve</span>
            </div>
          </NavLink>
        </div>
      )}
    </>
  );
}
