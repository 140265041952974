import { combineReducers } from "redux";
import { loadingReducer } from "./loadingReducer";
import { validateReducer } from "./validateReducer";
import { dropdownReducer } from "./common/dropdownReducer";
import { appVersionReducer } from "./public/appVersionReducer";
import { dashboardReducer } from "./dashboardReducer";
import { usersReducer } from "./usersReducer";
import { rolesReducer } from "./rolesReducer";
import { assetsReducer } from "./assetsReducer";
import { organizationReducer } from "./organizationReducer";
import { reportReducer } from "./reportReducer";

export default combineReducers({
  loadingReducer,
  validateReducer,
  dropdownReducer,
  dashboardReducer,
  usersReducer,
  rolesReducer,
  assetsReducer,
  organizationReducer,
  reportReducer,
  appVersionReducer
});
